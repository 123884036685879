import * as React from 'react';

import Modal from 'antd/lib/modal/Modal';

import {request} from '@common/react/components/Api';
import File from '@common/react/components/Forms/Files/File';
import {FileType} from '@common/typescript/objects/FileInterface';
import {deleteConfirmation} from '@common/react/components/Modal/Modal';

interface AvatarProps {
	type: string;
	object: any;
	update: (result: onAvatarUploadResult) => void;
	className?: string;
	buttonCaption?: string;
	infoMessage?: string;
	fileType?: FileType;
	property?: string;
	propertyOrigin?: string;
	confirmDelete?: boolean;
}

interface AvatarState {
	isLoading: boolean;
}

// tslint:disable-next-line:class-name
export interface onAvatarUploadResult {
	avatar: string;
	originalAvatar: string;
}

export default class Avatar extends React.Component<AvatarProps, AvatarState> {
	public static defaultProps: Partial<AvatarProps> = {
		className: ''
	};

	constructor(props: AvatarProps) {
		super(props);

		this.state = {
			isLoading: false
		};
		
		this.removeFileUpload = this.removeFileUpload.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onConfirmDelete = this.onConfirmDelete.bind(this);
	}
	
	removeFileUpload() {
		const original = this.props.propertyOrigin || 'originalAvatar';

		this.setState({ isLoading: true });

		request('uploadedFile', {
			// todo fix this
			objectType: this.props.type,
			objectId: this.props.object.id,
			//
			src: this.props.object[original],
			id: -1,
			deleted: true
		}).then(() => {
			this.props.update({ avatar: '', originalAvatar: '' });
			this.setState({ isLoading: false });
		})
		.catch((error) => {
			this.setState({ isLoading: false });
		});
	}
	
	onConfirmDelete() {
		deleteConfirmation(() => {
			this.removeFileUpload();
		});
	}
	
	onUpdate(result: any) {
		this.props.update({ avatar: result.thumb, originalAvatar: result.src });
	}

	render(): JSX.Element {
		const {object, buttonCaption, type, infoMessage, fileType = FileType.Avatar, property = 'avatar', confirmDelete = false} = this.props;

		const Thumb = (<div className="thumbnail avatar-component__thumb">
			<img src={object[property]}/>
			<i className="avatar-component__remove fa fa-times" onClick={e => confirmDelete ? this.onConfirmDelete() : this.removeFileUpload()}/>
		</div>);

		return <div className="avatar-component">{object[property] 
				?  Thumb 
				: <File 
						buttonCaption={buttonCaption} 
						infoMessage={infoMessage} 
						fileType={fileType} 
						objectId={object.id} 
						type={type} 
						update={this.onUpdate}
				/>
		} 
		</div>;
	}
}