import * as React from 'react';
import {TComment} from "@app/objects/Comment";
import {connect} from "react-redux";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {getActionCreators} from "@common/react/store/Comments";
import { request } from '@app/components/Api';
import message from 'antd/lib/message';

import {User} from "@app/objects/User";

import CommentNode from "@app/components/Forms/Comment/CommentNode";

interface CommentProps<TUser extends User> {
	item: TComment<TUser>
	handleReply: () => void,
	handleEdit: () => void,
	handleDelete: () => Promise<void>,
	index: number;
	stateName?: string;
}

interface ReduxProps {
	currentUser?: User,
}

interface Actions {
	actions: ActionCreatorsMapObject;
}

interface CommentState {
	deleteDialogVisible: boolean,
	isDeleting: boolean
}

type Props = CommentProps<User> & Actions & ReduxProps;

class Comment<TUser extends User> extends React.Component<Props, CommentState>{
	constructor(props: Props) {
		super(props);

		this.state = {
			deleteDialogVisible: false,
			isDeleting: false
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
		this.handleView = this.handleView.bind(this);
	}

	public handleDelete() {
		this.setState({
			isDeleting: true,
			deleteDialogVisible: false
		});

		this.props.handleDelete()
			.then(() => {
				this.setState({
					isDeleting: false
				});
				message.success('Comment has been successfully deleted!');
			})
			.catch(() => {
				this.setState({
					isDeleting: false
				});
				message.error('Error!');
			})
	}

	public handleDeleteClick() {
		this.setState({
			deleteDialogVisible: true
		});
	}

	public handleDeleteCancel() {
		this.setState({
			deleteDialogVisible: false
		});
	}
	
	handleView(item: TComment<User>) {
		request('viewComment', {id: item.id}).then((result) => {
			this.props.actions.simpleUpdateComment({...item, viewed: true}, this.props.stateName);
		});
	}

	public render() {
		return <CommentNode 
			currentUser={this.props.currentUser} 
			item={this.props.item}
			index={this.props.index}
			isDeleting={this.state.isDeleting} 
			deleteDialogVisible={this.state.deleteDialogVisible}
			handleDelete={this.props.handleDelete}
			handleDeleteCancel={this.handleDeleteCancel}
			handleDeleteClick={this.handleDeleteClick}
			handleView={this.handleView}
			handleEdit={this.props.handleEdit}
		/>
	}
}

export default connect<ReduxProps, Actions, CommentProps<User>>(
	(state) => ({currentUser: state.login.user}),
	(dispatch) => ({
		actions: bindActionCreators(getActionCreators(), dispatch)
	})
)(Comment);