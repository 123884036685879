import * as React from 'react';

import * as moment from 'moment';
import TimePicker, {TimePickerProps}  from 'antd/lib/time-picker';

import {getPopupContainer} from '@common/react/components/Utils/Utils';

interface ComponentProps {
	value?: number | string | null;
	onChange: (time: string | null) => void;
	antdProps?: TimePickerProps;
	utc?: boolean;
}

const Timepicker: React.SFC<ComponentProps> = ({value, onChange, antdProps = {}, utc = false}) => {
	let defaultValue;
	
	if (value !== null && value !== undefined ) {
		if (typeof value === 'string') {
			defaultValue = utc ? moment.utc(value, 'HH:mm:ss') : moment(value, 'HH:mm:ss');
		} else {
			defaultValue = utc ? moment.utc(value) : moment(value);
		}
	}

	const innerOnChange = (time: moment.Moment | null) => {
		onChange(time === null 
			? null 
			: utc ? moment.utc(time).format('HH:mm:ss') : moment(time).format( 'HH:mm:ss'));
	};

	return <div className="timepicker-component">
		<TimePicker 
			{...antdProps} 
			onChange={innerOnChange} 
			value={defaultValue} 
			getPopupContainer={antdProps.getPopupContainer || getPopupContainer} 
		/>
	</div>;
};

export default Timepicker;