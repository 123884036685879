import {Injury} from "@app/objects/Injury";
import {Employee} from "@app/objects/Employee";
import Schedule from "@app/objects/Schedule";
import {CallType} from "@app/objects/Call";
import Event from "@app/objects/Event";

export enum EventStatus { Started, Finished }

export default interface Alert {
	id: number;
	injuryId: number;
	injury: Injury;
	employeId: number;
	employe: Employee;
	eventId: number;
	event: Event;
	scheduleId: number;
	schedule: Schedule;
	scheduledCallTime: number;
	alertTime: number;
	missingCallType: CallType;
	eventStatus: EventStatus;
	seen: boolean;
	emails: string;
}