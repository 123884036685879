import * as React from 'react';

import Upload from 'antd/lib/upload';
import Icon from 'antd/lib/icon';

import Button from '@common/react/components/Forms/Button';
import {FileType, FileInterface} from '@common/typescript/objects/FileInterface';

interface FileState {
	isLoading: boolean;
	error: string | null;
}

interface FileProps {
	type: string;
	objectId: number;
	update: (data: FileInterface) => void;
	onError?: (error: string) => void;
	fileType?: FileType;
	infoMessage?: string;
	asButton?: boolean;
	accept?: string;
	buttonCaption?: string;
	buttonClassName?: string;
}

export default class File extends React.Component<FileProps, FileState> {
	public static defaultProps: Partial<FileProps> = {
		infoMessage: 'Размер изображения не должен превышать 1 Мб',
		asButton: false,
		accept: '',
		buttonCaption: 'Загрузить',
		objectId: -1
	};
	
	constructor(props : FileProps) {
		super(props);

		this.state = {
			isLoading: false,
			error: null
		};

		this.customRequest = this.customRequest.bind(this);
	}
	
	customRequest({
		action,
		data,
		file,
		filename,
		headers,
		onError,
		onProgress,
		onSuccess,
		withCredentials}) {

		const formData = new FormData();
		
		const {type, objectId, fileType = FileType.Avatar} = this.props;

		formData.append('file', file);
		formData.append('objectType', type);
		formData.append('objectId', objectId.toString());
		formData.append('fileType', fileType.toString());

		this.setState({ 
			isLoading: true,
			error: null
		});

		fetch('fineUploader', {
			credentials: 'same-origin',
			method: 'POST',
			body: formData
		}).then((response) => {
			return response.json();
		}).then((data: any) => {
			if (!data.success) throw data.response;

			this.setState({ isLoading: false });
			
			this.props.update(data.response);
		}).catch((error) => {
			this.setState({ 
				isLoading: false,
				error: error.message
			});
			
			if (this.props.onError) {
				this.props.onError(error.message);
			}
			
			console.log(error.message);
		});

	}

	render(): JSX.Element {
		const {asButton, accept, infoMessage, buttonCaption, buttonClassName} = this.props;
		
		let uploadButton;
		
		if (asButton) {
			uploadButton = <React.Fragment>
				<Button type="button" className={buttonClassName} isLoading={this.state.isLoading}>{buttonCaption}</Button>
				<div className="file-upload-error">{this.state.error}</div>
			</React.Fragment>;
		} else {
			uploadButton = (
				<React.Fragment>
					<Icon type={this.state.isLoading ? 'loading' : 'plus'} />
					<div className="ant-upload-text">{buttonCaption}</div>
					<div className="ant-upload-info">{this.state.error || infoMessage}</div>
				</React.Fragment>
			);
		}
		
		return <Upload 
			className={`avatar-uploader ${this.state.error ? 'avatar-uploader_error' : ''}`} 
			listType={this.props.asButton ? 'text' : 'picture-card'} 
			showUploadList={false} 
			accept={accept} 
			customRequest={this.customRequest}
		>
			{uploadButton}
		</Upload>;
	}
}