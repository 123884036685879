import * as React from 'react';

import {connect} from 'react-redux';
import {ApplicationState} from "@app/store";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import * as PageState from "@common/react/store/Item";
import * as ItemsState from '@common/react/store/ItemList';
import {Injury, InjuryStatus} from "@app/objects/Injury";

import {Loading} from "@app/components/UI/Loading";
import {dateFormat, getUserName} from "@app/components/Utils/Utils";

import Collapse from "antd/lib/collapse";
import Schedule from "@app/objects/Schedule";
import {CustomQuestion, getMergedQuestions, Question} from "@app/objects/Question";
import {StatusReport} from "@app/objects/StatusReport";
import {TComment as Comment} from "@app/objects/Comment";
import {User, UserRole} from "@app/objects/User";
import CommentNode from "@app/components/Forms/Comment/CommentNode";
import {Link} from "react-router-dom";
import {dateTimeFormat} from "@common/typescript/Utils";
import ViewStatusReport from "@app/components/Pages/StatusReports/ViewStatusReport";
import Modal from "antd/lib/modal";
import {request} from "@app/components/Api";

const Panel = Collapse.Panel;

interface ComponentProps {
	reportId: number;
}

interface Actions {
	actions: ActionCreatorsMapObject,
	pagesActions: ActionCreatorsMapObject
}

interface ReduxProps {
	questions: ItemsState.ItemsState<Question>,
	report: PageState.ItemState<Injury>,
	user: User | null
}

const requestParams = {
	withEmployee: true,
	withReferral: true,
	withClaimAdjuster: true,
	withSchedule: true,
	withAssignedPerson: true
};


type Props = ReduxProps & Actions & ComponentProps;


interface StatusReportViewProps {
	report: StatusReport,
	questions: Question[],
	onViewStatusReport: any;
}

interface ComponentState {
	visible: boolean;
	statusReport: StatusReport | null;
}


const StatusReportView: React.SFC<StatusReportViewProps> = ({ report, questions, onViewStatusReport}) => {
	const currentAnswers = report.answers ? report.answers.list : [];

	const answers = getMergedQuestions(currentAnswers, questions);
	
	return <Collapse bordered={false}>
		<Panel header="Last Status Report" key="last">
			<div className="row">
				<div className="col-sm-6 form-group">
					<strong>Date: </strong>
					<a onClick={() => onViewStatusReport(report)}>{dateFormat(report.time)} Click for more information</a>
				</div>
			</div>
			
		</Panel>
	</Collapse>;
};


class ReportViewCase extends React.Component<Props, ComponentState> {
	constructor(props: any) {
		super(props);
		
		this.state = {
			visible: false,
			statusReport: null
		};
		
		this.closeDialog = this.closeDialog.bind(this);
		this.onViewStatusReport = this.onViewStatusReport.bind(this);
		this.countDaysInProgram = this.countDaysInProgram.bind(this);
	}
	
	componentWillMount() {
		const id = this.props.reportId;
		
		if(id > 0) {
			this.props.actions.loadItem('report', 'getInjury', id, {}, requestParams);
			this.props.pagesActions.reqPages('questions', 'questionList', 'question', { page: 1, text: '', count: 100 })
		}
	}
	
	componentWillReceiveProps(newProps: Props) {
		if(newProps.reportId !== this.props.reportId && newProps.reportId > 0) {
			this.props.actions.loadItem('report', 'getInjury', newProps.reportId, {}, requestParams);
		}
	}
	
	closeDialog() {
		this.setState({
			visible: false,
		})
	}

	onViewStatusReport(statusReport: StatusReport){
		request('getStatusReport', { id: statusReport.id, withAnswers: true, withInjury: true, withSchedule: true}).then((result => {
			this.setState({
				visible: true,
				statusReport: result as StatusReport
			});
		}));
	}

	countDaysInProgram(statusReport: StatusReport) {
		if (statusReport.injury && statusReport.injury.schedules && statusReport.injury.schedules.list) {
			const schedules = statusReport.injury.schedules.list;
			const oneDay = 24*60*60*1000;
			if (schedules.length > 1) {
				const times = schedules.map(schedule => schedule.startTime);
				const minTime = Math.min.apply(null, times);

				return Math.round((Date.now() - minTime)/oneDay) > 0 ? Math.round((Date.now() - minTime)/oneDay).toString() : '0';
			} else if (schedules.length === 1) {
				return Math.round((Date.now() - schedules[0].startTime)/oneDay) > 0 ? Math.round((Date.now() - schedules[0].startTime)/oneDay).toString() : '0';
			}
		}
		return '0';
    }

	totalDaysInProgram(injury: Injury) {
		
        let days: number | null = 0;
        if (injury) {
            days = injury.totalDaysInProgram;
            if (injury.totalDaysInProgram == -1 && injury.schedules && injury.schedules.list && injury.schedules.list.length > 0 && injury.schedules.list[injury.schedules.list.length - 1].actualStartTime)
                days = Math.round((new Date().getTime() - injury.schedules.list[injury.schedules.list.length - 1].actualStartTime) / (1000 * 60 * 60 * 24)) + 1;
        }
        return days;
    }

    
    abbrState(input, to) {
        var i;
        var states = [
            ['Arizona', 'AZ'],
            ['Alabama', 'AL'],
            ['Alaska', 'AK'],
            ['Arizona', 'AZ'],
            ['Arkansas', 'AR'],
            ['California', 'CA'],
            ['Colorado', 'CO'],
            ['Connecticut', 'CT'],
            ['Delaware', 'DE'],
            ['Florida', 'FL'],
            ['Georgia', 'GA'],
            ['Hawaii', 'HI'],
            ['Idaho', 'ID'],
            ['Illinois', 'IL'],
            ['Indiana', 'IN'],
            ['Iowa', 'IA'],
            ['Kansas', 'KS'],
            ['Kentucky', 'KY'],
            ['Kentucky', 'KY'],
            ['Louisiana', 'LA'],
            ['Maine', 'ME'],
            ['Maryland', 'MD'],
            ['Massachusetts', 'MA'],
            ['Michigan', 'MI'],
            ['Minnesota', 'MN'],
            ['Mississippi', 'MS'],
            ['Missouri', 'MO'],
            ['Montana', 'MT'],
            ['Nebraska', 'NE'],
            ['Nevada', 'NV'],
            ['New Hampshire', 'NH'],
            ['New Jersey', 'NJ'],
            ['New Mexico', 'NM'],
            ['New York', 'NY'],
            ['North Carolina', 'NC'],
            ['North Dakota', 'ND'],
            ['Ohio', 'OH'],
            ['Oklahoma', 'OK'],
            ['Oregon', 'OR'],
            ['Pennsylvania', 'PA'],
            ['Rhode Island', 'RI'],
            ['South Carolina', 'SC'],
            ['South Dakota', 'SD'],
            ['Tennessee', 'TN'],
            ['Texas', 'TX'],
            ['Utah', 'UT'],
            ['Vermont', 'VT'],
            ['Virginia', 'VA'],
            ['Washington', 'WA'],
            ['West Virginia', 'WV'],
            ['Wisconsin', 'WI'],
            ['Wyoming', 'WY'],
        ];

        if (to == 'abbr') {
            input = input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            for (i = 0; i < states.length; i++) {
                if (states[i][0] == input) {
                    return (states[i][1]);
                }
            }
        } else if (to == 'name') {
            input = input.toUpperCase();
            for (i = 0; i < states.length; i++) {
                if (states[i][1] == input) {
                    return (states[i][0]);
                }
            }
        }
    }


	public render() {
		const report: Injury = this.props.report.item,
			isLoading: boolean = this.props.report.isLoading;
		
		const loggedUser = this.props.user;
		
		const {visible, statusReport} = this.state;
		
		if(report && !isLoading) {
			const schedules = report.schedules ? report.schedules.list : [],
				statusReports = report.reports.list,
				notes = report.notes,
				lastStatusHistory = report.lastStatusHistory;
			
			const notExternal = loggedUser && loggedUser.role !== UserRole.External;

			return <div className="report-view">
				<div className="row">
					<div className="col-sm-6 form-group">
						<label>Case #</label>
						<br/>
						{notExternal 
							? <Link to={`/employe-editor/${report.employeId}?case=${report.vccCase}`}>{report.vccCase}</Link>
							: report.vccCase
						}
					</div>
					<div className="col-sm-6 form-group">
						<label>Referral Date</label>
						<br/>
						{report.referralDate ? dateFormat(report.referralDate) : '-'}
					</div>
				</div>
				<div className="row">
					<div className="col-sm-6 form-group">
						<label>Employee</label>
						<br/>
						{report.employe ? getUserName(report.employe) : '-'}
					</div>
					{notExternal && <div className="col-sm-6 form-group">
						<label >Referral Source</label>
						<br/>
						{report.employe && report.employe.referralName ? report.employe.referralName : '-'}
					</div>}
				</div>
				<div className="row">
					<div className="col-sm-6 form-group">
						<label>Employer</label>
						<br/>
						{report.employe && report.employe.companyName  ? report.employe.companyName : '-'}
					</div>
					<div className="col-sm-6 form-group">
						<label>Insurance Co</label>
						<br/>
						{report.claimAdjuster && report.claimAdjuster && report.claimAdjuster.insuranceCompany  ? `${report.claimAdjuster.insuranceCompany.name || ''}` : '-'}
					</div>
				</div>
				{notExternal &&
					<div className="row">
						<div className="col-sm-6 form-group">
							<label>Person Assigned</label>
							<br/>
							{report.assignedPerson ? getUserName(report.assignedPerson): '-'}
						</div>
					</div>
				}
				<div className="row">
					<div className="col-sm-6 form-group">
						<label>Start Date</label>
                        <br />
                        {(report.schedules && report.schedules.list[report.schedules.count - 1]) && report.schedules.list[report.schedules.count - 1].actualStartTime? dateFormat(report.schedules.list[report.schedules.count - 1].actualStartTime) : '-'}
					</div>
					<div className="col-sm-6 form-group">
						<label>Jurisdiction</label>
						<br/>
                        {report.stateJurisdiction ? this.abbrState(report.stateJurisdiction, 'abbr') : '-'}
					</div>
				</div>
				<div className="row">
					<div className="col-sm-4 form-group">
						<label>Days in Program</label>
                        <br />
                        {this.totalDaysInProgram(report)}
					</div>
					<div className="col-sm-4 form-group">
						<label>Actual Days Worked</label>
						<br />
						{report.daysWorked !== null ? report.daysWorked : report.totalDaysWorked}
					</div>
					<div className="col-sm-4 form-group">
						<label>Total Hours Worked</label>
						<br/>
						{Math.floor((report.minutesWorked !== null ? report.minutesWorked : report.totalMinutesWorked) / 60)}
					</div>
				</div>
				<div className="row">
					{notExternal && <div className="col-sm-6 form-group">
						<label>Medical Restriction</label>
						<br/>
						{report.currentMedicalRestrictions ? report.currentMedicalRestrictions : '-'}
					</div>}
					<div className="col-sm-6 form-group">
						<label >Current Restriction Date</label>
						<br/>
						{report.currentRestrictionDate ? dateFormat(report.currentRestrictionDate) : '-'}
					</div>
				</div>
				<div className="form-group">
					{schedules.map((schedule: Schedule) => (
						<Collapse bordered={false} key={schedule.id}>
							<Panel header={schedule.companyName ? schedule.companyName : ''} key={'' + schedule.id}>
								{/*schedule.company &&*/
									<React.Fragment>
										{/*<div className="row">
											<div className="col-sm-6 form-group">
												<label>Name</label>
												<br/>
												{schedule.company.firstName || schedule.company.lastName ? getUserName(schedule.company) : '-'}
											</div>
											<div className="col-sm-6 form-group">
												<label>Phone</label>
												<br/>
												{schedule.company.phone || '-'}
											</div>
										</div>*/}
										<div className="row">
											<div className="col-sm-6 form-group">
												<label>Actual Days in Program/Hours Worked</label>
											<br />
											{(report.daysWorked !== null ? report.daysWorked : report.totalDaysWorked)} / {Math.floor((report.minutesWorked !== null ? report.minutesWorked : report.totalMinutesWorked) / 60)}
											</div>
											<div className="col-sm-6 form-group">
												<label>Total Days in Program</label>
                                                <br />
                                                {this.totalDaysInProgram(report)}
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6 form-group">
												<label>Worksite Start Date</label>
												<br/>
												{schedule.startTime ? dateFormat(schedule.startTime) : '-'}
											</div>
											<div className="col-sm-6 form-group">
												<label>Worksite Schedule</label>
												<br/>
												{schedule.textSchedule ? schedule.textSchedule : '-'}
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6 form-group">
												<label>Worksite Job Tasks</label>
												<br/>
												{schedule.jobTask ? schedule.jobTask : '-'}
											</div>
											<div className="col-sm-6 form-group">
                                                <Link to={'/calls?vccCases=' + report.vccCase }>Current Timecard</Link>
											</div>
										</div>
										{/*<div className="row">
											<div className="col-sm-6 form-group">
												<label>Total Days Worked/Hours Worked</label>
												<br/>
												{schedule.totalDaysWorked} / {schedule.hoursWorked}
											</div>
											<div className="col-sm-6 form-group">
												<label>Work Schedule vs Actual Work</label>
												<br/>
												<div className={`report-view__${schedule.last2WeeksHoursWorked < schedule.last2WeeksHoursScheduled ? 'late' : 'success'}`}>
													{minutesToHours(schedule.last2WeeksHoursScheduled)} / {minutesToHours(schedule.last2WeeksHoursWorked)}
												</div>
											</div>
										</div>*/}
									</React.Fragment>
								}
							</Panel>
						</Collapse>
					))}
				</div>
				{notes.length > 0 && loggedUser && loggedUser.role !== UserRole.External && <React.Fragment><hr/>
					<Collapse bordered={false}>
						<Panel header="Case Notes" key="notes">
							{notes.map((note: Comment<User>) => <div className="comment-node" key={note.id}><CommentNode item={note}/></div>)}
						</Panel>
				</Collapse>
				</React.Fragment>}
				{statusReports.length > 0 && <React.Fragment><hr/><StatusReportView report={statusReports[0]} questions={this.props.questions.items} onViewStatusReport={this.onViewStatusReport}/></React.Fragment>}
				{lastStatusHistory && lastStatusHistory.injuryStatus === InjuryStatus.Closed && <React.Fragment>
					<hr/>
					<div className="row">
						<div className="col-sm-6 form-group">
							<label>Outcome</label>
							<br/>
							{lastStatusHistory.outcomeType && lastStatusHistory.outcomeType.name}
						</div>
						<div className="col-sm-6 form-group">
							<label>End date</label>
							<br/>
							{dateFormat(lastStatusHistory.time)}
						</div>
					</div>
				</React.Fragment>}
				<Modal
					title={statusReport ? dateFormat(statusReport.time) + ' ' + dateTimeFormat(statusReport.time) : ''}
					visible={visible}
					onCancel={this.closeDialog}
					width={900}
					footer={null}
					destroyOnClose={true}
				>
					{statusReport && statusReport.injury && statusReport.injury.employe &&
					<ViewStatusReport onCancel={this.closeDialog} reportId={statusReport.id} daysInProgram={this.countDaysInProgram(statusReport)} injury={statusReport.injury.id}/>}
				</Modal>
			</div>
		}

		return <Loading />
	}
}

export default connect<ReduxProps, Actions, ComponentProps>(
	(state: ApplicationState) => ({
		report: state.report,
		questions: state.questions,
		user: state.login.user
	}), // Selects which state properties are merged into the component's props
	(dispatch)  => ({
		actions:  bindActionCreators(PageState.getActionCreators(), dispatch),
		pagesActions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
	})    // Selects which action creators are merged into the component's props
)(ReportViewCase);