import * as React from 'react';

import {CallLog} from "@app/objects/CallLog";
import {ExtendableItemsPage} from "@app/components/Pages/ItemsPage";
import {dateFormat, getUserName, printPage, timeSpanTo12} from "@app/components/Utils/Utils";

import Table from "antd/lib/table";
import Modal from "antd/lib/modal";

import CallLogEditor from './CallLogEditor';
import message from "antd/lib/message";

import Popover from 'antd/lib/popover';
import {Injury} from "@app/objects/Injury";
import {Link} from "react-router-dom";


export interface CompanyCallLogOwnProps {
	injury?: Injury;
	companyId?: number;
	withoutHeadline?: boolean;
	storeName: string;
	refreshCounter?: number;
	worksiteTab?: boolean;
	selectedCompanyName?: string;
}

interface State {
	visible: boolean;
	callLogId: number | null;
}

export default class CompanyCallLog extends ExtendableItemsPage<CallLog, CompanyCallLogOwnProps, State> {
	constructor(props: any) {
		super(props);
		
		this.withoutUrlPagination = true;
		this.count = 10;

		const {injury} = props;
		
		this.state = {
			...this.state,
			visible: false,
			callLogId: null
		};

		this.columns = [
			{
				title: 'Author',
				dataIndex: 'author',
				render: (text: string, record: CallLog) => this.renderMobileCell('Author', record.author ? getUserName(record.author) : '')
			},
			{
				title: 'Contact Person',
				dataIndex: 'name',
				render: (text: string, record: CallLog) => this.renderMobileCell('Contact Person', text)
			},
			{
				title: 'Date',
				dataIndex: 'date',
				render: (text: string, record: CallLog) => this.renderMobileCell('Date', dateFormat(record.date))
			},
			{
				title: 'Time',
				dataIndex: 'callTime',
				render: (text: string, record: CallLog) => this.renderMobileCell('Time', timeSpanTo12(text))
			},
			{
				title: 'VCC Case #',
				dataIndex: 'vccCase',
				render: (text: string, record: CallLog) => this.renderMobileCell('VCC Case #', record.injury ? <Link to={`/employe-editor/${record.injury.employeId}?case=${record.injury.vccCase}`}>{record.injury.vccCase}</Link> : '-')
			},
			{
				title: 'Note',
				dataIndex: 'note',
				render: (text: string, record: CallLog) => {
					return this.renderMobileCell('Note', record.note ? <Popover title="Note" content={<div>{record.note}</div>}><strong className="popover-dots">...</strong></Popover> : '');
				}
			},
			{
				title: 'Worksite',
				dataIndex: 'company',
				render: (text: string, record: CallLog) => this.renderMobileCell('Worksite', record.companyId ? <Link to={`/company-editor/${record.companyId}`}>{record.company ? record.company.name : ''}</Link> : '-')
			},
			{
				title: '',
				dataIndex: 'actions',
				render: (text, record) => (
					<div className="text-right table-actions">
						<button type="button" className="btn btn-sm btn-default" title="Edit" onClick={e => this.openDialog(record.id)}><i className="fa fa-pencil"></i></button>
						<button type="button" className="btn btn-sm btn-danger" title="Delete" onClick={e => this.handleDelete(e, record)}><i className="fa fa-trash"></i></button>
					</div>
				)
			}
		];
		
		if(props.injury) {
			this.columns.splice(4,1);
		}

		this.type = 'callLog';
		this.store = props.storeName;
		this.path = 'callLogList';
		this.listPath = 'user-list';
		this.editorCaption = 'Add Call';
		this.caption = 'Call Log';
		this.tableClassName = 'table-mobile table-with-buttons' + props.injury ? ' small-padding-table' : '';
		
		this.additionalParams = {
			withCompany: true,
			withAuthor: true,
			injuryId: injury ? injury.id : -1,
			withInjury: !injury
		};
		
		this.isFilterText = false;

		this.openDialog = this.openDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	componentWillMount() {
		this.props.reqPages(this.store, this.path, this.type, { page: 1, count: this.count, company: this.props.worksiteTab ? -1 : this.props.companyId, ...this.additionalParams })
	}

	handleTableChange(pagination, filters, sorter) {
		this.props.reqPages(this.store, this.path, this.type, { page: pagination.current, count: this.count, company: this.props.worksiteTab ? -1 : this.props.companyId, ...this.additionalParams});
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.companyId !== nextProps.companyId || this.props.refreshCounter !== nextProps.refreshCounter) {
			this.props.refreshPages(this.store, this.path,  { page: 1, count: this.count, company: this.props.worksiteTab ? -1 : nextProps.companyId, ...this.additionalParams});
		}
	}

	openDialog(id: number): void {
		this.setState({
			callLogId: id,
			visible: true
		})
	}
	
	handleAdd() {
		this.openDialog(-1);
	}

	closeDialog() {
		this.setState({
			visible: false,
		})
	}

	onSave() {
		this.props.refreshPages(this.store, this.path, { page: 1, ...this.additionalParams });
		
		this.closeDialog();

		message.success('Call has been successfully saved!');

		this.setState({
			callLogId: null
		});
	}
	
	public render() {
		const {items, pagination, isLoading, companyId = 0, withoutHeadline, injury, selectedCompanyName, worksiteTab} = this.props;
		
		const {visible, callLogId} = this.state;

		return <React.Fragment>
			<div className="site-headline site-headline_with-button clearfix hide-print">
				{!withoutHeadline && <h1 className="pull-left">{this.caption}</h1>}
				<div className="pull-right">
					<button className="btn btn-primary" type="button" onClick={this.handleAdd}>{this.editorCaption}</button>
					<button className="btn btn-default" type="button" onClick={printPage}><i className="fa fa-print"/></button>
					<a className="btn btn-success" target="_blank" href={`/getCallLog?${companyId ? 'companyId=' + companyId : ''}${injury ? '&injuryId=' + injury.id : ''}`}><i className="fa fa-file-excel-o"/></a>
				</div>
			</div>
			<div className="call-log">
			<Table
				columns={this.columns}
				dataSource={items}
				pagination={pagination}
				loading={isLoading}
				onChange={this.handleTableChange}
				childrenColumnName="child"
				rowKey="id"
				className={this.tableClassName}
			/></div>
			{callLogId && <Modal
				title={callLogId > 0 ? 'Edit Call' : 'Add New Call'}
				visible={visible}
				onCancel={this.closeDialog}
				width={600}
				destroyOnClose={true}
				footer={null}>
				<CallLogEditor companyId={companyId} callLogId={callLogId} onCancel={this.closeDialog} injury={injury}
				               onSave={this.onSave} companyAutocomplete={worksiteTab}
				               defaultCompanyName={selectedCompanyName}/>
			</Modal>
			}
		</React.Fragment>;
	}
}