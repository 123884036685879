export enum StateTimeZone {
	SamoaStandardTime = -11,
	HawaiiAleutianStandardTime = -10,
	AlaskaStandardTime = -9,
	PacificStandardTime = -8,
	MountainStandardTime = -7,
	CentralStandardTime = -6,
	EasternStandardTime = -5,
	AtlanticStandardTime = -4,
	StandardTime = 10,
	WakeIslandTimeZone = 12
}