import {Company} from "@app/objects/Company";
import {Injury} from "@app/objects/Injury";
import {List} from "@common/typescript/objects/List";
import {Call} from "@app/objects/Call";

import * as moment from 'moment';
import {millisecondToMinutes, minutesToHours} from "@app/components/Utils/Utils";
import Schedule from "@app/objects/Schedule";

export default interface Event {
	id: number;
	
	companyId: number;
	company: Company;
	
	injuryId: number;
	injury: Injury;
	
	startTime: number;
	endTime: number;
	
	note: string;

	lunchStartTime: number;
	lunchEndTime: number;

	scheduleId: number;
	schedule?: Schedule;
	
	workDays: any[];
	
	calls: List<Call>;

	lateInAlert: number;
	lateOutAlert: number;
}


export const getScheduleRangeString = (event: Event, militaryTime?: boolean): string => {
	const {startTime, endTime} = event;
	
	const format = militaryTime ? 'HH:mm' : 'h:mm a';
    //console.log(event);
	return `${moment.utc(startTime).format(format)} - ${moment.utc(endTime).format(format)}`	
};

export const getEventWorkTimeString = (event: Event): string => {
	const {startTime, endTime, lunchStartTime, lunchEndTime} = event;

	if (lunchStartTime && lunchEndTime) {
		return minutesToHours(millisecondToMinutes((lunchStartTime - startTime) + (endTime - lunchEndTime)), true);
	} else {
		return minutesToHours(millisecondToMinutes(endTime - startTime), true);
	}
};

