import * as React from 'react';

import {FieldProps} from 'formik';

import Autocomplete, {Option} from '@common/react/components/Forms/Autocomplete/Autocomplete';
import {ZipCode} from '@common/typescript/objects/ZipCode';

interface ComponentProps {
	onSelect: (value, option) => void;
	fieldProps: FieldProps;
	onChange?: (value: string) => void;
}

export const ZipAutocomplete: React.SFC<ComponentProps> = ({onSelect, fieldProps: {field, form}, onChange }) => {
	const defaultOnChange = (value = '') => form.setFieldValue(field.name, value);
	
	return <Autocomplete
		type="zipCodeList"
		renderOption={(item: ZipCode) => <Option 
			key={`${item.id}`} 
			value={`${item.zip}`} 
			title={item.zip} 
			{...{item: item}}
		>
			{item.zip}
		</Option>}
		onSelect={onSelect}
		onChange={onChange || defaultOnChange}
		value={field.value}
		paramName="text"
	/>;
};