import {request} from "@common/react/components/Api";

﻿import React from "react";
import {Company} from "@app/objects/Company";
import {EmployeeFormValues} from "@app/objects/Employee";
import {Field, FieldProps, FormikProps} from "formik";
import MaskedInput from 'react-text-mask';
import {Attorney} from "@app/objects/Attorney";
import {InjuryFormValues} from "@app/objects/Injury";
import {phoneMask} from "@app/components/Utils/Utils";

import Autocomplete, {Option} from "@common/react/components/Forms/Autocomplete/Autocomplete";

import {SpecialCompanyType} from "@app/objects/CompanyType";
import {FormikInput} from "@common/react/components/Forms/FormikInput/FormikInput";

export interface ClaimAdjusterProps {
	formikBag: FormikProps<InjuryFormValues> | FormikProps<EmployeeFormValues>; 
	attorney: Attorney;
	namePrefix: string;
	employeeId: number;
	injuryStatus: number;
}

export interface ClaimAdjusterState {
	companyName: string;
}


export  default class ClaimAdjuster extends React.Component<ClaimAdjusterProps, ClaimAdjusterState> {
	constructor(props) {
		super(props);
		
		this.state = {
			companyName: ''
		};
		
		this.addNewInsurance = this.addNewInsurance.bind(this);
		this.handleExtraRender = this.handleExtraRender.bind(this);
	}
	
	componentWillReceiveProps(nextProps: Readonly<ClaimAdjusterProps>) {
		if(this.props.attorney.id !== nextProps.attorney.id) {
			const companyName = nextProps.attorney.insuranceCompany ? nextProps.attorney.insuranceCompany.name : '';
			
			this.setState({
				companyName: companyName
			})
		}
	}
	
	addNewInsurance(name: string) {
		const item = {
			name: name,
			companyTypeId: SpecialCompanyType.Insurance
		};
		
		return request('company', item).then((response: any) => this.props.formikBag.setFieldValue('insuranceCompanyId', response.id))
			.catch((error: string) => {});
	}
	
	handleExtraRender(state, props) {
		return  state.value.length > 2 && state.items.length === 0 && <div className="input-group-btn">
			<button type="button" className="btn btn-default" onClick={e => this.addNewInsurance(state.value)}>Add New Insurance</button>
		</div>
	}
	
	render() {
		let {formikBag, attorney, namePrefix, employeeId} = this.props;
		
		const prop = namePrefix.slice(0, -1);
		
		return <React.Fragment>
				<div className="row">
					<Field
						name={namePrefix + 'firstName'}
						render={(fieldProps: FieldProps<Attorney>) => (
							<FormikInput fieldProps={fieldProps} title="First Name" />
						)}
					/>
					<Field
						name={namePrefix + 'lastName'}
						render={(fieldProps: FieldProps<Attorney>) => (
							<FormikInput fieldProps={fieldProps} title="Last Name" />
						)}
					/>
				</div>
				<div className="row">
					<Field
						name={namePrefix + 'phone'}
						render={({field, form}: FieldProps<Attorney>) => (
							<div className={`form-group col-sm-6 ${form.touched[prop] && form.errors[prop] && form.touched[prop].phone && form.errors[prop].phone ? 'has-error' : '' }`}>
								<label htmlFor={namePrefix + 'phone' + employeeId}>Phone</label>
								<div className="is-relative">
									<MaskedInput
										mask={phoneMask}
										className="form-control"
										placeholder="+1 (___) ___-____"
										guide={true}
										id={namePrefix + 'phone' + employeeId}
										{...field}
									/>
									{form.touched[prop] && form.errors[prop] && form.touched[prop].phone && form.errors[prop].phone ? <div className="validation-message">{form.errors[prop].phone}</div> : ''}
								</div>
							</div>
						)}
					/>
					<Field
						name={namePrefix + 'extension'}
						render={(fieldProps: FieldProps<Attorney>) => (
							<FormikInput fieldProps={fieldProps} title="Extension" />
						)}
					/>
				</div>
				<div className="row">
					<Field
						name={namePrefix + 'email'}
						render={({form, field}: FieldProps<Attorney>) => (
							<div className={`form-group col-sm-6 ${form.touched[prop] && form.errors[prop] && form.touched[prop].email && form.errors[prop].email ? 'has-error' : '' }`}>
								<label htmlFor={namePrefix + 'email' + employeeId}>Email</label>
								<div className="is-relative">
									<input className="form-control" type="text"
									       id={namePrefix + 'email' + employeeId}
									       value={attorney.email} {...field}/>
									{form.touched[prop] && form.errors[prop] && form.touched[prop].email && form.errors[prop].email ? <div className="validation-message">{form.errors[prop].email}</div> : ''}
								</div>
							</div>
						)}
					/>
					<Field
						name={namePrefix + 'insuranceCompanyId'}
						render={({field, form}: FieldProps<Attorney>) => (
							<div className={`form-group col-sm-6`}>
								<label>Insurance Co/TPA</label>
								<div className="input-group w-100">
									<Autocomplete type="companyList"
									              renderOption={(item: Company) => {
										              return <Option key={item.id + ''} value={item.name}
										                             title={item.name}>{item.name}</Option>
									              }}
									              onSelect={(value, option) => {
										              formikBag.setFieldValue(namePrefix + 'insuranceCompanyId', +option.key);
										              this.setState({companyName: value})
									              }}
									              onChange={(value = '') => {
										              if (formikBag.values[namePrefix + 'insuranceCompanyId'] && value !== this.state.companyName) {
											              formikBag.setFieldValue(namePrefix + 'insuranceCompanyId', null);
											              this.setState({companyName: value})
										              }
										              if (value === '') {
											              formikBag.setFieldValue(namePrefix + 'insuranceCompanyId', null);
											              this.setState({companyName: ''})
										              }
									              }}
									              value={this.state.companyName}
									              params={{
										              companyType: SpecialCompanyType.Insurance
												  }}
												  loadOnFocus={true}
									              paramName="text"
									              onExtraRender={this.handleExtraRender}
									/>
								</div>
							</div>
						)}
					/>
				</div>
			</React.Fragment>
		
	}
}
