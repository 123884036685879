import * as React from 'react';

import {ExtendableEditableItemsPage} from "@common/react/components/Pages/EditableItemsPage";
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import * as PagesState from "@app/store/ItemList";
import {Company, CompanyContact} from "@app/objects/Company";
import {Group} from "@app/objects/Group";
import {request} from "@app/components/Api";
import * as Yup from 'yup';

import MaskedInput from 'react-text-mask';
import {emailRegexp, phoneFormat, phoneMask, phoneRegexp} from "@app/components/Utils/Utils";
import {Title} from "@app/objects/Title";
import message from "antd/lib/message";
import {Injury} from "@app/objects/Injury";

import Autocomplete, { Option } from "@common/react/components/Forms/Autocomplete/Autocomplete";
import SimpleSearchInput from "@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput";
import Table from "antd/lib/table";

interface ReduxProps {
	titles: Title[];
}

interface OwnProps {
	company?: Company;
}

interface CanBeDeletedResponse {
	canBeDeleted: boolean;
	item?: Injury;
}

class CompanyContacts extends ExtendableEditableItemsPage<CompanyContact, ReduxProps & OwnProps> {
	constructor(props: any) {
		super(props);
		
		this.columns = [
			{
				title: 'First Name',
				dataIndex: 'firstName',
				render: (text: string, record: CompanyContact) => this.getSimpleEditField('firstName', text, record.id, 'First Name')
			},
			{
				title: 'Last Name',
				dataIndex: 'lastName',
				render: (text: string, record: CompanyContact) => this.getSimpleEditField('lastName', text, record.id, 'Last Name')
			},
			{
				title: 'Phone',
				dataIndex: 'phone',
				render: (text: string, record: CompanyContact) => {
					const error = this.getError(record.id, 'phone');
					return this.isEdit(record.id) ?
						<div className={error ? 'has-error' : ''}>
							<div className="is-relative">
								<MaskedInput
									mask={phoneMask}
									className="form-control"
									placeholder="+1 (___) ___-____"
									guide={true}
									defaultValue={text}
									name={'phone-' + record.id}
									onChange={(event) => this.setItemPropValue(record.id, 'phone', event.target.value)}
								/>
								{error ? <div className="validation-message">{error}</div> : ''}
							</div>
						</div>
						: this.renderMobileCell('#', phoneFormat(text))
				}
			},
			{
				title: 'Email',
				dataIndex: 'email',
				render: (text: string, record: CompanyContact) => this.getSimpleEditField('email', text, record.id, 'Email')
			},
			{
				title: 'Title',
				dataIndex: 'titleId',
				render: (text: string, record: CompanyContact) => this.isEdit(record.id)
					? <div>
						<select className="form-control" id={'company-contact-title-'+record.id} defaultValue={'' + record.titleId} onChange={(event) => this.setItemPropValue(record.id, 'titleId', +event.target.value)}>
							<option value={0} key="0" disabled>Not selected</option>
							{this.props.titles && this.props.titles.map(title => 
								<option value={title.id} key={title.id}>{title.name}</option>
							)}
						</select>
					</div>
					: this.renderMobileCell('Title', this.getRecordTitle(record))
			},
			{
				title: 'Company',
				dataIndex: 'companyId',
				render: (text: string, record: CompanyContact) => {
					const modifedItem = this.state.modifiedItems[record.id];
					
					return this.isEdit(record.id)
						? <div><Autocomplete type="companyList"
						                     renderOption={(autocompleteItem: Company) =>
							                     <Option
								                     {...{
									                     key: autocompleteItem.id + '',
									                     value: autocompleteItem.id + '',
									                     title: autocompleteItem.name,
									                     item: autocompleteItem
								                     }}>{autocompleteItem.name} ({autocompleteItem.companyType && autocompleteItem.companyType.name})</Option>
						                     }
						                     onSelect={(value, option) => {
							                     this.setItemPropValue(record.id, 'companyId', +value);
							                     this.setItemPropValue(record.id, 'company', option.props.item);
						                     }}
						                     params={{
							                     //employerOrReferral: true,
							                     withCompanyType: true
						                     }}
						                     value={ modifedItem &&  modifedItem.company ? modifedItem.company.name : ''}
						                     paramName="name"
						                     antdProps={{
							                     placeholder: 'Start typing for search...'
						                     }}
						/></div>
						: this.renderMobileCell('Company', record.company ? record.company.name : '')
				}
			},
			{
				title: 'Group',
				dataIndex: 'groupId',
				render: (text: string, record: CompanyContact) => {
					const modifedItem = this.state.modifiedItems[record.id];
					
					return this.isEdit(record.id)
						? <div><Autocomplete type="groupList"
						                     renderOption={(autocompleteItem: Group) =>
							                     <Option
								                     {...{
									                     key: autocompleteItem.id + '',
									                     value: autocompleteItem.id + '',
									                     title: autocompleteItem.name,
                                                         item: autocompleteItem
                                                     }}>{autocompleteItem.name}</Option>
						                     }
						                     onSelect={(value, option) => {
							                     this.setItemPropValue(record.id, 'groupId', +value);
							                     this.setItemPropValue(record.id, 'group', option.props.item);
						                     }}
						                     value={ modifedItem &&  modifedItem.group ? modifedItem.group.name : ''}
						                     paramName="name"
						                     antdProps={{
							                     placeholder: 'Start typing for search...'
						                     }}
						/></div>
						: this.renderMobileCell('Group', record.group ? record.group.name : '')
				}
			},
			this.baseAction
		];

		this.type = 'companyContact';
		this.store = 'companyContacts';
		this.path = 'companyContactList';
		this.editorCaption = 'Add Contact';
		this.caption = 'Contacts';
		this.isFilterText = true;
		this.listPath = 'contacts';
		
		
		this.validationSchema = Yup.object().shape({
			firstName: Yup.string().required(),
			lastName: Yup.string().required(),
			phone: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == undefined || value == '' || value.match(phoneRegexp)),
			email: Yup.mixed().test('is-valid', 'Invalid email', value => value == undefined || value == '' || value.match(emailRegexp))
		});

		if (this.props.company) {
			this.additionalParams = {
				objectId: this.props.company.id
			}
		}
	}
	
	componentWillMount() {
		this.props.reqPages(this.store, this.path, this.type, { page: 1, objectId: this.props.company ? this.props.company.id : undefined });

		this.props.reqPages('titles', 'titleList', 'title', { count: 100 });
	}
	
	componentWillReceiveProps(nextProps) {
		if (this.props.history) super.componentWillReceiveProps(nextProps);
	}

	saveItem(item) {
		if (item.titleId === 0) item = {...item, titleId: null};
		super.saveItem(item);
	}

	handleTableChange(pagination, filters, sorter) {
		if (this.props.history) {
			super.handleTableChange(pagination, filters, sorter);
		} else {
			this.props.reqPages(this.store, this.path, this.type, { page: pagination.current, company: this.props.companyId, ...this.additionalParams});
		}
	}

	getFiltersRender() {
		return <div className="list-filters">
			<form className="clearfix" onSubmit={this.handleFilter}>
				<div className="pull-left list-filters__search top-search">
					<SimpleSearchInput onChange={this.handleChange} name="text"/>
				</div>
				{this.props.titles.length
					? <div className="pull-left">
						<select className="form-control" id="companyTitle" name="titleId" onChange={this.handleChangeAndRefresh}>
							<option value="0" key="0" selected>All titles</option>
							{this.props.titles.map((value: Title, index: number) => {
								return <option value={value.id} key={value.id}>{value.name}</option>
							})}
						</select>
					</div>
					: ''}
			</form>
		</div>
	};
	
	renderTable(): JSX.Element {
        const { items, pagination, isLoading } = this.props;
		var _tblclass = "contect-tbl";
		return <div className="contect-tbl">
		<Table
			columns={this.columns}
			dataSource={items.length > this.count ? items.slice(0, this.count) : items}
			pagination={pagination}
			loading={isLoading}
			onChange={this.handleTableChange}
			childrenColumnName="child"
			rowKey={this.rowKey}
			className={this.tableClassName}
			scroll={{x: true}}
			/>
			</div>
	}
	
	getNewItem() {
		return {
			id: -1, 
			companyId: this.props.company ? this.props.company.id : undefined,
			titleId: 0
		}
	}
	
	getRecordTitle(record: CompanyContact): string {
		if (!this.props.titles) {
			return ''
		}
		
		const title = this.props.titles.filter(title => title.id == record.titleId)[0];
		
		return title && title.name || '';
	}

	handleDelete(event: any, item: any) {
		event.preventDefault();

		request<CanBeDeletedResponse>('companyContactCanBeDeleted', {
			id: item.id
		}).then((data) => {
			if (data.canBeDeleted) {
				super.handleDelete(event, item);
			} else if (data.item) {
				message.error(`The contact cannot be deleted, there are still active connection to job for VCC Case #${data.item.vccCase}`);
			} else {
				message.error(`The contact cannot be deleted, there are still active connections to VCC Case`);
			}
		});
	}
}

export default connect(
	(state: ApplicationState) => ({
		...state.companyContacts,
		titles: state.titles.items
	}), // Selects which state properties are merged into the component's props
	PagesState.getActionCreators<CompanyContact>()                 // Selects which action creators are merged into the component's props
)(CompanyContacts) as typeof CompanyContacts;