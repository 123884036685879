import * as React from 'react';

import { FormikProps} from 'formik';

interface FormikField {
	onChange: (e: React.ChangeEvent<any>) => void;
	onBlur: (e: any) => void;
	value: any;
	name: string;
}

export interface FormikListProps<FormValues> {
	field: FormikField;
	formikBag: FormikProps<FormValues>;
	equalityChecker: (item: any, newItem: any) => boolean;
	renderItem: (item: any, removeItem: () => void) => JSX.Element | null;
	renderInput: (addNewItem: (item) => void) => JSX.Element;
}

// tslint:disable-next-line:function-name
export function FormikList<FormValues>(props: FormikListProps<FormValues>) {
	const {field} = props;
	let arr = field.value;
	
	if (!Array.isArray(arr)) {
		arr = field.value.list;
	}
	
	return <React.Fragment>
		<div>
			{arr.filter(item => !item.deleted)
				.map(item => props.renderItem(item, () => {
					
					const itemIndex = arr.indexOf(item);
					
					const newArray = arr.slice();
					
					if (item.id === -1) {
						newArray.splice(itemIndex, 1);
					} else {
						newArray.splice(itemIndex, 1, {
							...item,
							deleted: true
						});
					}

					props.formikBag.setFieldValue(field.name, newArray);
				}))
			}
		</div>
		{props.renderInput((newItem) => {
			let arr = props.field.value;
			
			if (!Array.isArray(arr)) {
				arr = props.field.value.list;
			}
			
			const foundItem = arr.filter(nextItem => props.equalityChecker(nextItem, newItem))[0];
			
			if (!foundItem) {
				props.formikBag.setFieldValue(props.field.name, [...arr, newItem]);				
			} else if (foundItem.deleted) {
				const itemIndex = arr.indexOf(foundItem);
				const newArray = arr.slice();
				
				newArray.splice(itemIndex, 1);
				
				newArray.push({
					...foundItem, 
					deleted: false
				});
				
				props.formikBag.setFieldValue(props.field.name, newArray);
			}
		})}
	</React.Fragment>;
}