import * as React from 'react';

import {connect} from 'react-redux';
import {ApplicationState} from '@app/store/index';

import {dispatchToProps, ExtendableItemEditor} from "@app/components/Pages/ItemEditor";

import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';

import Button from "@common/react/components/Forms/Button";
import {Loading} from "@app/components/UI/Loading";

import {CallLog} from "@app/objects/CallLog";

import * as Yup from 'yup';
import {default as Datepicker} from "@common/react/components/Forms/Datepicker/Datepicker";

import Timepicker from "@common/react/components/Forms/Timepicker/Timepicker";

import Autocomplete, {Option} from "@common/react/components/Forms/Autocomplete/Autocomplete";
import {User, UserRole} from "@app/objects/User";

import MaskedInput from 'react-text-mask';
import {getUserNameWithEmail, phoneMask, phoneRegexp, phoneReplace} from "@app/components/Utils/Utils";
import * as LoginState from "@app/store/Login";
import * as moment from "moment";
import {Injury} from "@app/objects/Injury";
import {SpecialCompanyType} from "@app/objects/CompanyType";
import {request} from "@app/components/Api";


interface OwnProps {
	companyId?: number;
	injury?: Injury;
	callLogId: number;
	onSave: () => void;
	onCancel: () => void;
	companyAutocomplete?: boolean;
	defaultCompanyName?: string;
}

interface ReduxProps {
	login: LoginState.LoginState;
}

const validationSchema = Yup.object().shape({
	//name: Yup.string().required(),
	authorId: Yup.number().nullable().required(),
	injuryId: Yup.number().nullable().required(),
	companyId: Yup.number().nullable().required(),
	phone: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value.match(phoneRegexp))
});

type Props = OwnProps & ReduxProps;

const timepickerProps = {
	use12Hours: true,
	format: "h:mm a"
};

interface FormValues extends CallLog{
	authorName: string;
	vccCase: string;
	companyName: string;
}

class CallLogEditor extends ExtendableItemEditor<CallLog, Props> {
	form: any;
	
	constructor(props: Props) {
		super(props);
		
		this.type = 'callLog';
		this.path = 'getCallLog';

		this.itemsStore = 'statusReports';
		this.itemsPath = 'statusReportList';

		this.defaultItem = { id: -1, companyId: props.companyId };

		this.additionalParams = {
			withAuthor: true,
			withInjury: true,
			withCompany: true
		};
		
		this.handleExtraRender = this.handleExtraRender.bind(this);
		this.addNewCompany = this.addNewCompany.bind(this);
	}

	componentWillMount() {
		if(this.props.callLogId)
			this.props.itemActions.loadItem(this.type, this.path, this.props.callLogId, this.defaultItem, this.additionalParams);
	}
	
	componentWillReceiveProps(newProps) {
		if(newProps.callLogId && newProps.callLogId !== this.props.callLogId) {
			this.props.itemActions.loadItem(this.type, this.path, newProps.callLogId, this.defaultItem, this.additionalParams);
		}
	}
	
	handleSubmit(values: FormValues, {resetForm}) {
		const item = {...this.props.item, ...values, phone: phoneReplace(values.phone), author: null, vccCase: null};
		
		this.saveItem(item).then((response: CallLog) => {
			resetForm();
			
			this.hideSuccess();

			this.props.onSave();
		});
	}

	addNewCompany(name: string) {
		const item = {
			name: name,
			companyTypeId: SpecialCompanyType.NonProfit
		};

		return request('company', item).then((response: any) => this.form.setFieldValue('companyId', response.id))
			.catch((error: string) => {});
	}

	handleExtraRender(state, props) {
		return state.value && state.value.length > 2 && state.items.length === 0 && <div className="input-group-btn">
			<button type="button" className="btn btn-default" onClick={e => this.addNewCompany(state.value)}>Add New Company</button>
		</div>
	}

	public render() {
		const item: CallLog = this.props.item;
		
		const injury = this.props.injury;
		
		const user: User | null = this.props.login.user;
		
		const {defaultCompanyName, callLogId} = this.props;
		
		if (item && callLogId && item.id === +callLogId && user) {
			const authorName = item.author ? getUserNameWithEmail(item.author) : getUserNameWithEmail(user);
			
			return (
				<React.Fragment>
					<Formik
						initialValues={{
							...item,
							phone: item.phone || '',
							date: item.date || +moment(),
							name: item.name || '',
							note: item.note || '',
							callTime: item.callTime || moment().format('HH:mm:ss'),
							authorId: item.authorId || user.id,
							injuryId: item.injury ? item.injuryId : injury ? injury.id : null,
							authorName: authorName,
							vccCase: item.injury ? item.injury.vccCase : injury ? injury.vccCase : '',
							companyId: item.companyId || null,
							companyName: callLogId > 0 ? (item.company && item.company.name) : defaultCompanyName || '',
							extension: item.extension
						}}
						ref={node => (this.form = node)}
						onSubmit={this.handleSubmit}
						enableReinitialize={true}
						validationSchema={validationSchema}
						render={(formikBag: FormikProps<FormValues>) => (
							<Form id="callLog-form">
								<div className="row">
									<Field
										name="authorId"
										render={({ field, form }: FieldProps<FormValues>) => (
											<div className={`form-group col-sm-6 ${form.errors.authorId && form.touched.authorId ? 'has-error' : ''}`}>
												<label>Call Author*</label>
												<div className="is-relative">
													<Autocomplete type="userList"
													              renderOption={(item: User) => {
														              const name = getUserNameWithEmail(item);
														              return <Option key={item.id + ''} value={item.id + ''} title={name}>{name}</Option>
													              }}
													              onSelect={(value, option) => {
														              formikBag.setFieldValue(field.name, +value);
														              formikBag.setFieldValue('authorName', option.props.title);
													              }}
													              onChange={(value = '') => {
														              value === '' && formikBag.setFieldValue(field.name, null);
														              formikBag.setFieldValue('authorName', value);
													              }}
													              value={formikBag.values.authorName}
													              params={{
														              roles: [UserRole.Internal, UserRole.Admin]
													              }}
													/>
													{form.touched.authorId  && form.errors.authorId  ? <div className="validation-message">{form.errors.authorId}</div> : ''}
												</div>
											</div>
										)}
									/>
									{this.props.companyAutocomplete && 
										<Field
											name="companyId"
											render={({ field, form }: FieldProps<FormValues>) => (
												<div className={`form-group col-sm-6 ${form.errors.companyId && form.touched.companyId ? 'has-error' : ''}`}>
													<label>Worksite*</label>
													<div className="input-group w-100">
														<Autocomplete type="companyList" 
														              renderOption={(item) => <Option key={item.id} value={`${item.name}`} title={item.name} {...{item: item}}>{item.name}</Option>}
														              onSelect={(value, option) => {formikBag.setFieldValue('companyId', +option.props.item.id); formikBag.setFieldValue('companyName', option.props.item.name);}}
														              onChange={(value = '') => {
														                if (formikBag.values.companyId && value !== formikBag.values.companyName) {
														                    formikBag.setFieldValue('companyId', null);
														                    formikBag.setFieldValue('companyName', value);
														                }
														                if (value === '') {
														                    formikBag.setFieldValue('companyId', null);
														                    formikBag.setFieldValue('companyName', null);
														                }
														              }}
														              value={formikBag.values.companyName}
														              params={{
														                companyType: SpecialCompanyType.NonProfit
														              }}
														              paramName="name"
														              loadOnFocus={true}
														              onExtraRender={!formikBag.values.companyId ? this.handleExtraRender : undefined}
														/>
														{form.touched.companyId  && form.errors.companyId  ? <div className="validation-message">{form.errors.companyId}</div> : ''}
													</div>
												</div>
											)}
										/>
									}
								</div>
								<div className="row">
									<Field
										name="phone"
										render={({ field, form }: FieldProps<FormValues>) => (
											<div className={`form-group col-sm-6 ${form.errors.phone && form.touched.phone ? 'has-error' : ''}`}>
												<label htmlFor="phone">Phone</label>
												<div className="is-relative">
													<MaskedInput
														mask={phoneMask}
														className="form-control"
														placeholder="+1 (___) ___-____"
														guide={true}
														id="phone"
														{...field}/>
													{form.touched.phone && form.errors.phone ? <div className="validation-message">{form.errors.phone}</div> : ''}
												</div>
											</div>
										)}
									/>
									<Field
										name="extension"
										render={({ field, form }: FieldProps<FormValues>) => (
											<div className={`form-group col-sm-6`}>
												<label htmlFor="extension">Extension</label>
												<input className="form-control" type="text" id="extension" {...field} />
											</div>
										)}
									/>
								</div>
								<div className="row">
									<Field
										name="name"
										render={({ field, form }: FieldProps<CallLog>) => (
											<div className={`form-group col-sm-6 ${form.errors.name && form.touched.name ? 'has-error' : ''}`}>
												<label htmlFor="callLogName">Contact Person</label>
												<div className="is-relative">
													<input className="form-control" type="text" id="callLogName" {...field} />
													{form.touched.name && form.errors.name ? <div className="validation-message">{form.errors.name}</div> : ''}
												</div>
											</div>
										)}
									/>
									<Field
										name="injuryId"
										render={({ field, form }: FieldProps<FormValues>) => (
											<div className={`form-group col-sm-6 ${form.errors.injuryId && form.touched.injuryId ? 'has-error' : ''}`}>
												<label>VCC Case*</label>
												<div className="is-relative">
													<Autocomplete
														type="injuryList"
														renderOption={(item: Injury) => <Option key={item.id + ''} value={`${item.id}`} title={item.vccCase} {...{item: item}}>{item.vccCase}</Option>}
														onSelect={(value, option) => {
															formikBag.setFieldValue(field.name, option.props.item.id);
															formikBag.setFieldValue('vccCase', option.props.item.vccCase);
														}}
														onChange={(value = '') => {
															value === '' && formikBag.setFieldValue(field.name, null);
															formikBag.setFieldValue('vccCase', value);
														}}
														value={formikBag.values.vccCase}
														disabled={this.props.companyAutocomplete}
													/>
													{form.touched.injuryId && form.errors.injuryId ? <div className="validation-message">{form.errors.injuryId}</div> : ''}
												</div>
											</div>
										)}
									/>
								</div>
								<div className="row">
									<div
										className={`form-group col-sm-6 ${formikBag.errors.date && formikBag.touched.date ? 'has-error' : ''}`}>
										<label>Date</label>
										<div className="is-relative">
											<Datepicker
												value={formikBag.values.date}
												format="MM/DD/YY"
												maxDateToday={true}
												onChange={(date) => {
													formikBag.setFieldValue('date', date);
												}}
												isDisabled={callLogId > 0 ? true : true}
												//disabled={formikBag.values.companyTypeId != SpecialCompanyType.Employer}

											/>
											{formikBag.touched.date && formikBag.errors.date ? <div className="validation-message">{formikBag.errors.date}</div> : ''}
										</div>
									</div>
									<div
										className={`form-group col-sm-6 ${formikBag.errors.callTime && formikBag.touched.callTime ? 'has-error' : ''}`}>
										<label>Time</label>
										<Field
											name="callTime"
											render={({field, form}: FieldProps<FormValues>) => (
												<div className="is-relative">
													<Timepicker
														antdProps={timepickerProps}
														value={field.value}
														onChange={(time) => {
															formikBag.setFieldValue(field.name, time);
														}}/>
													{form.touched.callTime && form.errors.callTime ? <div className="validation-message">{form.errors.callTime}</div> : ''}
												</div>	
											)}
										/>
									</div>
								</div>
								<Field
									name="note"
									render={({ field, form }: FieldProps<FormValues>) => (
										<div className={`form-group ${formikBag.errors.note && formikBag.touched.note ? 'has-error' : ''}`}>
											<label htmlFor="callLogNotes">Note</label>
											<div className="is-relative">
												<textarea className="form-control" id="callLogNotes" {...field}></textarea>
												{form.touched.note && form.errors.note ? <div className="validation-message">{form.errors.note}</div> : ''}
											</div>
										</div>
									)}
								/>
								<div className="text-right form-group">
									<Button isLoading={this.state.isLoading} className="btn btn-success" onClick={e => {e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit()}}>Save</Button>
									<button type="button" className="btn btn-danger" onClick={() => {
										formikBag.resetForm();
										this.props.onCancel();
									}}>Cancel</button>
								</div>
								{this.messages()}
							</Form>
						)}
					/>
				</React.Fragment>	
			)
		}

		return <Loading />
	}
}

export default connect<{}, any, OwnProps>(
	(state: ApplicationState) => ({
		...state.callLog,
		login: state.login
	}),
	dispatchToProps
)(CallLogEditor);