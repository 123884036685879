import {
	PageProps as BasePageProps,
	ExtendableItemEditor as BaseExtendableItemEditor,
	ItemEditor as BaseItemEditor
} from '@common/react/components/pages/ItemEditor';
import {User} from "@app/objects/User";
import {ApplicationState} from "@app/store";

import * as React from 'react';

export interface PageProps<T> extends BasePageProps<T, User, ApplicationState> {};

export class ExtendableItemEditor<T, TPropsExtension> extends BaseExtendableItemEditor<T, User, ApplicationState, TPropsExtension>{
	messages() {
		//todo translate
		return (
			<React.Fragment>
				{this.state.success ? <div className="alert alert-success">Successfully saved</div> : ''}
				{this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : ''}
			</React.Fragment>
		);
	}
}

export default class ItemEditor<T> extends BaseItemEditor<T, User, ApplicationState> {
	messages() {
		//todo translate
		return (
			<React.Fragment>
				{this.state.success ? <div className="alert alert-success">Successfully saved</div> : ''}
				{this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : ''}
			</React.Fragment>
		);
	}
};

export {dispatchToProps} from '@common/react/components/pages/ItemEditor';