import * as React from 'react';

import Table from 'antd/lib/table';

import {ExtendableItemsPage} from '@common/react/components/Pages/ItemsPage';
import {WithId} from '@common/typescript/objects/WithId';
import {request} from '@common/react/components/Api';
import { Checkbox } from 'antd';

interface ModifedItems<T> {
	[key: number]: T | undefined;
}

interface FieldError {
	[key: string]: string;
}

interface Errors {
	[key: number]: FieldError | undefined;
}

export interface EditableItemsPageState<T> {
	modifiedItems: ModifedItems<T>;
	errors: Errors;
}

export class ExtendableEditableItemsPage<
		TEntity extends WithId, 
		TPropsExtension, 
		TState extends EditableItemsPageState<TEntity> = { modifiedItems: {}, errors: {} }
	> extends ExtendableItemsPage<
		TEntity, 
		TPropsExtension, 
		TState
	> {
	validationSchema: any;
	baseAction = {
		title: '',
		render: (text, record) => {
			return (
				<div className="text-right table-actions">
					{this.isEdit(record.id) ?
						<div>
							<button className="btn btn-sm btn-primary" type="button" title="Save" onClick={e => this.handleInputSubmit(record)}>
								<i className="fa fa-save"/>
							</button>
							<button className="btn btn-sm btn-default"  type="button" title="Cancel" onClick={e => this.handleInputCancel(record)}>
								<i className="fa fa-times"/>
							</button>
							{record.id !== -1 && <button className="btn btn-sm btn-danger"  type="button" title="Delete" onClick={e => this.handleDelete(e, record)}>
								<i className="fa fa-trash"/>
							</button>
							}
						</div>
						: <div>
							<button className="btn btn-sm btn-default"  type="button" title="Edit" onClick={e => this.handleInput(record)}>
								<i className="fa fa-pencil"/>
							</button>
							<button className="btn btn-sm btn-danger"  type="button" title="Delete" onClick={e => this.handleDelete(e, record)}>
								<i className="fa fa-trash"/>
							</button>
						</div>
					}
				</div>
			);
		}
	};
	
	nameColumn = {
		title: 'Name',
		dataIndex: 'name',
		render: (text: string, record: TEntity) => this.getSimpleEditField('name', text, record.id, 'Name')
	};

	constructor(props: any) {
		super(props);

		this.textInput = React.createRef();
		
		this.columns = [
			this.idColumn,
			this.nameColumn,
			this.baseAction
		];
		
		this.state = {
			modifiedItems: {},
			errors: {}
		} as TState;
		
		this.handleInput = this.handleInput.bind(this);
		this.handleInputSubmit = this.handleInputSubmit.bind(this);
		this.handleInputCancel = this.handleInputCancel.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.isEdit = this.isEdit.bind(this);
		this.saveItem = this.saveItem.bind(this);
		this.getSimpleEditField = this.getSimpleEditField.bind(this);
	}
	
	getSimpleEditField(fieldName: string, value: string, recordId: number, mobileCaption: string = ''): JSX.Element {
		const error = this.getError(recordId, fieldName);
		
		return this.isEdit(recordId) 
			? <div className={error ? 'has-error' : ''}>
				<div className="is-relative">
					<input 
						className="form-control" 
						id={`${fieldName}-${recordId}`} 
						type="text" 
						defaultValue={value} 
						onChange={event => this.setItemPropValue(recordId, fieldName, event.target.value)} 
					/>
					{error ? <div className="validation-message">{error}</div> : ''}
				</div>
			</div>
			: this.renderMobileCell(mobileCaption, value);
    }

    getNumberEditField(fieldName: string, value: number, recordId: number, mobileCaption: string = ''): JSX.Element {
        const error = this.getError(recordId, fieldName);

        return this.isEdit(recordId)
            ? <div className={error ? 'has-error' : ''}>
                <div className="is-relative">
                    <input
                        className="form-control"
                        id={`${fieldName}-${recordId}`}
                        type="number"
                        defaultValue={value?value.toString():''}
                        onChange={event => this.setItemPropValue(recordId, fieldName, event.target.value)}
                    />
                    {error ? <div className="validation-message">{error}</div> : ''}
                </div>
            </div>
            : this.renderMobileCell(mobileCaption, value?value.toString():'');
    }

    getYesNoEditField(fieldName: string, value: boolean, recordId: number, mobileCaption: string = ''): JSX.Element {
        const error = this.getError(recordId, fieldName);
        
        return this.isEdit(recordId)
            ? <div className={error ? 'has-error' : ''}>
                <div className="is-relative">
                    <input
                        className="form-control"
                        id={`${fieldName}-${recordId}`}
                        type="checkbox"
                        defaultChecked={value}
                        onChange={event => this.setItemPropValue(recordId, fieldName, event.target.checked) }
                    />

                    {error ? <div className="validation-message">{error}</div> : ''}
                </div>
            </div>
            : this.renderMobileCell(mobileCaption, value? 'Yes':'No');
    }
	
	getNewItem() {
		return {id: -1};
	}

	handleInput(record) {
		this.setState(prevState => ({
			modifiedItems: {
				...prevState.modifiedItems,
				[record.id]: {
					...record
				}
			}
		}));
	}
	
	transformData(record: TEntity): TEntity {
		return record;
	}
	
	handleInputSubmit(record) {
		this.setState(prevState => ({
			errors: {
				...prevState.errors,
				[record.id]: undefined
			}
		}));

		const modifiedRecord = {
			...record,
			...this.state.modifiedItems[record.id]
		};

		if (this.validationSchema) {
			this.validationSchema.validate(modifiedRecord, { abortEarly: false }).then(() => {
				this.saveItem(this.transformData(modifiedRecord));
			}).catch((err) => {
				const errors = {};
				for (let i = 0; i < err.inner.length; i++) {
					errors[err.inner[i].path] = err.inner[i].errors[0];
				}

				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						[record.id]: errors
					}
				}));
			});
		} else {
			this.saveItem(modifiedRecord);
		}
	}
	
	saveItem(item: TEntity) {
		request(this.type, item).then((response: any) => {
			this.setState(prevState => ({
				modifiedItems: {
					...prevState.modifiedItems,
					[item.id]: undefined
				}
			}));

			if (item.id === -1) {
				this.props.refreshPages(this.store, this.path);
			} else {
				this.props.updateItem(this.store, response);
			}
		});
	}

	handleInputCancel(record) {
		this.setState(prevState => ({
			modifiedItems: {
				...prevState.modifiedItems,
				[record.id]: undefined
			},
			errors: {
				...prevState.errors,
				[record.id]: undefined
			}
		}));

		if (record.id === -1) {
			this.props.deleteItem(this.store, -1);
		}
	}

	getError(id: number, propName: string): string | undefined {
		const itemErrors = this.state.errors[id];
		return itemErrors && itemErrors[propName];
	}

    handleAdd() {
		const newId = -1;
		if (!this.isEdit(newId)) {
			const newItem = this.getNewItem();

            console.log(newItem)
			this.setState(prevState => ({
				modifiedItems: {
					...prevState.modifiedItems,
					[newId]: newItem as TEntity
				}
			}));

            console.log(this.store,newItem)
			this.props.addItem(this.store, newItem);
		}
	}
	
	isEdit(id: number): boolean {
		return typeof this.state.modifiedItems[id] !== 'undefined';
	}

	setItemPropValue(id: number, propName: string, value: any) {
		this.setState(prevState => ({
			modifiedItems: {
				...prevState.modifiedItems,
				[id]: {
					...prevState.modifiedItems[id],
					[propName]: value
				} as TEntity
			}
		}));
		
		if (this.state.errors[id]) {
			this.setState(prevState => ({
				errors: {
					...prevState.errors,
					[id]: {
						...prevState.errors[id],
						[propName]: undefined
					}
				}
			}));
		}
	}
	
	setItemPropValuePromisified(id: number, propName: string, value: any) {
		const promise = new Promise((resolve) => {
			this.setState(prevState => ({
				modifiedItems: {
					...prevState.modifiedItems,
					[id]: {
						...prevState.modifiedItems[id],
						[propName]: value
					} as TEntity
				}
			}), () => {
				resolve();
			});
		});
		promise.then(() => {
			if (this.state.errors[id]) {
				this.setState(prevState => ({
					errors: {
						...prevState.errors,
						[id]: {
							...prevState.errors[id],
							[propName]: undefined
						}
					}
				}));
			}
		});
		
		return promise;
	}

	handleTableChange(pagination, filters, sorter) {
		this.setState({
			modifiedItems: []
		});
		
		super.handleTableChange(pagination, filters, sorter);
	}
	
	renderTable(): JSX.Element {
		const {items, pagination, isLoading} = this.props;
		
		return <Table
			columns={this.columns}
			dataSource={items.length > this.count ? items.slice(0, this.count) : items}
			pagination={pagination}
			loading={isLoading}
			onChange={this.handleTableChange}
			childrenColumnName="child"
			rowKey={this.rowKey}
			className={this.tableClassName}
		/>;
	}
}

export default class EditableItemsPage<
	T extends WithId, 
	TState extends EditableItemsPageState<T> = { modifiedItems: {}, errors: {} }
> extends ExtendableEditableItemsPage<T, {}, TState> {

}
