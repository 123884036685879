import * as React from 'react';
import {connect} from 'react-redux';

import {ActionCreatorsMapObject, bindActionCreators} from 'redux';

import {BaseUser} from '@common/react/objects/BaseUser';
import {Comment as TComment, getActionCreators, ItemComments} from '@common/react/store/Comments';
import CommentTreeNode from '@common/react/components/Forms/Comment/CommentTreeNode';
import IComment, {CommentProps} from '@common/react/components/Forms/Comment/IComment';
import ICommentForm, {CommentFormProps} from '@common/react/components/Forms/Comment/ICommentForm';
import {BaseParams} from '@common/react/objects/BaseParams';

interface OwnProps  {
	objectType: string;
	objectId: number;
	additionalParams?: BaseParams;
	newCommentExtendProperties?: BaseParams;
	stateName?: string;
}

interface ComponentProps<TUser extends BaseUser> {
	commentComponent: IComment<TUser>;
	commentFormComponent: ICommentForm<TUser>;
	
	withoutAddButton?: boolean;
	submitButtonCaption?: string;
	readOnly?: boolean; 
	insertBefore?: boolean;
}

interface StateProps<TUser extends BaseUser> {
	root?: TComment<TUser>;
	user?: TUser;
}

type CommentsProps<TUser extends BaseUser> = OwnProps & ComponentProps<TUser> & StateProps<TUser> & { actions?: ActionCreatorsMapObject};

interface CommentsState<TUser extends BaseUser> {
	adding: boolean;
	tempComment?: TComment<TUser>;
}

export class BaseCommentTree<TUser extends BaseUser> extends React.Component<CommentsProps<TUser>, CommentsState<TUser>>{
	constructor(props: CommentsProps<TUser> & { actions?: ActionCreatorsMapObject}) {
		super(props);
		
		this.state = {
			adding: !!this.props.withoutAddButton,
			tempComment: this.getNewComment()
		};

		this.addComment = this.addComment.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCancelAdd = this.handleCancelAdd.bind(this);
	}
	
	componentWillMount() {
		if (this.props.actions) {
			this.props.actions.loadTree(this.props.objectType, this.props.objectId, this.props.additionalParams, this.props.stateName);
		}
	}
	
	componentWillReceiveProps(newProps: CommentsProps<TUser> & { actions?: ActionCreatorsMapObject}) {
		if (newProps.objectId !== this.props.objectId || newProps.objectType !== this.props.objectType) {
			if (this.props.actions) {
				this.props.actions.loadTree(newProps.objectType, newProps.objectId, newProps.additionalParams, this.props.stateName);
			}

			if (newProps.user) {
				this.setState({
					tempComment: {
						id: -1,
						parent: 0,
						objectType: newProps.objectType,
						objectId: newProps.objectId,
						usr: newProps.user,
						user: newProps.user.id,
						text: '',
						...newProps.newCommentExtendProperties
					}
				});
			}
		}
	}
	
	private getNewComment(): TComment<TUser> | undefined {
		const {user, objectType, objectId} = this.props;
		
		if (user) {
			return {
				id: -1,
				parent: 0,
				objectType: objectType,
				objectId: objectId,
				usr: user,
				user: user.id,
				text: '',
				...this.props.newCommentExtendProperties
			};
		}
		
		return;
	}
	
	private addComment() {
		debugger;
		this.setState({
			tempComment: this.getNewComment(),
			adding: true
		});
	}
	
	public handleSubmit(values) {
		debugger;
		return this.props.actions
			? this.props.actions.addComment({ ...this.state.tempComment, ...values }, this.props.insertBefore, this.props.stateName).then(() => {
				if (this.props.withoutAddButton) {
					this.setState({
						tempComment: this.getNewComment(),
						adding: true
					});
				} else {
					this.setState({
						tempComment: undefined,
						adding: false
					});
				}
			})
			: Promise.resolve();
	}
	
	public handleCancelAdd() {
		this.setState({
			tempComment: undefined,
			adding: false
		});
	}
	
	public render() {
		const CommentForm = this.props.commentFormComponent;
		
		const {root, objectType, objectId, commentComponent, withoutAddButton, submitButtonCaption, readOnly = false, stateName} = this.props;
		
		const { adding, tempComment } = this.state;
		
		return root 
			?	<div className="comments-component">
					{!readOnly &&
						<React.Fragment>
							{adding && tempComment 
								? <CommentForm 
									item={tempComment}
									handleSubmit={this.handleSubmit}
									handleCancel={this.handleCancelAdd}
									withoutAddButton={withoutAddButton}
									submitButtonCaption={submitButtonCaption}
								/>
							: <button type="button" className="btn btn-default comments-component__add" onClick={this.addComment}>Add Comment</button>
						}
						</React.Fragment>
					}
					{root.childrenIds
						&& root.childrenIds.list
							.map((childId, index) => 
								<CommentTreeNode  
									key={`${objectType}-${objectId}-${childId}`}
									commentComponent={commentComponent}
									commentFormComponent={CommentForm}
									objectType={objectType}
									objectId={objectId}
									id={childId}
									index={index}
									submitButtonCaption={submitButtonCaption}
									stateName={stateName}
								/>)}
				</div>
			: 	null;
	}
}

export default connect<any, { actions?: ActionCreatorsMapObject}, any>(
	(state, ownProps?: OwnProps) => {
		if (!ownProps) { return {}; }

		const objectType = ownProps.objectType;
		const objectId = ownProps.objectId;
		
		const stateName = ownProps.stateName || 'comments';
		
		return { 
			root: state[stateName][objectType]
				&& state[stateName][objectType][objectId]
				&& state[stateName][objectType][objectId].root,
			user: state.login.user
		};
	},
	dispatch => ({
		actions: bindActionCreators(getActionCreators(), dispatch)
	})
)(BaseCommentTree);