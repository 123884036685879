import * as React from "react";
import {getAvatar} from "@app/components/Utils/Utils";
import {TComment} from "@app/objects/Comment";
import {User, UserRole} from "@app/objects/User";
import {dateFormat as commonDateFormat, getBackgroundUrl} from "@common/typescript/Utils";
import Button from "@common/react/components/Forms/Button";
import Modal from "antd/lib/modal";

interface CommentNodeProps {
	index?: number,
	item: TComment<User>,
	currentUser?: User,
	handleDelete?: () => Promise<void>,
	deleteDialogVisible?: boolean,
	isDeleting?: boolean,
	handleDeleteClick?: () => void,
	handleDeleteCancel?: () => void,
	handleView?: (item: TComment<User>) => void,
	handleEdit?: () => void
}




const CommentNode: React.SFC<CommentNodeProps> = ({index, item, currentUser, handleDelete, deleteDialogVisible = false, handleDeleteClick, isDeleting, handleDeleteCancel, handleView, handleEdit}) => {
	const userName = item.usr ? `${(item.usr as any).firstName || ''} ${(item.usr as any).lastName || ''}` : '';

	const avatar = getAvatar(item.usr);


	
	return <div onMouseEnter={!item.viewed && handleView && currentUser && currentUser.id !== item.user ? () => handleView(item) : undefined}>
		<div className="comment-node__info">
			<div className="comment-node__info-user comment-node-user">
				<div className="comment-node-user__avatar" style={{backgroundImage: getBackgroundUrl(avatar)}}></div>
				<div className="comment-node-user__name">
                    {userName}
                    <div className="comment-node__info-date" style={{ color: "#212529",fontWeight: "bold",fontSize: "13px"}}>{item.time && commonDateFormat(item.time, { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })}</div>
				</div>
				{item.viewed && <i className="fa fa-eye comment-node__viewed-icon"></i> }
			</div>
		</div>
		<div className="comment-node__text">
			{index == 0 && (item as any).commentType == 1 ? <b>{item.text}</b> : item.text }
			<div className="comment-node__buttons">
				{/*<button type="button" className="btn btn-default" onClick={this.props.handleReply}>Reply</button>*/}
				{currentUser && (currentUser.role == UserRole.Admin || currentUser.id == item.user) &&
					<React.Fragment>
						<Button type="button" className="comment-node__button" title="Edit" isLoading={isDeleting} onClick={handleEdit}><i className="fa fa-pencil"></i></Button>
						<Button type="button" className="comment-node__button" title="Delete" isLoading={isDeleting} onClick={handleDeleteClick}><i className="fa fa-remove"></i></Button>
						<Modal title="Delete Comment" visible={deleteDialogVisible} onOk={handleDelete} onCancel={handleDeleteCancel}>
							<p>Comment will be deleted. Are you sure?</p>
						</Modal>
					</React.Fragment>
				}
			</div>
		</div>
	</div>
};


export default CommentNode;