import * as React from "react";

import { Link } from 'react-router-dom';

import moment from "moment";

import { Injury, InjuryStatus } from "@app/objects/Injury";
import Event from "@app/objects/Event";


import { ActionCreatorsMapObject, bindActionCreators } from "redux";

import BigCalendar from "react-big-calendar";
import { connect } from "react-redux";
import { ApplicationState } from "@app/store";
import { getEventActionCreators } from "@app/store/Event";
import Modal from "antd/lib/modal";

import { Reminder } from "@app/objects/Reminder";
import { getSelectedInjuryReminderActionCreators } from "@app/store/InjuryReminder";

import Popover from 'antd/lib/popover';
import { dateFormat, getUserName } from "@app/components/Utils/Utils";
import Schedule, { DayOfWeek, WeekDay } from "@app/objects/Schedule";
import AddEventPopup from "@app/components/Pages/Employees/InjurySchedule/AddEventPopup";
import { BaseParams } from "@common/react/objects/BaseParams";
import { request } from "@app/components/Api";
import { deleteConfirmation } from "@app/components/Utils/Modal";
import { Loading } from "@app/components/UI/Loading";
import { Tabs, Calendar } from "antd";
import { List } from "@common/typescript/objects/List";
import Table, { ColumnProps } from "antd/lib/table";
import FollowUp from "@app/objects/FollowUp";
import { FollowUpDoneCheckbox } from "../Employees/InjuryStatusForm/FollowUpDoneCheckbox";
const { TabPane } = Tabs;

import * as ItemsState from '@common/react/store/ItemList';
import * as PagesState from "@app/store/ItemList";
import { SortingDirection } from "@common/react/components/Pages/ItemsPage";
import CalenderFilters, { CalenderFiltersValues } from "./CalenderFilters";
import { StatusHistory } from "@app/objects/StatusHistory";

interface ReduxProps {
    location: any;
    followUps: PagesState.ItemsState<Reminder>;
    statusHistory: PagesState.ItemsState<StatusHistory>
}

interface OwnProps {
    refresh?: boolean;
}

interface Actions {
    countersActions: ActionCreatorsMapObject;
    listActions: ActionCreatorsMapObject
}

type CalenderProps = OwnProps & Actions & ReduxProps & {
    actions: ActionCreatorsMapObject;
    injuryReminderActions: ActionCreatorsMapObject;
    injuryStatusHistoryActions: ActionCreatorsMapObject;
};

interface CalenderState {
    setViewModalVisible: boolean;
    setEditModalVisible: boolean;
    currentEvent?: any;
    date: Date;
    c1date: moment.Moment;
    c2date: moment.Moment;
    view: string;
    reminders: Reminder[];
    statuses: StatusHistory[];
    isLoading: boolean;
    items: ItemsState.ItemsState<Reminder>;
    rightCalenderMode: "month" | "year" | undefined;
    activeTabKey: string | undefined;
    status: ItemsState.ItemsState<Injury>;
}

interface CalendarEventProps {
    event: any;
    title: any;
    isAllDay: any;
    localizer: any;
}

BigCalendar.momentLocalizer(moment);

export const renderMobileCell = (caption: string, text: string | JSX.Element | null): JSX.Element => {
    return <React.Fragment>
        <div className="table-mobile__caption">{caption}</div>
        <div className="table-mobile__content">{text}</div>
    </React.Fragment>;
};

class Calender extends React.Component<CalenderProps, CalenderState> {
    form: any;
    type: string = 'page';
    store: string = 'pages';
    path: string = 'pageList';
    listPath: string = 'pages';
    editorPath: string = 'page-editor';
    editorCaption: string = 'Add page';
    caption: string = 'Pages';
    additionalParams: BaseParams = {};
    followUpsCollumns: ColumnProps<Reminder>[];
    StatusCollumns: ColumnProps<Injury>[];
    calls: any;
    overdue: string | null;
    Historypath: string = 'pageList';

    constructor(props) {
        super(props);
        this.overdue = null;
        if (this.props && this.props.location) {
            const params = new URLSearchParams(this.props.location.search);
            this.overdue = params.get('overdue');
        }
        this.state = {
            rightCalenderMode: "month",
            isLoading: false,
            setViewModalVisible: false,
            setEditModalVisible: false,
            reminders: [],
            statuses:[],
            status: {
                isLoading: true,
                items: [],
                pagination: {
                    total: 0,
                    current: 0,
                    offset: 0
                },
                type: 'Pending',
                params: {

                }
            },
            date: new Date(),
            c1date: moment(),
            c2date: moment().add(1, 'months'),
            view: "day",
            items: {
                isLoading: true,
                items: [],
                pagination: {
                    total: 0,
                    current: 0,
                    offset: 0
                },
                type: 'Reminder',
                params: {

                }
            },
            activeTabKey: this.overdue=="true"?"2":"1"
        };

        this.type = 'reminder';
        this.store = 'reminders';
        this.path = 'reminderList';
        this.listPath = 'calender';
        this.editorPath = 'calender-editor';
        this.caption = 'Calender';
        this.editorCaption = '';
        this.Historypath = 'injuryList';


        this.followUpsCollumns = [
            {
                title: 'Injury',
                dataIndex: 'injury',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Injury', record.followUp && record.followUp.injury ? <Link to={`/employe-editor/${record.followUp.injury.employeId}?case=${record.followUp.injury.vccCase}`}>{record.followUp.injury.vccCase}</Link> as any : '')
                },
                sorter: true,
                width: 110
            },
            {
                title: 'Employee Name',
                dataIndex: 'employeeName',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Employee Name', record.followUp && record.followUp.injury && record.followUp.injury.employe ? getUserName(record.followUp.injury.employe) : '')
                },
                sorter: true,
                width: 210
            },
            {
                title: 'Type',
                dataIndex: 'type',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Type', record.followUpType ? record.followUpType.name : '')
                },
                sorter: true,
                width: 200
            },
            {
                title: 'Jurisdiction',
                dataIndex: 'stateJurisdiction',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Jurisdiction', record.followUp && record.followUp.injury ? record.followUp.injury.stateJurisdiction : '')
                },
                sorter: true,
                width: 150
            },
            {
                title: 'Date',
                dataIndex: 'date',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Date', moment(record.start).format('MM/DD/YY hh:mm a'))
                },
                sorter: true,
                defaultSortOrder: "descend",
                width: 160
            },
            {
                title: 'Person Assigned',
                dataIndex: 'person',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Person Assigned', record.followUp && record.followUp.injury && record.followUp.injury.assignedPerson ? getUserName(record.followUp.injury.assignedPerson) : '')
                },
                sorter: true,
                width: 210
            },
            {
                title: 'Worksite',
                dataIndex: 'worksite',
                render: (text: string, record: Reminder) => {
                    return renderMobileCell('Worksite', record.followUp && record.followUp.schedule && record.followUp.schedule.companyName ? record.followUp.schedule.companyName : '')
                },
                sorter: true
            },
            {
                title: 'Completed',
                dataIndex: 'completed',
                render: (text: string, record: Reminder) => {

                    return renderMobileCell('Completed', <div style={{ 'display': 'inline-block' }}>
                        <FollowUpDoneCheckbox item={record} type="reminder" onCheck={this.folowUpDone} />
                    </div>)
                },
                sorter: true,
                width: 115
            }
        ];

        this.StatusCollumns = [
            {
                title: 'Injury',
                dataIndex: 'injury',
                render: (text: string, record: Injury) => {
                    return renderMobileCell('Injury', record && record.vccCase ? <Link to={`/employe-editor/${record.employeId}?case=${record.vccCase}`}>{record.vccCase}</Link> as any : '')
                },
                sorter: true,
                width: 110
            },
            {
                title: 'Reason',
                dataIndex: 'reason',
                render: (text: string, record: Injury) => {
                    return renderMobileCell('Reason', record.lastStatusHistory && record.lastStatusHistory.pendingReason ? record.lastStatusHistory.pendingReason.name : '')
                },
                sorter: true,
                width: 210
            },
            {
                title: 'FollowUp Date',
                dataIndex: 'followupdate',
                render: (text: string, record: Injury) => {
                    return renderMobileCell('FollowUp Date', record.followUpDate ? moment(record.followUpDate).format('MM/DD/YY hh:mm a'):'')
                },
                sorter: true,
                defaultSortOrder: "descend",
                width: 160
            },
            {
                title: 'Employee Name',
                dataIndex: 'employeeName',
                render: (text: string, record: Injury) => {
                    return renderMobileCell('Employee Name', record.employe ? getUserName(record.employe) : '')
                },
                sorter: true,
                width: 210
            },
            {
                title: 'Person Assigned',
                dataIndex: 'person',
                render: (text: string, record: Injury) => {
                    return renderMobileCell('Person Assigned', record.assignedPerson ? getUserName(record.assignedPerson) : '')
                },
                sorter: true,
                width: 210
            },
            {
                title: 'Note',
                dataIndex: 'note',
                render: (text: string, record: Injury) => {
                    return renderMobileCell('Note', record.lastStatusHistory ? record.lastStatusHistory.note : '')
                },
                sorter: true,
                width: 210
            }
        ];

        this.checkWorkTime = this.checkWorkTime.bind(this);
        this.viewEventPopup = this.viewEventPopup.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onEventSave = this.onEventSave.bind(this);
        this.cancelPopup = this.cancelPopup.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.onPanelChange1 = this.onPanelChange1.bind(this);
        this.onPanelChange2 = this.onPanelChange2.bind(this);
        this.onSelect1 = this.onSelect1.bind(this);
        this.onSelect2 = this.onSelect2.bind(this);
        this.delete = this.delete.bind(this);


        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.requestItems = this.requestItems.bind(this);

        const date = new Date(),
            firstDay = +new Date(date.getFullYear(), date.getMonth(), -5),
            lastDay = +new Date(date.getFullYear(), date.getMonth() + 1, 6);

        this.additionalParams = {
            column: [{ caption: "date", direction: 2 }],
            injuryId: null,
            vccCase: '',
            withFollowUp: true, withInjury: true,
            notInjuryClosed: true,
            from2: firstDay,
            to2: lastDay,
            pastDue: this.overdue == "true" ? true : false
        };

    }

    get statusHistoryListParams(): BaseParams {
        return {
            page: 1,
            withReason: true,
            withUser: true,
            withOutcome: true,
            withFollowUps: true,
            withSchedule: true,
            injuryStatus: 4
        }
    }

    additionalRemindersParams: BaseParams = {
        page: 1,
        withFollowUp: true,
        withAssignedUser: true,
        withLastStatusHistory: true,
        WithEmployee: true,
        filter : {
            injuryStatus: 4
        }
    };

    folowUpDone(followUp: FollowUp | Reminder) {
        this.props.listActions.refreshPages('dashboardFollowUps', 'reminderList');
    }


    onApplyFilters(values: CalenderFiltersValues) {
        this.additionalParams = {
            ...this.additionalParams,
            vccCase: values.vccCase,
            employeeName: values.employeeName,
            from: values.date[0],
            to: values.date[1],
            userId: values.user ? values.user.id : null,
            companyId: values.company ? values.company.id : null,
            referralId: values.referral ? values.referral.id : null,
            pastDue: values.pastDue
        };

        this.requestItems(this.path, { page: 1, count: 10, ...this.additionalParams });
        this.refresh();
    }

    getFiltersRender() {
        return <div className="list-filters">
            <div className="clearfix">
                <CalenderFilters onApply={this.onApplyFilters} pastDue={this.overdue == "true" ? true : false} />
            </div>
        </div>
    }

    refresh() {
        this.setState({
            isLoading: true
        })
        this.additionalParams.withInjury = true;
        this.additionalParams.withFollowUp = true;
        request('reminderList', { isCalender: true, page: 1, count: 10000, ...this.additionalParams }).then((result: any) => {
           
            if (result)
                this.setState({
                    reminders: result.list,
                    isLoading: false
                })
        }).catch(() => {
            this.setState({
                isLoading: false
            })
        });
    }

    componentWillMount() {
        this.requestItems('reminderList', {
            page: 1,
            offset: 0,
            ...this.additionalParams
        });

        
        this.requestStatusItems('injuryList', {
            page: 1,
            offset: 0,
            ...this.additionalRemindersParams
        });


        //request('injuryList', {
        //    page: 1,
        //    offset: 0,
        //    ...this.additionalRemindersParams
        //}).then((result: any) => {
        //    debugger;
        //    if (result)
        //        this.setState({
        //            reminders: result.list,
        //            isLoading: false
        //        })
        //}).catch(() => {
        //    this.setState({
        //        isLoading: false
        //    })
        //});

        this.refresh();
    }



    handleFilter(event: React.FormEvent<HTMLFormElement> | null) {
        event && event.preventDefault();
        this.requestItems(this.path, { page: 1, ...this.additionalParams });
    }

    handleTableChange(pagination, filters, sorter) {
        if (this.state.items.pagination.current !== pagination.current) {
            this.state.items.pagination.offset = (pagination.current - 1) * 10;
        } else {
            if (sorter.column) {
                this.additionalParams.column = [{
                    caption: sorter.field,
                    direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending
                }]
            } else {
                this.additionalParams.column = [];
            }
        }
        this.requestItems(this.path, { ...this.additionalParams });

    }

    handleStatusTableChange(pagination, filters, sorter) {
        if (this.state.status.pagination.current !== pagination.current) {
            this.state.status.pagination.offset = (pagination.current - 1) * 10;
        } else {
            if (sorter.column) {
                this.additionalRemindersParams.column = [{
                    caption: sorter.field,
                    direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending
                }]
            } else {
                this.additionalRemindersParams.column = [];
            }
        }
        this.requestStatusItems(this.Historypath, { ...this.additionalRemindersParams });

    }


    requestItems(path: string, params: any) {
        this.setState({
            items: {
                ...this.state.items,
                isLoading: true
            },
            isLoading: true
        });
        params.offset = params.offset == 0 ? 0 : this.state.items.pagination.offset;
        params.isCalender = false;
        return request<List<Reminder>>(path, params).then((result: List<Reminder>) => {
            
            this.setState({
                items: {
                    isLoading: false,
                    items: result.list,
                    pagination: {
                        total: result.count,
                        current: result.offset / 10 + 1,
                        offset: result.offset
                    },
                    type: 'Reminder',
                    params: {}
                },
                isLoading: false
            })
        }).catch((e) => {
            console.log(e);
            this.setState({
                isLoading: false
            })
        });
    }

    requestStatusItems(path: string, params: any) {
        this.setState({
            status: {
                ...this.state.status,
                isLoading: true
            },
            isLoading: true
        });
        params.offset = params.offset == 0 ? 0 : this.state.items.pagination.offset;
        params.isCalender = false;

        return request<List<Injury>>(path, params).then((result: List<Injury>) => {
            
            this.setState({
                status: {
                    isLoading: false,
                    items: result.list,
                    pagination: {
                        total: result.count,
                        current: result.offset / 10 + 1,
                        offset: result.offset
                    },
                    type: 'Pending',
                    params: {}
                },
                isLoading: false
            })
        }).catch((e) => {
            console.log(e);
            this.setState({
                isLoading: false
            })
        });
    }



    componentWillReceiveProps(nextProps: Readonly<CalenderProps>): void {
        {/*if (this.props.refresh !== nextProps.refresh) {
            this.props.actions.reqPages('events', 'eventList', 'event', { page: 1, injury: this.props.injury.id, withCalls: true, count: 100, withSchedule: true, ...this.dateParams });
        }*/}
    }

    onEventSave() {
        {/*this.props.actions.refreshPages('events', 'eventList', { page: 1, injury: this.props.injury.id, withCalls: true, count: 100, withSchedule: true, ...this.dateParams });
        */} this.cancelPopup();
    }

    getTime(date) {
        date = new Date(date);
        let time = '';
        const minutes = date.getMinutes().toString().length === 2 ? date.getMinutes() : '0' + date.getMinutes();

        if (date.getHours() == 0) {
            time = '12:' + date.getMinutes() + ' am';
        } else if (date.getHours() > 0 && date.getHours() < 12) {
            time = date.getHours() + ':' + minutes + ' am';
        } else if (date.getHours() > 12) {
            time = date.getHours() - 12 + ':' + minutes + ' pm';
        }

        return time.length == 8 ? time : '0' + time;
    }

    viewEventPopup(event: Event | null, action: 'edit' | 'show') {
        const state = {
            currentEvent: (event != null ? event : {})
        };


        state[action === 'show' ? 'setViewModalVisible' : 'setEditModalVisible'] = true;

        this.setState(state)
    }

    cancelPopup() {
        this.setState({
            setViewModalVisible: false,
            setEditModalVisible: false,
        })
    }

    getViewModalContent(event: Event) {
        if (event.startTime && event.endTime) {

            const eventDay = moment(event.startTime).day();
            const getTime = this.getTime;

            return <div>
                {event.schedule && event.schedule.workDays && event.schedule.workDays.list.length > 0 && event.schedule.workDays.list.map((day: WeekDay) =>
                    <div key={day.id} className={`schedule-popup-day ${eventDay === day.day ? 'schedule-popup-day_current' : ''}`}>
                        <h5>{DayOfWeek[day.day]}</h5>
                        <div>
                            <strong>Time:</strong> {day.startTime && moment(day.startTime.slice(0, 5).replace(':', ''), 'Hmm').format('hh:mm a')} - {day.endTime && moment(day.endTime.slice(0, 5).replace(':', ''), 'Hmm').format('hh:mm a')}
                        </div>
                        <div>
                            <strong>Late in Alert:</strong> {day.lateInAlert} / <strong>Late out Alert:</strong> {day.lateOutAlert}
                        </div>
                    </div>
                )}

                {event.lunchStartTime > 0 && event.lunchEndTime > 0 && <div className="row">
                    <div className={`form-group col-sm-6`}>
                        <label>Lunch Start Time:</label><br />
                        <span>{getTime(event.lunchStartTime)}</span>
                    </div>
                    <div className={`form-group col-sm-6`}>
                        <label htmlFor="lunchEndTime">Lunch End Time:</label><br />
                        <span id="lunchEndTime">{getTime(event.lunchEndTime)}</span>
                    </div>
                </div>}

                {event.note && <div className={`row`}>
                    <div className={`form-group col-sm-12`}>
                        <label>Note:</label><br />
                        <span>{event.note}</span>
                    </div>
                </div>}
            </div>;
        }

        return null;
    }

    isPastDate(date: number) {
        let today = moment(new Date()).startOf('day').add(moment().utcOffset(), 'minutes');
        let selectedDate = moment(date).utcOffset(0).startOf('day');
        return moment(+today).isAfter(moment(+selectedDate));
    }

    checkWorkTime(events, date, checkedDays) {
        let realTime = 0;
        let workTime = 0;

        const day = ('0' + date.getDate()).slice(-2);



        return <div className={`pull-right`}>
            {day}
        </div>;
    }
    handleSelect(range) {
        this.setState({
            date: range.start,
            view: "day"
        })
    }

    handleRangeChange(range) {
        if (range.length == 1) range[1] = new Date(range[0]).getTime() + 86400000;
        if (range) {
            this.additionalParams.from2 = range.start ? range.start : range[0];
            this.additionalParams.to2 = range.end ? range.end : range[range.length - 1];

            {/*this.props.actions.refreshPages('events', 'eventList', { page: 1, injury: this.props.injury.id, count: 100, withSchedule: true, ...this.dateParams });*/ }
            this.refresh();
        }
    }

    handleDeleteEvent(event) {
        const data = {
            ...event,
            deleted: true
        };

        const self = this;

        {/*deleteConfirmation(() => request('event', data).then(() => { self.props.actions.refreshPages('events', 'eventList', { page: 1, injury: self.props.injury.id, withCalls: true, count: 100, withSchedule: true, ...self.dateParams }) }), 'This item will be permanently deleted and cannot be recovered. It may contain calls that will be deleted also. Are you sure?');*/ }
    }

    delete(reminder: Reminder) {
        reminder.deleted = true;
        deleteConfirmation(() => {
            request('reminder', reminder).then(result => {
                this.refresh();

            });
        });
    }
    eventStyleGetter(event, start, end, isSelected) {
        var style = {
            backgroundColor: new Date(end).getTime() < Date.now() && !event.done ? 'red' : '#00c0ef',
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            display: 'block',
            textDecoration: event.done ? 'line-through' : ''
        };
        return {
            style: style
        };
    }
    onPanelChange1(value: moment.Moment | undefined, mode) {
        if (value) {
            this.setState({ rightCalenderMode: mode, c2date: moment(value).add(1, mode =="month"?'months':'years'), c1date: value });

        }

    };

    onPanelChange2(value: moment.Moment | undefined, mode) {
        if (value) {
            this.setState({ rightCalenderMode: mode, c1date: moment(value).add(-1, mode == "month" ? 'months' : 'years'), c2date: value });

        }

    };

    onSelect1(value: moment.Moment | undefined) {
        if (value) {

            let type: moment.unitOfTime.StartOf = this.state.rightCalenderMode == 'month' ? 'day' : 'month';
            this.setState({
                date: value.toDate(),
                view: type, c1date: value
            })
            this.additionalParams.from2 = value.startOf(type).unix() * 1000;
            this.additionalParams.to2 = moment(value).startOf(type).add(1, this.state.rightCalenderMode == 'month' ?'days':'months').unix() * 1000;
            this.refresh();
        }
    };


    onSelect2(value: moment.Moment | undefined) {
        if (value) {
            let type: moment.unitOfTime.StartOf = this.state.rightCalenderMode == 'month' ? 'day' : 'month';

            this.setState({
                date: value.toDate(),
                view: type, c2date: value
            })
            this.additionalParams.from2 = value.startOf(type).unix() * 1000;
            this.additionalParams.to2 = moment(value).startOf(type).add(1, this.state.rightCalenderMode == 'month' ? 'days' : 'months').unix() * 1000;
            this.refresh();
        }
    };

    render() {

        const { statusHistory } = this.props;

        const remindersFromProps: Reminder[] = this.state.reminders;

        const utcOffset = -moment().utcOffset();

        const { currentEvent, setEditModalVisible, setViewModalVisible } = this.state;

        const statuses = statusHistory ? statusHistory.items : [];
        
        let reminders: any[] = remindersFromProps.map(reminder => {
            reminder.title = 'Reminder';
            return reminder;
        });

        reminders = reminders.map(reminder => {
            reminder.end = new Date(((reminder.end as number)));
            reminder.start = new Date(((reminder.start as number)));
            return reminder;
        });
        
        let checkedDays: any[] = [];

        let events = reminders;
        return <div className="scheduler col-sm-12" >
            <div className="site-headline site-headline_with-button clearfix">

                {this.getFiltersRender()}

            </div>
            <div className="row">
                
                <Tabs type="card" defaultActiveKey={this.state.activeTabKey}>
                    {/*<TabPane tab="Calender" key="1">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-sm-4 calender tbl-calender">*/}

                    {/*        <Calendar value={this.state.c1date} mode={this.state.rightCalenderMode} fullscreen={false} onPanelChange={this.onPanelChange1} onSelect={this.onSelect1} />*/}
                    {/*        <Calendar value={this.state.c2date} mode={this.state.rightCalenderMode} fullscreen={false} onPanelChange={this.onPanelChange2} onSelect={this.onSelect2} />*/}
                    {/*    </div>*/}
                    {/*    <button type="button" className="btn btn-default" onClick={() => this.viewEventPopup(null, 'edit')} >Edit Schedule</button>*/}
                    {/*    {!this.state.isLoading ? <BigCalendar className="col-sm-8" eventPropGetter={(this.eventStyleGetter)}*/}
                    {/*        events={events}*/}
                    {/*        components={{*/}
                    {/*            event: (props: CalendarEventProps) => {*/}
                    {/*                const reminder = props.event as Reminder;*/}
                    {/*                const content = <div>*/}
                    {/*                    <div>*/}
                    {/*            <i className="fa fa-times scheduler-event__delete" onClick={()=>this.delete(reminder)} title="Delete reminder"></i>*/}
                    {/*            <i className="fa fa-pencil scheduler-event__edit" title="Edit reminder"></i>*/}
                    {/*        </div>*/}
                    {/*        <i className={`fa fa-eye scheduler-event__view`} title="View reminder" />*/}
                    {/*                    <div className={`scheduler-event__name`}>*/}
                    {/*                        {props.title}*/}
                    {/*                    </div>*/}
                    {/*                    {dateFormat(reminder.start, true)}*/}
                    {/*                    <br />{dateFormat(reminder.end, true)}*/}
                    {/*                    <br />{reminder.followUpType.name}*/}
                    {/*                    <br />{reminder.followUp && reminder.followUp.injury ? reminder.followUp.injury.vccCase : ''}*/}
                    {/*                    <br />By : {reminder.followUp && reminder.followUp.injury && reminder.followUp.injury.assignedPerson ? reminder.followUp.injury.assignedPerson.lastName + ' ' + reminder.followUp.injury.assignedPerson.firstName : ''}*/}
                    {/*                </div>;*/}

                    {/*                return <Popover*/}
                    {/*                    content={content}*/}
                    {/*                    placement="top">*/}
                    {/*                    <div  >*/}
                    {/*                        <Link style={{ color: '#000000' }} to={`/employe-editor/${reminder.followUp && reminder.followUp.injury ? reminder.followUp.injury.employeId : ''}?case=${reminder.followUp && reminder.followUp.injury ? reminder.followUp.injury.vccCase : ''}`}> <i className="fa fa-bell"></i>&nbsp;{reminder.followUpType.name} {reminder.followUp && reminder.followUp.injury ? reminder.followUp.injury.vccCase : ''}*/}
                    {/*                        </Link>*/}
                    {/*                    </div>*/}
                    {/*                </Popover>*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        views={['month', 'week', 'day']}*/}
                    {/*        defaultDate={new Date()}*/}
                    {/*        date={this.state.date}*/}
                    {/*        defaultView="day"*/}
                    {/*        view={this.state.view}*/}
                    {/*        selectable="ignoreEvents"*/}
                    {/*        formats={{ dateFormat: 'dd DD' }}*/}
                    {/*        onRangeChange={this.handleRangeChange}*/}
                    {/*        onSelectSlot={this.handleSelect}*/}
                    {/*        tooltipAccessor={props => props.followUpType ? props.followUpType.name : props.title}*/}
                    {/*        onView={view => {*/}
                    {/*            this.setState({*/}
                    {/*                view,*/}
                    {/*            });*/}
                    {/*        }}*/}
                    {/*        onNavigate={(day) => {*/}
                    {/*            this.setState({*/}
                    {/*                date: day,*/}
                    {/*            });*/}
                    {/*        }}*/}
                    {/*        /> :*/}
                    {/*            <div className="col-sm-8"><Loading  ></Loading></div>}*/}

                    {/*    <Modal*/}
                    {/*        title={currentEvent && currentEvent.company ? currentEvent.company.name : ''}*/}
                    {/*        visible={this.state.setViewModalVisible}*/}
                    {/*        onCancel={this.cancelPopup}*/}
                    {/*        footer={null}>*/}
                    {/*        {currentEvent && this.getViewModalContent(currentEvent)}*/}
                    {/*    </Modal>*/}

                    {/*        {setEditModalVisible && currentEvent && ""}*/}
                    {/*    </div>*/}
                    {/*</TabPane>*/}

                    <TabPane tab="FollowUp" key="1"
                        style={{ overflow: "scroll" }}>
                        <div className="tblcalender-list">
                            <Table
                            columns={this.followUpsCollumns}
                            dataSource={this.state.items.items}
                            loading={this.state.isLoading}
                            childrenColumnName="child"
                            rowKey="id"
                            pagination={this.state.items.pagination}
                            className="small-padding-table"
                            onChange={this.handleTableChange}
                            /></div>
                    </TabPane>
                    <TabPane tab="Pending" key="2"
                        style={{ overflow: "scroll" }}>
                        <div className="tblcalender-list">
                            <Table
                                columns={this.StatusCollumns}
                                dataSource={this.state.status.items}
                                loading={this.state.isLoading}
                                childrenColumnName="child"
                                rowKey="id"
                                pagination={this.state.status.pagination}
                                className="small-padding-table"
                                onChange={this.handleTableChange}
                            /></div>
                    </TabPane>

                    
                </Tabs>
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps?: OwnProps) => {
        if (!ownProps) { return {}; }

        return {
            reminders: state.injuryReminder
        }
    }
)(Calender as any);