(function ($) {
	'use strict';

	$.extend(true, $.trumbowyg, {
		langs: {
			en: {
				indent: 'Indent',
				outdent: 'Outdent'
			},
			ru: {
				indent: 'Отступ вправо',
				outdent: 'Отступ влево'
			}
		}
	});
	
	$.extend(true, $.trumbowyg, {
		plugins: {
			indent: {
				init: function (trumbowyg) {
					trumbowyg.addBtnDef('indent', {
						fn: 'indent',
						title: trumbowyg.lang.indent,
						isSupported: function () { return !!document.queryCommandSupported && !!document.queryCommandSupported('indent'); },
						hasIcon: false,
						text: '<i class="fa fa-chevron-right"></i>'
					});
				}
			}
		}
	});

	$.extend(true, $.trumbowyg, {
		plugins: {
			outdent: {
				init: function (trumbowyg) {
					trumbowyg.addBtnDef('outdent', {
						fn: 'outdent',
						title: trumbowyg.lang.outdent,
						isSupported: function () { return !!document.queryCommandSupported && !!document.queryCommandSupported('outdent'); },
						hasIcon: false,
						text: '<i class="fa fa-chevron-left"></i>'
					});
				}
			}
		}
	});
	
})(jQuery);