import * as React from 'react';

import Popover from "antd/lib/popover";
import {BaseParams} from "@common/react/objects/BaseParams";

type ComponentsProps = OwnProps;

interface OwnProps {
	onApply: <T>(values: T) => void,
	defaultValues?: BaseParams
}

export interface Filter {
	id: number;
	caption: string;
	defaultValue: any;
	value: any;
	check?: () => boolean
	getValue?: () => JSX.Element | null | (JSX.Element | null)[];
	removeFilter?: (this: Filter, id: number) => void
}

interface Filters {
	[key: string]: Filter
}

export interface FilterBaseState {
	filters: Filters;
	visible: boolean;
}


interface RemoveButtonProps {
	click: () => void
}

export const RemoveButton: React.SFC<RemoveButtonProps> = ({click}) => {
	return <i className="fa fa-times" onClick={click}></i>;
};


export default class CommonFilters<TProps extends ComponentsProps, TState extends FilterBaseState, V> extends React.PureComponent<TProps, TState> {
	constructor(props: TProps) {
		super(props);
		
		this.applyFilters = this.applyFilters.bind(this);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.getFilterMarkup = this.getFilterMarkup.bind(this);
		this.fillValuesFromDefault = this.fillValuesFromDefault.bind(this);
	}
	
	fillValuesFromDefault() {
		const defaultValues = this.props.defaultValues;
		
		if(defaultValues) {
			const keys = Object.keys(defaultValues as object);
			
			keys.forEach((key: string) => {
				if(this.state.filters[key]) 
					this.state.filters[key].value = defaultValues[key];
			});
		}
	}
	
	getFiltersValues() {
		const filters = this.state.filters,
			filterNames = Object.keys(filters);

		return filterNames.reduce((accumulator ,name: string) => {
			const filter = filters[name];

			accumulator[name] = filter.value;

			return accumulator;
		}, {})
	}

	handleVisibleChange = (show) => {
		this.setState({
			visible: show
		})
	};

	removeFilter(name: string) {
		const filter = {...this.state.filters[name]};

		filter.value = filter.defaultValue;

		this.setState({
			filters: {
				...this.state.filters as Filters,
				[name]: filter
			}
		});

		setTimeout(() => {
			this.props.onApply(this.getFiltersValues() as V);
		}, 10)
	}
	
	applyFilters(values: V) {
		const filters = this.state.filters,
			filterNames = Object.keys(filters);

		this.setState({
			filters: filterNames.reduce((accumulator ,name: string) => {
				const filter = filters[name];

				accumulator[name] = {...filter, value: values[name]};

				return accumulator;
			}, {}),
			visible: false
		});

		this.props.onApply(values);
	}

	resetFilters() {
		const filters = this.state.filters,
			filterNames = Object.keys(filters),
			newFilters = filterNames.reduce((accumulator ,name: string) => {
				const filter = filters[name];

				accumulator[name] = filter.defaultValue;

				return accumulator;
			}, {});

		this.applyFilters(newFilters as V)
	}

	getFiltersContent() {
		return <div></div>;
	}

	getFilterMarkup(filter: Filter, value: any, filterName: string): JSX.Element {
		return <span className="filters-component__item-value">{value}<RemoveButton click={() => {
			if (filter.removeFilter) {
				filter.removeFilter(filter.id);
			} else {
				this.removeFilter(filterName);
			}
		}}/></span>;
	}
	
	filtersButtons() {
		return <div className="text-right filterbtn-btn">
			<button className="btn btn-danger" type="button" onClick={this.resetFilters}>Clear filters</button>
			<button className="btn btn-success" type="submit">Apply filters</button>
		</div>
	}

	public render() {
		const {visible, filters} = this.state,
			filterNames = Object.keys(filters);
		
		return (
			<React.Fragment>
				<div className="clearfix">
					<div className="filters-component common-filters">
						<div className="filters-label">
							<span className="filters-label__caption">Filters</span>
							<Popover
								content={this.getFiltersContent()}
								visible={visible}
								trigger={"click"}
								placement="bottomLeft"
								onVisibleChange={this.handleVisibleChange}
							>
								<div className="filters-label__icon">
									<i className="fa fa-sliders"></i>
								</div>
							</Popover>
						</div>
					</div>
				</div>
				<div className="filters-component__list">
					{filterNames.map((name: string) => {
						const filter = filters[name];

						return (filter.check ? filter.check() : filter.defaultValue !== filter.value)
							? <div className="filters-component__item" key={filter.id}>
								<span className="filters-component__item-caption">{filter.caption}</span>:&nbsp;
								{filter.getValue ? filter.getValue() : this.getFilterMarkup(filter, filter.value, name)}
							</div>
							: null;
					})}
				</div>
			</React.Fragment>
		)
	}
}