import * as React from "react";
import {InjuryFile} from "@app/objects/Injury";
import Modal from "antd/lib/modal";
import File from "@common/react/components/Forms/Files/File";
import {FileType} from "@common/typescript/objects/FileInterface";

export interface InjuryFilePopupProps {
	visible: boolean;
	onCancel: () => void,
	onOk: (file: InjuryFile) => void
}

interface InjuryFilePopupState {
	tempFile: InjuryFile;
	error: string | null;
}

export default class InjuryFilePopup extends React.Component<InjuryFilePopupProps, InjuryFilePopupState> {
	constructor(props: InjuryFilePopupProps) {
		super(props);

		this.state ={
			tempFile: {
				id: -1,
				objectId: -1,
				file: null,
				description: '',
			} as any,
			error: null
		};
		
		this.updateFile = this.updateFile.bind(this);
		this.onTextAreaValueChange = this.onTextAreaValueChange.bind(this);
		this.afterClose = this.afterClose.bind(this);
		this.onOk = this.onOk.bind(this);
	}

	updateFile(result: any) {
		this.setState({
			tempFile: {
				...this.state.tempFile,
				file: result
			}
		} as any);
	}
	
	onTextAreaValueChange(event) {
		this.setState({
			tempFile: {
				...this.state.tempFile,
				description: event.target.value
			}
		})
	}
	
	afterClose() {
		this.setState({
			tempFile: {
				id: -1,
				objectId: -1,
				file: null,
				description: '',
			} as any
		});
	}
	
	onOk(file: InjuryFile) {
		if (file.file) {
			this.setState({error: null});
			this.props.onOk(file);
		} else {
			this.setState({
				error: "File was not selected"
			})
		}
	}
	
	render() {
		const {visible,  onCancel} = this.props;
		const {tempFile, error} = this.state;
		
		return <Modal
			title="Add File"
			visible={visible}
			onOk={() => this.onOk(tempFile as InjuryFile)}
			okButtonProps={{disabled: !tempFile.file}}
			onCancel={onCancel}
			afterClose={this.afterClose}>
			<div className="form-group injury-form-popup-file">
				{tempFile.file && <div className="attached-file clearfix"><i className="fa fa-file"></i>&nbsp;{tempFile.file.name}</div>}
				<File buttonCaption="Upload Document" asButton={true} objectId={-1} type="injury" update={this.updateFile} fileType={FileType.File}/>
			</div>
			<div className="form-group">
				<textarea className="form-control" value={tempFile.description || ''} placeholder="File description" onChange={this.onTextAreaValueChange} id="shortText"/>
			</div>
			{error && <div className="alert alert-danger">{error}</div>}
		</Modal>
	}
}