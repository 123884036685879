export interface CompanyType {
	id: number;
	name: string;
}

export enum SpecialCompanyType {
	NonProfit = 1,
	Referral = 2,
	Employer = 3,
	Insurance = 4
}
