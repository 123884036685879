import * as React from 'react';

import {FieldProps} from 'formik';

interface ComponentProps {
	fieldProps: FieldProps;
	containerClassName?: string;
	render?: (fieldProps: FieldProps) => JSX.Element;
	title?: string;
	inputId?: string;
	showValidateAfterSubmit?: boolean;
	inputProps?: object;
}

const defaultRender = ({form, field}: FieldProps, inputProps?: object) => 
	<input className="form-control" type="text" id={field.name} {...field} {...inputProps}/>;

export const FormikInput: React.SFC<ComponentProps> = 
	({ 
		 fieldProps, 
		containerClassName = 'form-group col-sm-12 col-md-6 ',
		 render = defaultRender, 
		 title, 
		 inputId, 
		 showValidateAfterSubmit = true, 
		 inputProps = undefined 
	}) => {
		const {form, field} = fieldProps;
		const isInvalid = (showValidateAfterSubmit ? form.submitCount > 0 : true) && form.errors[field.name] && form.touched[field.name];
	
		return <div className={`${containerClassName} ${isInvalid ? 'has-error' : ''}`}>
			{title && <label htmlFor={inputId || field.name}>{title}</label>}
			<div className="is-relative">
				{render(fieldProps, inputProps)}
				{isInvalid ? <div className="validation-message">{form.errors[field.name]}</div> : ''}
			</div>
		</div>;
	};