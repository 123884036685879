import * as React from 'react';

import * as Yup from 'yup';

import { Group } from "@app/objects/Group";
import CommonFilters, {FilterBaseState, RemoveButton} from "@app/components/UI/CommonFilter/CommonFilter";
import {dateFormat} from "@app/components/Utils/Utils";
import {Field, FieldProps, Form, Formik, FormikProps} from "formik";
import Datepicker, {disabledFrom, disabledTo} from "@common/react/components/Forms/Datepicker/Datepicker";

import Autocomplete, { Option } from "@common/react/components/Forms/Autocomplete/Autocomplete";
import { EmailStatus, EmailPriority } from '@app/objects/EmailThread';
import { EmailAccount } from '@app/objects/EmailAccount';
import { User, UserRole } from '@app/objects/User';
import { Injury } from '@app/objects/Injury';
import { GlobalSettingsState } from '@app/store/GlobalSettings';
import * as LoginState from "@app/store/Login";
type ComponentsProps = OwnProps;

interface OwnProps {
    onApply: <T>(values) => void,
    user: User | null,
    injury: Injury | null,
    globalSettings: GlobalSettingsState|null
}

const validationSchema = Yup.object().shape({
	vccCase: Yup.string().matches(/^[\d-,\s]+$/, { excludeEmptyString: true })
});

export interface MailBoxFiltersValues {
	vccCase: string;
    fromEmail: string;
    group: Group | null;
    emailAccount: EmailAccount | null;
    user: User | null;
	includeSorted: boolean;
	date: number[];
    unread: boolean;
    archive: boolean;
    emailStatus: EmailStatus;
    emailPriority: EmailPriority;
    pastDue: boolean;

}

export default class MailBoxFilters extends CommonFilters<ComponentsProps, FilterBaseState, MailBoxFiltersValues> {
	constructor(props: ComponentsProps) {
		super(props);
		
		const that = this;
		
		this.state = {
			filters: {
				includeSorted: {
					id: 1,
					caption: 'Include emails with case specified',
					defaultValue: false,
					value: false,
					getValue: function () {
						return <span>{this.value ? 'Yes' : 'No'}<RemoveButton click={() => that.removeFilter('includeSorted')}/></span>
					}
				},
				unread: {
					id: 2,
					caption: 'Unread',
					defaultValue: false,
					value: false,
					getValue: function () {
						return <span>{this.value ? 'Yes' : 'No'} <RemoveButton click={() => that.removeFilter('unread')}/></span>
					}
				},
				archive: {
					id: 3,
					caption: 'Archive',
					defaultValue: false,
					value: false,
					getValue: function () {
						return <span>{this.value ? 'Yes' : 'No'} <RemoveButton click={() => that.removeFilter('archive')}/></span>
					}
				},
				emailStatus: {
					id: 4,
					caption: 'Email Status',
                    defaultValue: -1,
                    value: -1,
                    check: function () {
                        return +this.value !== this.defaultValue;
                    },
                    getValue: function () {
                        return that.getFilterMarkup(this, EmailStatus[this.value], 'emailStatus');
                    }
				},
				emailPriority: {
					id: 5,
					caption: 'Email Priority',
                    defaultValue: -1,
                    value: -1,
                    check: function () {
                        return +this.value !== this.defaultValue;
                    },
                    getValue: function () {
                        return that.getFilterMarkup(this, EmailPriority[this.value], 'emailPriority');
                    }
				},
                vccCase: {
                    id: 6,
                    caption: 'VCC Case #',
                    defaultValue: this.props.injury ? this.props.injury.vccCase:'',
                    value: this.props.injury ? this.props.injury.vccCase : '',
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value}<RemoveButton
                            click={() => that.removeFilter('vccCase')} /></span>;
                    }
                },
				user: {
					id: 7,
					caption: 'User',
					defaultValue: null,
					value: null,
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value ? this.value.lastName + ' ' + this.value.firstName:''}<RemoveButton
							click={() => that.removeFilter('user')}/></span>;
					}
                },
                emailAccount: {
					id: 8,
					caption: 'EmailAccount',
					defaultValue: null,
					value:null,
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value ? this.value.accountName:''}<RemoveButton
                            click={() => that.removeFilter('emailAccount')}/></span>;
					}
				},
				date: {
					id: 9,
					caption: 'Date',
					defaultValue: [null, null],
					value: [null, null],
					getValue: function() {
						if (this.value[0] !== null && this.value[1] !== null) {
							return  <span className="filters-component__item-value">{dateFormat(this.value[0])} - {dateFormat(this.value[1])}<RemoveButton click={() => that.removeFilter('date')}/></span>
						} else if (this.value[0] !== null) {
							return <span className="filters-component__item-value">From {dateFormat(this.value[0])}<RemoveButton click={() => that.removeFilter('date')}/></span>
						} else {
							return <span className="filters-component__item-value">To {dateFormat(this.value[1])}<RemoveButton click={() => that.removeFilter('date')}/></span>
						}
					},
					check:function() {
						return this.value[0] !== this.defaultValue[0] || this.value[1] !== this.defaultValue[1];
					}
                },
                fromEmail: {
                    id: 10,
                    caption: 'From Email',
                    defaultValue: '',
                    value: '',
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value}<RemoveButton
                            click={() => that.removeFilter('fromEmail')} /></span>;
                    }
                },
                group: {
                    id:11 ,
                    caption: 'Group',
                    defaultValue: null,
                    value: null,
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value?this.value.name:''}<RemoveButton
                            click={() => that.removeFilter('group')} /></span>;
                    }
                }
                ,
				pastDue: {
                    id: 12,
                    caption: 'Past Due',
                    defaultValue: false,
                    value: false,
                    getValue: function () {
                        return <span>{this.value ? 'Yes' : 'No'} <RemoveButton click={() => that.removeFilter('pastDue')} /></span>
                    }
                },
			},
			visible: false,
		};

		this.resetFilters = this.resetFilters.bind(this);
	}
	
	getFiltersContent() {
		const {filters} = this.state;

		const getFormContainer = (triggerNode: Element) => document.getElementById('filters-component__form') || document.body;
		
		return <div className="filters-component__popup clearfix">
			<Formik
				initialValues={
					{
						includeSorted: filters.includeSorted.value,
						vccCase: filters.vccCase.value,
						date: filters.date.value,
                        unread: filters.unread.value,
                        group: filters.group.value,
                        fromEmail: filters.fromEmail.value,
                        emailStatus: filters.emailStatus.value,
                        emailPriority: filters.emailPriority.value,
                        user: filters.user.value,
                        emailAccount: filters.emailAccount.value,
                        archive: filters.archive.value,
                        pastDue: filters.pastDue.value
					}
				}
				onSubmit={this.applyFilters}
				enableReinitialize={true}
				validationSchema={validationSchema}
                render={(formikBag: FormikProps<MailBoxFiltersValues>) => (
					<Form id="filters-component__form" className="filters-component__form">
						<div className="row">
							<div className="col-sm-6">
                                {this.props.injury == null && <Field
                                    name="vccCase"
                                    render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                        <div className={` ${form.errors.vccCase && form.touched.vccCase ? 'has-error' : ''}`}>
                                            <label htmlFor="vccCase">Case #</label>
                                            <input className="form-control input-sm" type="text" id="vccCase" disabled={!formikBag.values.includeSorted}  {...field} />
                                        </div>
                                    )}
                                />}
								<div className="row">
                                    {this.props.injury == null && <div className="col-sm-3">
                                        <Field
                                            name="includeSorted"
                                            render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                                <div className={`form-group`}>
                                                    <div className="checkbox">
                                                        <label htmlFor="includeSorted"><input className="form-control input-sm" type="checkbox" id="includeSorted" checked={field.value} {...field} />Include sorted</label>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>}
									<div className="col-sm-3">
										<Field
											name="unread"
                                            render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
												<div className={`form-group`}>
													<div className="checkbox">
														<label htmlFor="unread"><input className="form-control input-sm" type="checkbox" id="unread" checked={field.value} {...field} />Unread</label>
													</div>
												</div>
											)}
										/>
                                    </div>
                                    <div className="col-sm-3">
                                        <Field
                                            name="pastDue"
                                            render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                                <div className={`form-group`}>
                                                    <div className="checkbox">
                                                        <label htmlFor="pastDue"><input className="form-control input-sm" type="checkbox" id="pastDue" checked={field.value} {...field} />Past Due</label>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Field
                                            name="archive"
                                            render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                                <div className={`form-group`}>
                                                    <div className="checkbox">
                                                        <label htmlFor="archive"><input className="form-control input-sm" type="checkbox" id="archive" checked={field.value} {...field} />Archive</label>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <Field
                                        name="group"
                                        render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label>Group</label>
                                                <Autocomplete type="groupList"
                                                    renderOption={(item) => <Option key={item.id} value={`${item.name}`} title={item.name} {...{ item: item }}>{item.name}</Option>}
                                                    onSelect={(value, option) => {
                                                        formikBag.setFieldValue('group', option.props.item);
                                                    }}
                                                    onChange={(value = '') => { if (value === '') { formikBag.setFieldValue('group', null); } }}
                                                    value={ ''}

                                                    paramName="name"
                                                />
                                            </div>
                                        )}
                                    />
                                    <Field
                                        name="emailAccount"
                                        render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label>Email Account</label>
                                                <Autocomplete type="emailAccountList"
                                                    renderOption={(item) => <Option key={item.id} value={`${item.accountName}`} title={item.accountName} {...{ item: item }}>{item.accountName}</Option>}
                                                    onSelect={(value, option) => {
                                                        formikBag.setFieldValue('emailAccount', option.props.item);
                                                    }}
                                                    onChange={(value = '') => { if (value === '') { formikBag.setFieldValue('emailAccount', null); } }}
                                                    value={''}

                                                    paramName="accountName"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="row">
                                    <Field
                                        name="user"
                                        render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label>User</label>
                                                <Autocomplete type="userList"
                                                    params={{ roles: [UserRole.Admin, UserRole.Internal] }}
                                                    renderOption={(item) => <Option key={item.id} value={`${item.lastName + ' ' + item.firstName}`} title={item.lastName + ' ' + item.firstName} {...{ item: item }}>{item.lastName + ' ' + item.firstName}</Option>}
                                                    onSelect={(value, option) => {
                                                        formikBag.setFieldValue('user', option.props.item);
                                                    }}
                                                    onChange={(value = '') => { if (value === '') { formikBag.setFieldValue('user', null); } }}
                                                    value={''}

                                                    paramName="text"
                                                />
                                            </div>
                                        )}
                                    />
                                    <div className="col-sm-6">
                                        <Field
                                            name="fromEmail"
                                            render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                                <div className={` ${form.errors.fromEmail && form.touched.fromEmail ? 'has-error' : ''}`}>
                                                    <label htmlFor="fromEmail">From</label>
                                                    <input className="form-control input-sm" type="text" id="fromEmail"   {...field} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                {((this.props.globalSettings && this.props.globalSettings.settings.allowInternalToCloseEmail == true) || (this.props.user && this.props.user.role == UserRole.Admin)) && <Field
                                    name="emailStatus"
                                    render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                        <div className={`form-group col-sm-12 col-md-6`}>
                                            <label htmlFor="gender">Email Status</label>
                                            <select className="form-control input-sm" id="emailStatus"  {...field}>
                                                <option value="-1">All</option>
                                                <option value="0">Open</option>
                                                <option value="1">Close</option>
                                            </select>
                                        </div>
                                    )}
                                />}
                                <Field
                                    name="emailPriority"
                                    render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                        <div className={`form-group col-sm-12 col-md-6`}>
                                            <label htmlFor="gender">Email Priority</label>
                                            <select className="form-control input-sm" id="emailPriority"  {...field}>
                                                <option value="-1">All</option>
                                                <option value="0">Low</option>
                                                <option value="1">High</option>
                                            </select>
                                        </div>
                                    )}
                                    />
                                    </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 filters-component__date">
								<label>Date</label>
                                <div className="row">
                                    <div className="col-md-12">
                                    <div className="col-sm-12 col-md-6 DateFrom">
										<Datepicker
											disabledDate={disabledTo(formikBag.values.date[1], true)}
											value={formikBag.values.date[0]}
											format="MM/DD/YY"
											onChange={date => formikBag.setFieldValue('date.0', date ? new Date(date).setHours(0,0,0,0) : date)}
											antdProps={{
												placeholder: "From",
												getCalendarContainer: getFormContainer
											}}
										/>
									</div>
                                    <div className="col-sm-12 col-md-6 DateTo">
										<Datepicker
											disabledDate={disabledFrom(formikBag.values.date[0], true)}
											value={formikBag.values.date[1]}
											format="MM/DD/YY"
											onChange={date => formikBag.setFieldValue('date.1', date ? new Date(date).setHours(24,0,0,-1) : date)}
											antdProps={{
												placeholder: "To",
												getCalendarContainer: getFormContainer
											}}
										/>
                                        </div>
                                        </div>
								</div>
							</div>
						</div>
						{this.filtersButtons()}
					</Form>
				)}
			/>
		</div>;
	}
}