import * as React from "react";
import { ExtendableItemsPage } from "@app/components/Pages/ItemsPage";
import * as moment from "moment";
import { connect } from "react-redux";
import { ApplicationState } from "@app/store";
import * as PagesState from '@app/store/ItemList';
import { Link } from "react-router-dom";
import { User, UserRole } from "@app/objects/User";
import { Fragment } from "react";
import MailBoxFilters, { MailBoxFiltersValues } from "@app/components/Pages/MailBox/MailBoxFilters";
import { TableRowSelection } from 'antd/lib/table';
import SimpleSearchInput from "@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput";
import Popover from 'antd/lib/popover';
import { request } from "@app/components/Api";
import { Employee } from '@app/objects/Employee';
import { deleteConfirmation } from "@app/components/Utils/Modal";

import * as ItemsState from '@common/react/store/ItemList';
import { EmailThread, EmailStatus, EmailPriority, TaskStatus } from "@app/objects/EmailThread";
import { InjuryEmailAction, InjuryEmail } from "@app/objects/InjuryEmail";
import Button from "@common/react/components/Forms/Button";
const { Panel } = Collapse;

import Table, { ColumnProps } from 'antd/lib/table';
import { Modal, message, Select, Collapse, Checkbox, InputNumber } from "antd";
import { fail } from "assert";
import { Formik, FormikProps, FieldProps, Field, Form } from "formik";
import { InjuryFile, Injury } from "@app/objects/Injury";
import * as Yup from 'yup';
import Wysiwyg, { defaultOptions } from "@common/react/components/Forms/Wysiwyg/Wysiwyg";

import File from "@common/react/components/Forms/Files/File";
import { List } from "@common/typescript/objects/List";
import { SortingDirection, HandleChangeEventElement } from "@common/react/components/Pages/ItemsPage";
import { FileInterface, FileType } from "@common/typescript/objects/FileInterface";
import Autocomplete, { Option } from "@common/react/components/Forms/Autocomplete/Autocomplete";
import { EmailAccount } from "@app/objects/EmailAccount";
import { CompanyContact } from "@app/objects/Company";
import { FormikList } from "@common/react/components/Forms/FormikList/FormikList";
import { ActionCreatorsMapObject, bindActionCreators } from "redux";
import * as LoginState from "@app/store/Login";

import * as ReportsState from "@app/store/Reports";

import { RouteComponentProps } from 'react-router-dom';
import { ItemState } from "@common/react/store/Item";
import { EmailFolder, EmailFolderType } from "@app/objects/EmailFolder";
import { GlobalSettingsState } from "@app/store/GlobalSettings";

import { BaseParams } from '@common/react/objects/BaseParams';
//import FroalaEditor from 'react-froala-wysiwyg';

const FroalaEditor = loadable(() => loadableDelay(import("react-froala-wysiwyg")), params)
loadableDelay(import("froala-editor/js/plugins.pkgd.min.js"))

import { FormikInput } from "@common/react/components/Forms/FormikInput/FormikInput";
import Datepicker, { disabledTo, disabledFrom } from "@common/react/components/Forms/Datepicker/Datepicker";
import EmailAccounts from "../Admin/EmailAccounts/EmailAccounts";
import { loadableDelay, params } from "@app/loadableSettings";
import { loadable } from "@common/react/loadable/loadableSettings";
import { SpamFilter } from "@app/objects/SpamFilter";
import SpamFilters from "../Admin/SpamFilters/SpamFilters";

type rowKey = (record) => string;

interface ReduxProps {

    user: User | null,
    globalSettings: GlobalSettingsState | null
}

interface Actions {
}

type ReportsProps = ReduxProps & Actions & {

    location: any|null,
    injury: Injury | null,
    showArchived: boolean,
}

interface ComponentState {
    openTasks: boolean;
    ids: number[];
    emails: InjuryEmail[] | null;
    editorVisible: boolean;
    createFolderVisible: boolean;
    selectedItem: InjuryEmail | null;
    creatingNewEmail: boolean;
    replayEmail: boolean;
    forwardEmail: boolean;
    isTask: boolean;
    isReplayTask: boolean;
    editEmail: boolean;
    replayAllEmail: boolean;
    isLoading: boolean;
    attachments: InjuryFile[];
    firstItem: boolean;
    lastItem: boolean;
    editorTitle: string;
    emailThread: EmailThread | null;
    emailFolder: EmailFolder | null;
    emailFolders: EmailFolder[];
    emailAccount: EmailAccount | null;
    items: ItemsState.ItemsState<EmailThread>;
}

const validationSchema = Yup.object().shape({
    to: Yup.array().of(Yup.object().shape({ email: Yup.string() })).required('Required field!'),
    cc: Yup.array().of(Yup.object().shape({ email: Yup.string() })),
    bcc: Yup.array().of(Yup.object().shape({ email: Yup.string() })),
    html: Yup.string().required(),
    emailAccountId: Yup.number().required().typeError('Required field!')
});

const validationSchema2 = Yup.object().shape({
    to: Yup.array().of(Yup.object().shape({ email: Yup.string() })),
    cc: Yup.array().of(Yup.object().shape({ email: Yup.string() })),
    bcc: Yup.array().of(Yup.object().shape({ email: Yup.string() })),
    html: Yup.string().required()
});

const createFolderValidationSchema = Yup.object().shape({
    name: Yup.string().required()
});

interface CreateFolderProps {
    name: string;
    type: EmailFolderType;
}
interface SendReplyMessage {
    id: number;
    injuryId: number | null | undefined;
    userId: number | null | undefined;
    to: any[];
    cc: any[];
    bcc: any[];
    subject: string;
    html: string;
    emailAccountId: number | null;
    draftId: number | null;
    draft: boolean;
    startDate: number | null;
    dueDate: number | null;
    taskStatus: TaskStatus;
    completePercent: number;
    emailStatus: EmailStatus;
    emailPriority: EmailPriority;
}

export const renderMobileCell = (caption: string, text: string | JSX.Element | null): JSX.Element => {
    return <React.Fragment>
        <div className="table-mobile__caption">{caption}</div>
        <div className="table-mobile__content">{text}</div>
    </React.Fragment>;
};

class MailBox extends React.Component<ReportsProps, ComponentState> {

    emailAccountSig: string = '';
    oldSig: string = '';
    oldHtml: string = '';
    type: string = 'page';
    store: string = 'pages';
    path: string = 'pageList';
    listPath: string = 'pages';
    editorPath: string = 'page-editor';
    editorCaption: string = 'Add page';
    caption: string = 'Pages';
    text: string = '';
    isFilterText: boolean = true;
    textInput: any;
    tableClassName: string = '';
    additionalParams: BaseParams = {};
    rowKey: string | rowKey = 'id';
    withoutUrlPagination: boolean = false;
    count: number = 10;



    idColumn = {
        title: '#',
        dataIndex: 'id',
        render: (text: string, record: any): JSX.Element | null => {
            return this.renderMobileCell('#', record.id > 0 ? <span style={{ whiteSpace: 'nowrap' }}>{record.id}</span> : null);
        }
    };

    columns: ColumnProps<EmailThread>[] = [
        this.idColumn
    ];

    form: any;

    tableRowClick(record: EmailThread) {
        request('getEmailThread', { ...this.additionalParams, id: record.id }).then((result) => {

            var a = result as EmailThread;
            this.setState({
                emails: a.emails.list,
                emailThread: a
            })

        }).catch(() => {

        });

    };

    constructor(props) {
        super(props);
        
        this.state = {
            openTasks: false,
            emailFolders: [],
            isReplayTask: false,
            emailThread: null,
            editorTitle: '',
            attachments: [],
            isLoading: true,
            selectedItem: null,
            creatingNewEmail: false,
            replayEmail: false,
            isTask: false,
            replayAllEmail: false,
            forwardEmail: false,
            editEmail: false,
            firstItem: true,
            lastItem: false,
            ids: [],
            emails: null,
            editorVisible: false,
            emailFolder: null,
            createFolderVisible: false,
            emailAccount: null,
            items: {
                isLoading: true,
                items: [],
                pagination: {
                    total: 0,
                    current: 0,
                    offset: 0
                },
                type: 'EmailThread',
                params: {

                }
            }
        };
        if (this.props && this.props.location) {
            const params = new URLSearchParams(this.props.location.search);
            this.state = {...this.state, openTasks: (params.get('task') == "true") }
        }
        const prev = (record, list) => list[list.indexOf(record) - 1];
        const next = (record, list) => list[list.indexOf(record) + 1];

        this.columns = [{
            title: '',
            key: 'read',
            dataIndex: 'read',
            render: (text, record: EmailThread) => this.renderMobileCell('Read', <div>{record.read ? <i className="fa fa-check email-table-read" title="read" /> : <i className="fa fa-close text-danger" title="unread" />} {record.emailPriority == EmailPriority.High ? <i className="fa fa-exclamation-circle text-danger" title="high" /> : <i className="fa fa-arrow-circle-down email-table-read" title="low" />} {record.emailStatus == EmailStatus.Closed ? <i className="fa fa-lock text-danger" title="closed" /> : <i className="fa fa-unlock email-table-read" title="open" />} {record.user ? <i className="fa fa-user email-table-read" title={record.user.lastName + ' ' + record.user.firstName} /> : ''}  {record.injuryFilesCount > 0 ? <span className="email-table-read">(<i className="fa fa-paperclip " title="attachments" />{record.injuryFilesCount})</span> : ''}  {record.isTask ? <span className={record.completePercent >= 100 ? 'email-table-read' : 'text-danger'}>(<i className="fa fa-spinner" title="progress" />{record.completePercent}%)</span> : ''}</div>),
            sorter: false,

        }, {
            title: '',
            key: 'sender',
            dataIndex: 'sender',
            render: (text, record: EmailThread) => this.renderMobileCell('Sender', <div className="white-space-normal">{record.emails && record.emails.count > 0 && record.emails.list[0] ? (record.emails.list[0].userId != null ? record.emails.list[0].to : record.emails.list[0].from) : ''}</div>),
            sorter: false
            },
            {
                title: '',
                key: 'dueDate',
                dataIndex: 'dueDate',
                render: (text, record: EmailThread) => this.renderMobileCell('DueDate', <div className={"white-space-normal " + (record.dueDate && record.dueDate> new Date().getTime() ?"email-table-read":"text-danger")}>{record.isTask && record.dueDate ? moment(record.dueDate).format('MM/DD/YYYY') : ''}</div>),
                sorter: false
            },{
            title: '',
            key: 'subject',
            dataIndex: 'subject',
            render: (text, record: EmailThread) => this.renderMobileCell('Subject', <div className="white-space-normal" >
                {record.emails && record.emails.count > 0 && record.emails.list[0].subject ? (record.emails.list[0].subject.length > 60 ? <Popover content={record.emails.list[0].subject} title={record.emails.list[0].subject} placement="top">{record.emails.list[0].subject.substr(0, 60)}...</Popover> : record.emails.list[0].subject) : ''}</div>),
            sorter: false
        }, {
            title: '',
            key: 'date',
            dataIndex: 'date',
            render: (text, record: EmailThread) => this.renderMobileCell('Date', record.emails && record.emails.count > 0 && record.emails.list[0] ? moment(record.emails.list[0].date).format('MM/DD/YYYY h:mm a') : ''),
            sorter: false,

        }];

        this.type = 'emailThread';
        this.store = 'emailThreads';
        this.path = 'emailThreadList';
        this.listPath = 'mailbox';
        this.editorPath = 'mailbox-editor';
        this.caption = 'MailBox';
        this.tableClassName = 'table-mobile';
        this.editorCaption = '';

        this.additionalParams = {
            column: [{ caption: "date", direction: 2 }],
            archive: this.props.showArchived ? null : false,
            //injuryId: this.props.injury ? this.props.injury.id : null,
            vccCase: this.props.injury ? this.props.injury.vccCase : '',
            from: null,
            to: null,
            unread: false,
            emailStatus: -1,
            emailPriority: -1,
            groupId: null,
            emailAccountId: null,
            userId: null,
            fromEmail: '',
            text: '',
            emailFolder: -1,
            emailThreadFilter: true
        };


        this.loadEmailFolders();
        this.loadEmailAccount();

        this.reload = this.reload.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.getFiltersRender = this.getFiltersRender.bind(this);
        this.handleActionButton = this.handleActionButton.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addAttachment = this.addAttachment.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
        this.addEmail = this.addEmail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCreateFolderSubmit = this.handleCreateFolderSubmit.bind(this);
        this.handleActionButton = this.handleActionButton.bind(this);
        this.ComposeEmail = this.ComposeEmail.bind(this);
        this.AddTask = this.AddTask.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.closeCreateFolderDialog = this.closeCreateFolderDialog.bind(this);
        this.onReply = this.onReply.bind(this);
        this.applynewsig = this.applynewsig.bind(this);
        this.onReplyTask = this.onReplyTask.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onReplyAll = this.onReplyAll.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.deleteEmailFolder = this.deleteEmailFolder.bind(this);
        this.createEmailFolder = this.createEmailFolder.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.addContact = this.addContact.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.requestItems = this.requestItems.bind(this);
    }

    handleChange(evt: HandleChangeEventElement) {
        this.additionalParams.text = evt.currentTarget.value;
    }

    loadEmailFolders() {
        this.setState({
            items: {
                ...this.state.items,
                isLoading: true
            },
            isLoading: true
        });
        request('emailFolderList', {
            ...this.additionalParams
        }).then((result: any) => {
            //console.log(result);
            this.setState({
                isLoading: false,
                emailFolders: result.list,
                ids: []
            });
            if (this.state.openTasks) {
                //this.clickFolderAndRefresh(this.state.emailFolders.filter(f => f.name && f.name.toLowerCase() == "tasks")[0]);
                var item = this.state.emailFolders.filter(f => f.name && f.name.toLowerCase() == "tasks")[0];
                this.state.emailFolders.map(v => v.active = false);
                item.active = true;
                this.setState({ emailFolder: this.state.emailFolders.filter(f => f.id == item.id)[0] });
                this.additionalParams.emailFolder = item.id;
                this.additionalParams.sent = item.name.toLowerCase() == "sent";
                this.additionalParams.draft = item.name.toLowerCase() == "draft";

            }

            this.reload();
        }).catch(() => {
            this.setState({
                isLoading: false
            });
        });
    }

    loadEmailAccount() {
        if (!this.props.user) return;
        this.setState({
            isLoading: true
        });
        request('getUser', { id: this.props.user ? this.props.user.id : -1 }).then((result: any) => {
            this.setState({
                isLoading: false,
                emailAccount: result.defaultEmailAccount,

            });
            this.emailAccountSig = result.defaultEmailAccount && result.defaultEmailAccount.signature ? result.defaultEmailAccount.signature : '';
            this.getSig(this.emailAccountSig);
        }).catch((e) => {
            console.log(e);
            this.setState({
                isLoading: false
            });
        });
    }

    deleteEmailFolder() {
        deleteConfirmation(() => {
            if (this.state.emailFolder) {
                this.setState({
                    isLoading: true
                });
                const item = this.state.emailFolder;
                item.deleted = true;
                request('emailFolder', item).then((result) => {
                    this.additionalParams.emailFolder = -1;
                    this.loadEmailFolders();
                    this.componentWillMount();

                }).catch((e) => {
                    console.log(e);
                    this.loadEmailFolders();
                });
            }
        });
    }
    handleCreateFolderSubmit(emailFolder: CreateFolderProps) {
        this.setState({
            isLoading: true
        });
        const item = emailFolder;
        request('emailFolder', item).then((result) => {
            this.setState({
                createFolderVisible: false
            })
            this.loadEmailFolders();

        }).catch((e) => {
            console.log(e);
        });
    }

    createEmailFolder() {
        this.setState({
            createFolderVisible: true
        })
    }

    requestItems(path: string, params: any) {
        this.setState({
            items: {
                ...this.state.items,
                isLoading: true
            },
            isLoading: true
        });
        params.offset = params.offset == 0 ? 0 : this.state.items.pagination.offset;

        return request<List<EmailThread>>(path, params).then((result: List<EmailThread>) => {
            this.setState({
                items: {
                    isLoading: false,
                    items: result.list,
                    pagination: {
                        total: result.count,
                        current: result.offset / 10 + 1,
                        offset: result.offset
                    },
                    type: 'InjuryEmail',
                    params: {}
                },
                isLoading: false
            })
        }).catch((e) => {
            console.log(e);
            this.setState({
                isLoading: false
            })
        });
    }

    reload() {
        this.requestItems(this.path, {
            page: 1,
            offset: 0,
            text: this.text,
            loadNewMailFromPostBox: true,
            ...this.additionalParams
        });
    }

    handleChangeAndRefresh(evt: HandleChangeEventElement) {
        this.setState({ emailFolder: this.state.emailFolders.filter(f => f.id == parseInt(evt.currentTarget.value))[0] });
        this.additionalParams.emailFolder = +evt.currentTarget.value;
        //this.handleChange(evt);
        this.handleFilter(null);
    }

    clickFolderAndRefresh = item => ev => {

        this.state.emailFolders.map(v => v.active = false);
        item.active = true;
        this.setState({ emailFolder: this.state.emailFolders.filter(f => f.id == item.id)[0] });
        this.additionalParams.emailFolder = item.id;
        this.additionalParams.sent = item.name.toLowerCase() == "sent";
        this.additionalParams.draft = item.name.toLowerCase() == "draft";
        //this.handleChange(evt);
        this.requestItems(this.path, { page: 1, offset: 0, ...this.additionalParams });
        this.onClose(null);
    }

    getFiltersRender() {
        const ids = this.state.ids;
        const emailFolders = this.state.emailFolders;
        return <div className="list-filters">
            <div className="clearfix">

                <MailBoxFilters user={this.props.user} globalSettings={this.props.globalSettings} onApply={this.onApplyFilters} injury={this.props.injury} />
            </div>
        </div>
    }

    renderMobileCell(caption: string, text: string | JSX.Element | null): JSX.Element {
        return renderMobileCell(caption, text);
    }

    componentWillMount() {
        // This method runs when the component is first added to the page

        //this.props.countersActions.reqPages('emailFolders', 'emailFolderList', 'emailFolder', { page: 1, text: '', count: 100 });
    }

    onApplyFilters(values: MailBoxFiltersValues) {
        this.additionalParams = {
            ...this.additionalParams,
            injuryId: values.includeSorted ? 0 : null,
            vccCase: values.vccCase,
            from: values.date[0],
            to: values.date[1],
            unread: values.unread,
            archive: values.archive,
            emailStatus: values.emailStatus,
            emailPriority: values.emailPriority,
            groupId: values.group ? values.group.id : null,
            emailAccountId: values.emailAccount ? values.emailAccount.id : null,
            userId: values.user ? values.user.id : null,
            fromEmail: values.fromEmail,
            pastDue: values.pastDue
        };
        this.loadEmailFolders();
        //this.requestItems( this.path, { page: 1, ...this.additionalParams });
    }


    closeDialog(form: any) {

        if (this.state.isTask || this.state.isReplayTask) {
            this.setState({
                editorVisible: false,
            });
            return;
        }
        let formikBag: FormikProps<SendReplyMessage> = form.getFormikBag();
        if ((formikBag.values.html == "" || formikBag.values.html == this.oldSig || formikBag.values.html == this.oldHtml + this.oldSig)) {
            this.setState({
                editorVisible: false,
            });
            return;
        }
        formikBag.values.draft = true;
        this.handleSubmit(formikBag.values, { resetForm: formikBag.resetForm });

    }

    closeCreateFolderDialog() {
        this.setState({
            createFolderVisible: false
        })
    }

    handleSubmit(values, { resetForm }) {

        this.emailAccountSig = this.state.emailAccount && this.state.emailAccount.signature ? this.state.emailAccount.signature : '';
        this.getSig(this.emailAccountSig);

        this.setState({
            isLoading: true
        });
        request("sendReply", {
            ...values,
            injuryId: values.injuryId ? values.injuryId : -1,
            to: values.to.filter(q => !q.deleted).map(item => `${item.email}`).join(','),
            cc: values.cc.filter(q => !q.deleted).map(item => `${item.email}`).join(','),
            bcc: values.bcc.filter(q => !q.deleted).map(item => `${item.email}`).join(','),
            id: this.state.selectedItem ? this.state.selectedItem.id : 0,
            attachments: this.state.attachments,
            draft: values.draft,
            draftId: values.draftId ? values.draftId : -1,
            userId: values.userId,
            isTask: this.state.isTask || this.state.isReplayTask,
        }).then((result: any) => {
            this.setState({
                isLoading: false
            });
            if (this.state.emailThread)
                this.tableRowClick(this.state.emailThread)
            message.success(result != null ? result.message : '');

            this.setState({
                selectedItem: null,
                creatingNewEmail: false,
                editorVisible: false
            });

            resetForm();
            this.setState({
                attachments: []
            });

            this.reload();
        }).catch((e) => {
            message.error(e);
            this.setState({
                isLoading: false
            });
            this.setState({
                selectedItem: null,
                creatingNewEmail: false,
                editorVisible: false
            });
            resetForm();
            this.setState({
                attachments: []
            });
        });
    }


    addEmail() {
        this.setState({ creatingNewEmail: true })
    }


    addAttachment(result: FileInterface) {
        this.setState((state) => ({
            attachments: [...state.attachments, {
                id: -1,
                objectId: -1,
                description: '',
                file: result
            }]
        }));
    }

    deleteAttachment(index: number) {
        this.setState((state) => ({
            attachments: [
                ...state.attachments.slice(0, index),
                ...state.attachments.slice(index + 1)
            ]
        }));
    }

    handleActionButton(type) {
        if (type == InjuryEmailAction.Delete) {

            deleteConfirmation(() => {
                request('emailThreadAction', { action: type, ids: this.state.ids }).then(result => {
                    message.success('Deleted');
                    this.loadEmailFolders();
                }).catch((e) => {
                    console.log(e);
                });
            });
        } else {
            request('emailThreadAction', { action: type, ids: this.state.ids }).then(result => {
                let msg = "";
                switch (type) {
                    case InjuryEmailAction.Archive:
                        msg = "Archived";
                        break;
                    case InjuryEmailAction.Close:
                        msg = "Closed";
                        break; case InjuryEmailAction.HighPriority:
                        msg = "Priority set to high";
                        break; case InjuryEmailAction.Inbox:
                        msg = "Moved to inbox";
                        break; case InjuryEmailAction.Junk:
                        msg = "Moved to junk";
                        break; case InjuryEmailAction.LowPriority:
                        msg = "Priority set to low";
                        break; case InjuryEmailAction.Open:
                        msg = "Opened";
                        break; case InjuryEmailAction.Read:
                        msg = "Email set as read";
                        break;
                    case InjuryEmailAction.Unarchive:
                        msg = "Unarchived";
                        break;
                    case InjuryEmailAction.Unread:
                        msg = "Email set as unread";
                        break;
                }
                message.success(msg);

                this.loadEmailFolders();
            });
        }
    }

    onReply(e) {
        this.setState({
            selectedItem: e,
            replayEmail: true,
            forwardEmail: false,
            isReplayTask: false,
            isTask: false,
            editEmail: false,
            replayAllEmail: false,
            editorVisible: true,
            editorTitle: "Reply",
            creatingNewEmail: true
        });
    }


    onReplyTask(e) {
        this.setState({
            selectedItem: e,
            replayEmail: false,
            forwardEmail: false,
            isReplayTask: true,
            isTask: false,
            editEmail: false,
            replayAllEmail: false,
            editorVisible: true,
            editorTitle: "Reply",
            creatingNewEmail: true
        });
    }

    onReplyAll(e) {
        this.setState({
            selectedItem: e,
            replayEmail: false,
            forwardEmail: false,
            isReplayTask: false,
            isTask: false,
            editEmail: false,
            replayAllEmail: true,
            editorVisible: true,
            editorTitle: "Reply",
            creatingNewEmail: true
        });
    }

    onForward(e) {
        this.setState({
            selectedItem: e,
            forwardEmail: true,
            isReplayTask: false,
            replayEmail: false,
            editEmail: false,
            isTask: false,
            replayAllEmail: false,
            editorVisible: true,
            editorTitle: "Forward",
            creatingNewEmail: true,
            attachments: e.injuryFiles
        });
    }

    onEdit(e) {
        this.setState({
            selectedItem: e,
            editEmail: true,
            forwardEmail: false,
            isReplayTask: false,
            isTask: false,
            replayEmail: false,
            replayAllEmail: false,
            editorVisible: true,
            editorTitle: "Send",
            creatingNewEmail: true
        });
    }

    onDelete(e) {
        deleteConfirmation(() => {
            request('injuryEmailAction', { action: InjuryEmailAction.Delete, ids: [e.id] }).then(result => {
                this.reload();
                e.deleted = true;

            }).catch((e) => {
                console.log(e);
            });;
        });
    }

    onPrint(e,et) {
        var isTask = et.isTask;
        var email = e;
        var emailThread = et;
        var header = `<h5>${email.subject} ${!isTask ? `<span className="mailbox-read-time" style="float:right">Time:${email.date ? moment(email.date).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span>`:''}</h5>
                            ${!isTask ? `<h6>To: ${email.to} <span className="mailbox-read-time" style="float:right">Delivery Time:${email.deliveredTime ? moment(email.deliveredTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>` : ''}
                            ${!isTask ? `<h6>From: ${email.from} <span className="mailbox-read-time" style="float:right">Send Time:${email.sendTime ? moment(email.sendTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>` : ''}
                            ${!isTask ? `<h6>CC: ${email.cc} <span className="mailbox-read-time" style="float:right">Reply Time:${email.replyTime ? moment(email.replyTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>` : ''}
                            ${!isTask ? `<h6>BCC: ${email.bcc} <span className="mailbox-read-time" style="float:right">Create Time:${email.createTime ? moment(email.createTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>` : ''}
                            ${isTask ? `<h6>&nbsp;<span className="mailbox-read-time" style="float:right">Create Time:${email.createTime ? moment(email.createTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>` : ''}
                            ${isTask ? `<h6>&nbsp;<span className="mailbox-read-time " style="float:right">Start Date:${emailThread && emailThread.startDate ? moment(emailThread.startDate).format(`dddd, MM/DD/YY`) : ''}</span></h6>` : ''}
                            ${isTask ? `<h6>&nbsp;<span className="mailbox-read-time " style="float:right">Due Date:${emailThread && emailThread.dueDate ? moment(emailThread.dueDate).format(`dddd, MM/DD/YY`) : ''}</span></h6>`:''}
                            ${!isTask ? `<h6>${email.viewed ? 'Viewed' : ''}</h6>`:''}
                            ${!isTask ? `<h6>${email.deliveredTime || email.draft ? '' : 'Sending'}</h6>`:''}
                            <h6>${email.draft ? 'Draft' : ''}</h6>
                            ${ email.user ? `<h6>${"By: " + email.user.lastName + ' ' + email.user.firstName}</h6>` : ''}`
                            
        var newWin = window.frames["printf"];
        newWin.document.write('<body onload="window.print()">' + header + e.html + '</body>');
        newWin.document.close();
    }

    onClose(e) {
        this.setState(
            {
                emails: [],
                emailThread: null
            });
    }
    onRefresh(e) {
        //this.reload();
        this.loadEmailFolders();
    }

    ComposeEmail() {

        this.setState({
            editorVisible: true,
            replayEmail: false,
            editEmail: false,
            forwardEmail: false,
            isReplayTask: false,
            isTask: false,
            editorTitle: "Send",
            creatingNewEmail: true,
            selectedItem: null
        })
    }

    addContact(emaill: string, e: any) {
        const s = e.target;
        request("companyContact", {
            email: emaill
        }).then((result: any) => {
            s.style.display = "none"
            }).catch((e) => {
                console.log(e);
            });
    }

    onSelectAll(a) {
        if (!a.target.checked)
            this.setState({
                ids: []
            })
        else
            this.setState({
                ids: this.state.items.items.map(a => a.id)
            })
    }


    handleFilter(event: React.FormEvent<HTMLFormElement> | null) {
        event && event.preventDefault();
        this.loadEmailFolders();
        //this.requestItems(this.path, { page: 1, ...this.additionalParams });
    }

    handleTableChange(pagination, filters, sorter) {
        if (this.state.items.pagination.current !== pagination.current) {
            this.state.items.pagination.offset = (pagination.current - 1) * 10;
        } else {
            if (sorter.column) {
                this.additionalParams.column = [{
                    caption: sorter.field,
                    direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending
                }]
            } else {
                this.additionalParams.column = [];
            }
        }
        this.requestItems(this.path, { ...this.additionalParams });

    }

    AddTask() {
        this.setState({
            editorVisible: true,
            replayEmail: false,
            editEmail: false,
            forwardEmail: false,
            isReplayTask: false,
            isTask: true,
            editorTitle: "Add",
            creatingNewEmail: true,
            selectedItem: null
        })
    }

    applynewsig(formikBag, option) {
        //console.log(formikBag.values.html, "-----------", this.oldSig, formikBag.values.html.indexOf(this.oldSig))
        if (formikBag.values.html.indexOf(this.oldSig) >= 0) {

            let s = formikBag.values.html.replace(this.oldSig, '');

            s += this.getSig(option.props.item.signature);
            formikBag.setFieldValue('html', s);
        }
    }

    getSig(s: string, render: boolean=false) {
        let ret = "<br><br><br>";
        ret += (!this.state.isTask && !this.state.isReplayTask && this.props.user && this.props.user.emailSignature ? this.props.user.emailSignature : '');
        ret += (!this.state.isTask && !this.state.isReplayTask && s ? s : '');
        ret += (!this.state.isTask && !this.state.isReplayTask && this.props.globalSettings && this.props.globalSettings.settings && this.props.globalSettings.settings.signature ? this.props.globalSettings.settings.signature : '');
        ret = ret.replace(/<\/undefined>/g, '').replace(/<undefined>/g, '').replace(/^\s+|\r\n|\n|\r|(>)\s+(<)|\s+$/gm, '$1$2');
        if (!render) this.oldSig = ret;
        return ret;
    }

    getHtml(selectedItem: InjuryEmail) {
        let ret = "<br><br>";
        ret = !this.state.isTask && !this.state.isReplayTask && selectedItem ? (this.state.editEmail ? '' : '<br/><br/><br/><br/><div class="gmail_quote">on ' + moment(selectedItem.date).format('MM/DD/YYYY h:mm a') + ', ' + selectedItem.from.substring(selectedItem.from.lastIndexOf('<') + 1, selectedItem.from.lastIndexOf('>')) + ' wrote:<br/>' + selectedItem.subject + "</div>") + ( !selectedItem.html ? '' : selectedItem.html) : '';
        this.oldHtml = ret;
        return ret;
    }


    render() {

        const { items, pagination, isLoading } = this.state.items;
        const { creatingNewEmail, selectedItem, isLoading: isLoadingReply, firstItem, lastItem, ids, emailFolders } = this.state;
       
        const tableRowSelection: TableRowSelection<EmailThread> = {
            selectedRowKeys: ids,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    ids: selectedRows.map(row => (row as EmailThread).id),
                });
            }
        };

        if (emailFolders.filter(v => v.id == -1).length == 0) {
            emailFolders.push({ name: "All Emails", id: -1, active: true, newCount: 0, type: EmailFolderType.Public, emails: [], deleted: false, emailThreads: [], user: null, userId: -1 });
            if (this.state.emailFolder != null) {
                var res = emailFolders.filter(q => this.state.emailFolder != null && q.id == this.state.emailFolder.id);
                if (res.length > 0) {
                    emailFolders.map(q => q.active = false);
                    res[0].active = true;
                    this.setState({
                        emailFolder: res[0]
                    });
                }
            } else {
                var res = emailFolders.filter(q => q.name.toLowerCase() == 'inbox');
                if (res.length > 0) {
                    emailFolders.map(q => q.active = false);
                    res[0].active = true;
                    this.setState({
                        emailFolder: res[0]
                    });
                    this.additionalParams.emailFolder = res[0].id;
                    //this.handleChange(evt);
                    //this.handleFilter(null);
                    //this.requestItems(this.path, { page: 1, ...this.additionalParams });
                    this.onClose(null);
                }
            }
        }
       
        //console.log(this.props.injury);
        var to = selectedItem != null && selectedItem.to ? selectedItem.to.replace(/"[^"]*"/g,"").split(",").map((v) => { return { email: v.indexOf('<') > 0 ? v.substring(v.lastIndexOf('<') + 1, v.lastIndexOf('>')) : v } }) : [];
        var from = selectedItem != null && selectedItem.from != null ? selectedItem.from.replace(/"[^"]*"/g, "").split(",").map((v) => { return { email: v.indexOf('<') > 0 ? v.substring(v.lastIndexOf('<') + 1, v.lastIndexOf('>')) : v } }) : [];
        var cc = selectedItem != null && selectedItem.cc ? selectedItem.cc.replace(/"[^"]*"/g, "").split(",").map((v) => { return { email: v.indexOf('<') > 0 ? v.substring(v.lastIndexOf('<') + 1, v.lastIndexOf('>')) : v } }) : [];
        var bcc = selectedItem != null && selectedItem.bcc ? selectedItem.bcc.replace(/"[^"]*"/g, "").split(",").map((v) => { return { email: v.indexOf('<') > 0 ? v.substring(v.lastIndexOf('<') + 1, v.lastIndexOf('>')) : v } }) : [];

        if (this.props.injury && this.props.injury.user) {
            to.push({ email: this.props.injury.user.email })
        }


        return <Fragment>
            <iframe id="printf" name="printf" className="hide"></iframe>

            <div className="site-headline site-headline_with-button clearfix">
                <h1 className="pull-left">{this.caption}</h1>

                {this.isFilterText && this.getFiltersRender()}

            </div>
            <div className="col-md-3 lte">
                <Button isLoading={isLoading} className="btn btn-primary btn-block mb-3" onClick={this.state.emailThread ? this.onClose : (this.state.emailFolder != null && this.state.emailFolder.name.toLowerCase() == "tasks" ? this.AddTask : this.ComposeEmail)}>{this.state.emailThread ? "Back To List" : (this.state.emailFolder != null && this.state.emailFolder.name.toLowerCase() == "tasks" ? "Add Task" : "Compose")}</Button>

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Folders</h3>

                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fa fa-plus" onClick={e => this.createEmailFolder()} ></i>
                            </button><button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fa fa-minus" onClick={e => this.deleteEmailFolder()}></i>
                            </button>
                        </div>
                    </div>
                    <div className="card-body p-0" >
                        <ul className="nav nav-pills flex-column">


                            {emailFolders.map((value: EmailFolder, index: number) => {
                                return <li className="nav-item" onClick={this.clickFolderAndRefresh(value)}>
                                    <a className={"nav-link " + (value.active ? 'active' : '')} >
                                        <i className={"fa " + (value.name.toLowerCase() == 'inbox' ? 'fa-inbox' : (value.name.toLowerCase() == 'sent' ? 'fa-envelope' : (value.name.toLowerCase() == 'junk' ? 'fa-filter' : (value.name.toLowerCase() == 'draft' ? 'fa-file' : (value.name.toLowerCase() == 'trash' ? 'fa-trash' : (value.name.toLowerCase() == 'tasks' ? 'fa-tasks' : 'fa-folder'))))))}></i> {value.name}
                                        {this.props.injury == null && <span className={"badge  float-right " + (value.name.toLowerCase() == 'junk' ? 'bg-warning ' : 'bg-primary ') + (value.newCount <= 0 ? 'hide' : '')}>{value.newCount}</span>}
                                    </a>
                                </li>
                            })}

                        </ul>
                    </div>
                </div>
            </div>
            <div className={"form-group lte " + (this.state.emailThread ? 'hide' : 'col-sm-9')}>
                <div className="card card-primary card-outline">
                    <div className="card-header">
                        <h3 className="card-title">{this.state.emailFolder ? this.state.emailFolder.name : ''}</h3>

                        <div className="card-tools">

                            <form onSubmit={this.handleFilter} className="input-group input-group-sm">
                                <input type="text" className="form-control" placeholder="Search Mail" onChange={this.handleChange} />
                                <div className="input-group-append">
                                    <div className="btn btn-primary" onClick={e => this.handleFilter(null)}>
                                        <i className="fa fa-search" ></i>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                    <div className="mailbox-controls">
                        <button type="button" className="btn btn-default btn-sm checkbox-toggle" style={{ padding: '0px 5px 0px 5px' }}><Checkbox onChange={this.onSelectAll} ></Checkbox>
                        </button>
                        <div className="btn-group">
                            {ids.length > 0 && <div className="">
                                <button type="button" className="btn btn-default  btn-sm" onClick={e => this.handleActionButton(0)} title="Read"><i className="fa fa-check email-table-read" ></i></button>
                                <button type="button" className="btn btn-default  btn-sm" onClick={e => this.handleActionButton(1)} title="Unread"><i className="fa fa-close text-danger"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(2)} title="Delete"><i className="fa fa-trash"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(4)} title="Low Priority"><i className="fa fa-arrow-circle-down email-table-read"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(3)} title="High Priority"><i className="fa fa-exclamation-circle text-danger"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(7)} title="Open"><i className="fa fa-unlock email-table-read  "></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(8)} title="Close"><i className="fa fa-lock text-danger "></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(9)} title="Move To Inbox"><i className="fa fa-inbox"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(6)} title="Move To Junk"><i className="fa fa-filter"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(5)} title="Move To Archive"><i className="fa fa-archive"></i></button>
                                <button type="button" className="btn btn-default btn-sm" onClick={e => this.handleActionButton(10)} title="Unarchive"><i className="fa fa-arrow-up"></i></button>
                            </div>
                            }
                        </div>
                        <button type="button" className="btn btn-default btn-sm" onClick={this.reload}><i className="fa fa-refresh"></i></button>

                    </div>
                    <Table
                        columns={this.columns}
                        showHeader={false}
                        dataSource={items}
                        pagination={pagination}
                        loading={isLoading}
                        onChange={this.handleTableChange}
                        childrenColumnName="child"
                        rowKey="id"
                        className={this.tableClassName}
                        rowSelection={tableRowSelection}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => this.tableRowClick(record), // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                        rowClassName={(record: EmailThread, index) => (!record.read ? "font-weight-bold" : "")}
                    />
                </div>

            </div>
            <div className="form-group lte col-sm-9 ">
                <EmailThreadTable
                    emailThread={this.state.emailThread}
                    onDelete={this.onDelete}
                    onReplyTask={this.onReplyTask}
                    onPrint={this.onPrint}
                    onClose={this.onClose}
                    onRefresh={this.onRefresh}
                    onForward={this.onForward}
                    onEdit={this.onEdit}
                    onReplyAll={this.onReplyAll}
                    onReply={this.onReply}
                    emails={this.state.emails} isNotExternal={true} globalSettings={this.props.globalSettings} user={this.props.user} ></EmailThreadTable>
            </div>

            <Modal
                title={'Create Folder'}
                visible={this.state.createFolderVisible}
                onCancel={this.closeCreateFolderDialog}
                width={600}
                footer={null}
                destroyOnClose={true}>
                <form>
                    <Formik
                        ref={node => (this.form = node)}
                        initialValues={{
                            name: '',
                            type: EmailFolderType.Public
                        }}
                        onSubmit={this.handleCreateFolderSubmit}
                        validationSchema={createFolderValidationSchema}
                        enableReinitialize={true}
                        render={(formikBag: FormikProps<CreateFolderProps>) => <React.Fragment>



                            <div className="row">
                                <Field
                                    name="name"
                                    render={({ field, form }: FieldProps<CreateFolderProps>) => (
                                        <div className="form-group col-sm-12 ${form.errors.name && form.touched.name ? 'has-error' : ''}">
                                            <label htmlFor={'name'}>Name</label>
                                            <input className="form-control" id="name" {...field} />
                                            {form.touched.name && form.errors.name ? <div
                                                className="validation-message">{form.errors.name}</div> : ''}
                                        </div>
                                    )}
                                />
                            </div>
                            {(this.props.user && this.props.user.role == UserRole.Admin) && <div className="row">
                                <Field
                                    name="type"
                                    render={({ field, form }: FieldProps<MailBoxFiltersValues>) => (
                                        <div className={`form-group col-md-6 col-sm-12col-xs-12`}>
                                            <label htmlFor="type">Type</label>
                                            <select className="form-control input-sm" id="type"  {...field}>
                                                <option value="0">public</option>
                                                <option value="1">private</option>
                                            </select>
                                        </div>
                                    )}
                                />

                            </div>}
                            <div className="text-center space-between">
                                <Button isLoading={this.state.isLoading} className="btn btn-primary" type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit() }}>{'Create Folder'}</Button>
                            </div>
                        </React.Fragment>}
                    /></form>
            </Modal>

            <Modal
                title={this.editorCaption}
                visible={this.state.editorVisible}
                onCancel={() => this.closeDialog(this.form)}
                width={'80%'}

                style={{ top: 20 }}
                footer={null}
                destroyOnClose={true}>
                <form style={{ paddingRight: 20 }}>
                    <Formik
                        ref={node => (this.form = node)}
                        initialValues={{
                            id: selectedItem && (this.state.replayEmail || this.state.replayAllEmail || this.state.isReplayTask) ? selectedItem.id : 0,
                            injuryId: selectedItem ? selectedItem.injuryId : (this.props.injury ? this.props.injury.id : null),
                            to: !this.state.isTask && selectedItem && !this.state.forwardEmail ? (this.state.replayEmail || this.state.replayAllEmail ? from : to) : [],
                            cc: !this.state.isTask && selectedItem && (this.state.replayAllEmail || this.state.editEmail) && selectedItem.cc ? cc : [],
                            bcc: !this.state.isTask && selectedItem && (this.state.replayAllEmail || this.state.editEmail) && selectedItem.bcc ? bcc : [],
                            subject: !this.state.isTask && selectedItem ? (this.state.replayEmail && selectedItem.subject.toLowerCase().indexOf("re:") < 0 ? 'Re:' : '') + (this.state.forwardEmail && selectedItem.subject.toLowerCase().indexOf("fw:") < 0 ? 'Fw:' : '') + selectedItem.subject : '',
                            html: (selectedItem ? this.getHtml(selectedItem) : '') + this.getSig(this.emailAccountSig,true),
                            emailAccountId: selectedItem && selectedItem.emailAccountId ? selectedItem.emailAccountId : (this.state.emailAccount ? this.state.emailAccount.id : null),
                            draft: false,
                            draftId: selectedItem && this.state.editEmail ? selectedItem.id : 0,
                            startDate: null,
                            dueDate: null,
                            completePercent: 0,
                            taskStatus: TaskStatus.NotStarted,
                            userId: null,
                            emailStatus: 0,
                            emailPriority: 0
                        }}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchema2}
                        enableReinitialize={true}
                        render={(formikBag: FormikProps<SendReplyMessage>) => <React.Fragment>

                            {creatingNewEmail && <React.Fragment>

                                <div className="row ">
                                    {!this.state.isReplayTask && <Field
                                        name="vcc"
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label className={!this.state.isTask ? "col-sm-3  col-form-label" : ""}>VCC#</label>
                                                <div className={!this.state.isTask ? "col-sm-9 is-relative" : ""}>
                                                    <Autocomplete
                                                        type="injuryList"
                                                        renderOption={(item: Injury) => <Option key={item.vccCase + ''} value={`${item.vccCase}`} title={item.vccCase} {...{ item: item }}>{item.vccCase + " " + item.employe.lastName + " " + item.employe.firstName}</Option>}
                                                        onSelect={(value, option) => {
                                                            formikBag.setFieldValue('injuryId', option.props.item.id);
                                                        }}

                                                        params={{
                                                            withEmployee: true
                                                        }}
                                                        onChange={(value = '') => {
                                                            if (value === '') {
                                                                formikBag.setFieldValue('injuryId', null);
                                                            }
                                                        }}
                                                        value={this.state.selectedItem && this.state.selectedItem.injury ? this.state.selectedItem.injury.vccCase : (this.props.injury ? this.props.injury.vccCase : '')}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />}

                                    {!this.state.isTask && !this.state.isReplayTask && <Field
                                        name="emailAccountId"
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                            <div className={`form-group col-sm-6 ${form.errors.emailAccountId && form.touched.emailAccountId ? 'has-error' : ''}`}>

                                                <label className="col-sm-3  col-form-label">Email Account</label>
                                                <div className="col-sm-9 is-relative">
                                                    <Autocomplete type="emailAccountList"
                                                        renderOption={(item) => <Option key={item.id} value={`${item.accountName}`} title={item.accountName} {...{ item: item }}>{item.accountName}</Option>}
                                                        onSelect={(value, option) => {

                                                            formikBag.setFieldValue('emailAccountId', option.props.item.id);
                                                            this.applynewsig(formikBag, option)

                                                        }}
                                                        onChange={(value = '') => { if (value === '') { formikBag.setFieldValue('emailAccountId', null); } }}
                                                        value={this.state.selectedItem && this.state.selectedItem.emailAccount ? this.state.selectedItem.emailAccount.accountName : (this.state.emailAccount ? this.state.emailAccount.accountName : '')}
                                                        paramName="accountName"
                                                        min={0}
                                                    />
                                                </div>
                                                {form.touched.emailAccountId && form.errors.emailAccountId ? <div className="validation-message">{form.errors.emailAccountId}</div> : ''}
                                            </div>
                                        )}
                                    />}

                                    {this.state.isTask && !this.state.isReplayTask && <Field
                                        name="taskStatus"
                                        onSelect={(value) => {

                                            console.log(value);

                                        }}  
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                            <div className={`form-group col-sm-3`}>
                                                <label htmlFor="emailPriority">Task Status</label>
                                                <select className="form-control input-sm" id="emailPriority"  {...field}
                                                                                                 >
                                                    <option value="-1">Not Started</option>
                                                    <option value="0">In Progress</option>
                                                    <option value="1">Completed</option>
                                                    <option value="2">Waiting On Someone Else</option>
                                                    <option value="3">Deferred</option>
                                                </select>
                                            </div>
                                        )}
                                    />}
                                    {this.state.isTask && !this.state.isReplayTask && <Field
                                        name="completePercent"
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="completePercent">Completed %</label>
                                                <br />
                                                <InputNumber className="form-control" id="completePercent" defaultValue={0}
                                                    onChange={(value) => {
                                                        formikBag.setFieldValue('completePercent', value);
                                                    }}
                                                    min={0} max={100} />
                                            </div>
                                        )}
                                    />}
                                </div>


                                {!this.state.isTask && !this.state.isReplayTask && <div className="row">


                                    <Field
                                        name="to"
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => {

                                            return <React.Fragment>

                                                <FormikList
                                                    field={field}
                                                    formikBag={formikBag}
                                                    equalityChecker={(item, newItem) => item.email == newItem.email}
                                                    renderItem={(autocompleteItem: CompanyContact, removeItem) => <div className="inline-select-item" key={autocompleteItem.id}>
                                                        <i className="fa fa-times inline-select-item__remove" onClick={removeItem} />
                                                        <span>{(autocompleteItem.lastName ? autocompleteItem.lastName + ' ' : '') + (autocompleteItem.firstName ? autocompleteItem.firstName + ' ' : '')}({autocompleteItem.email})</span>
                                                        {!autocompleteItem.id && <i className="fa fa-plus " onClick={(e) => this.addContact(autocompleteItem.email, e)} />}
                                                    </div>}
                                                    renderInput={addNewItem => <div
                                                        className={`form-group col-sm-12 ${form.touched.to && form.errors.to ? 'has-error' : ''}`}>
                                                        <div className="is-relative">
                                                            <label className="col-sm-1 col-form-label">TO</label>
                                                            <div className="col-sm-11">
                                                                <Autocomplete type="companyContactList"
                                                                    renderOption={(autocompleteItem: CompanyContact) =>
                                                                        <Option
                                                                            {...{
                                                                                key: autocompleteItem.id + '',
                                                                                value: autocompleteItem.id + '',
                                                                                title: autocompleteItem.email,
                                                                                item: autocompleteItem
                                                                            }}>{(autocompleteItem.lastName ? autocompleteItem.lastName + ' ' : '') + (autocompleteItem.firstName ? autocompleteItem.firstName + ' ' : '')}({autocompleteItem.email})</Option>
                                                                    }
                                                                    onSelect={(value, option) => {
                                                                        if (Yup.string().email().isValidSync(option.props.item.email)) {
                                                                            addNewItem({
                                                                                id: option.props.item.id,
                                                                                email: option.props.item.email,
                                                                                firstName: option.props.item.firstName,
                                                                                lastName: option.props.item.lastName,
                                                                            });
                                                                        }
                                                                    }}
                                                                    params={{
                                                                        isEmailFilter: true
                                                                    }}
                                                                    value=""
                                                                    paramName="text"
                                                                    isClear={true}
                                                                    antdProps={{
                                                                        placeholder: 'Start typing for search...'
                                                                    }}
                                                                    loadOnFocus={true}
                                                                    updateAfterSelect={true}

                                                                />
                                                            </div>
                                                            {form.touched.to && form.errors.to ? <div className="validation-message">{form.errors.to}</div> : ''}
                                                        </div>
                                                    </div>}

                                                />
                                            </React.Fragment>
                                        }}
                                    />
                                </div>}

                            </React.Fragment>
                            }
                            {this.state.isTask && !this.state.isReplayTask &&
                                <div className="row">

                                <div className={`form-group col-md-6 col-sm-12col-xs-12`}>
                                        <label>User</label>
                                        <Autocomplete
                                        type="userList"
                                        params={{ roles: [ UserRole.Admin, UserRole.Internal ] }}
                                            renderOption={(item: { id: number, lastName: string, firstName: string }) =>
                                                <Option {...{
                                                    key: item.id + '',
                                                    value: item.id + '',
                                                    title: item.lastName + ' ' + item.firstName,
                                                    item: item
                                                }}>{item.lastName + ' ' + item.firstName}</Option>
                                            }
                                            onSelect={(value, option) => {
                                                formikBag.setFieldValue("userId", option.props.item.id);
                                            }}
                                            onChange={(value = '') => {
                                                if (value === '') {
                                                    formikBag.setFieldValue('userId', null);
                                                }
                                            }}
                                            value={''}
                                        />
                                    </div>
                                    <div className="form-group col-sm-3 filters-component__date">
                                        <label>Start Date</label>

                                        <Datepicker
                                            disabledDate={disabledTo(formikBag.values.dueDate, true)}
                                            value={formikBag.values.startDate}
                                            format="MM/DD/YY"
                                            onChange={date => formikBag.setFieldValue('startDate', date ? new Date(date).setHours(0, 0, 0, 0) : date)}
                                            antdProps={{
                                                placeholder: "Start Date"
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-sm-3 filters-component__date">
                                        <label>Due Date</label>
                                        <Datepicker
                                            disabledDate={disabledFrom(formikBag.values.startDate, true)}
                                            value={formikBag.values.dueDate}
                                            format="MM/DD/YY"
                                            onChange={date => formikBag.setFieldValue('dueDate', date ? new Date(date).setHours(24, 0, 0, -1) : date)}
                                            antdProps={{
                                                placeholder: "Due Date"
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                            {this.state.isTask && !this.state.isReplayTask &&
                                <div className="row">
                                    {((this.props.globalSettings && this.props.globalSettings.settings.allowInternalToCloseEmail == true) || (this.props.user && this.props.user.role == UserRole.Admin)) && <Field
                                        name="emailStatus"
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label htmlFor="emailStatus">Status</label>
                                                <select className="form-control input-sm" id="emailStatus"  {...field}>
                                                    <option value="0">Open</option>
                                                    <option value="1">Close</option>
                                                </select>
                                            </div>
                                        )}
                                    />}
                                    <Field
                                        name="emailPriority"
                                        render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label htmlFor="emailPriority">Priority</label>
                                                <select className="form-control input-sm" id="emailPriority"  {...field}>
                                                    <option value="0">Low</option>
                                                    <option value="1">High</option>
                                                </select>
                                            </div>
                                        )}
                                    />
                                </div>}

                            {!this.state.isTask && !this.state.isReplayTask && <div className="row">
                                <Field
                                    name="cc"
                                    render={({ field, form }: FieldProps<SendReplyMessage>) => {

                                        return <React.Fragment>

                                            <FormikList
                                                field={field}
                                                formikBag={formikBag}
                                                equalityChecker={(item, newItem) => item.email == newItem.email}
                                                renderItem={(autocompleteItem: CompanyContact, removeItem) => <div className="inline-select-item" key={autocompleteItem.id}>
                                                    <i className="fa fa-times inline-select-item__remove" onClick={removeItem} />
                                                    <span>{(autocompleteItem.lastName ? autocompleteItem.lastName + ' ' : '') + (autocompleteItem.firstName ? autocompleteItem.firstName + ' ' : '')}({autocompleteItem.email})</span>
                                                    {!autocompleteItem.id && <i className="fa fa-plus " onClick={(e) => this.addContact(autocompleteItem.email, e)} />}
                                                </div>}
                                                renderInput={addNewItem => <div
                                                    className={`form-group col-sm-12 ${form.touched.cc && form.errors.cc ? 'has-error' : ''}`}>
                                                    <div className="is-relative">
                                                        <label className="col-sm-1  col-form-label">CC</label>
                                                        <div className="col-sm-11">
                                                            <Autocomplete type="companyContactList"
                                                                renderOption={(autocompleteItem: CompanyContact) =>
                                                                    <Option
                                                                        {...{
                                                                            key: autocompleteItem.id + '',
                                                                            value: autocompleteItem.id + '',
                                                                            title: autocompleteItem.email,
                                                                            item: autocompleteItem
                                                                        }}>{(autocompleteItem.lastName ? autocompleteItem.lastName + ' ' : '') + (autocompleteItem.firstName ? autocompleteItem.firstName + ' ' : '')}({autocompleteItem.email})</Option>
                                                                }
                                                                onSelect={(value, option) => {
                                                                    if (Yup.string().email().isValidSync(option.props.item.email))
                                                                        addNewItem({
                                                                            id: option.props.item.id,
                                                                            email: option.props.item.email,
                                                                            firstName: option.props.item.firstName,
                                                                            lastName: option.props.item.lastName,
                                                                        });
                                                                }}
                                                                params={{
                                                                    isEmailFilter: true
                                                                }}
                                                                value=""
                                                                paramName="text"
                                                                isClear={true}
                                                                antdProps={{
                                                                    placeholder: 'Start typing for search...'
                                                                }}
                                                                loadOnFocus={true}
                                                                updateAfterSelect={true}
                                                            />
                                                        </div>
                                                        {form.touched.cc && form.errors.cc ? <div className="validation-message">{form.errors.cc}</div> : ''}
                                                    </div>
                                                </div>}

                                            />
                                        </React.Fragment>
                                    }}
                                />
                            </div>}

                            {!this.state.isTask && !this.state.isReplayTask && <div className="row">
                                <Field
                                    name="bcc"
                                    render={({ field, form }: FieldProps<SendReplyMessage>) => {

                                        return <React.Fragment>

                                            <FormikList
                                                field={field}
                                                formikBag={formikBag}
                                                equalityChecker={(item, newItem) => item.email == newItem.email}
                                                renderItem={(autocompleteItem: CompanyContact, removeItem) => <div className="inline-select-item" key={autocompleteItem.id}>
                                                    <i className="fa fa-times inline-select-item__remove" onClick={removeItem} />

                                                    <span>{(autocompleteItem.lastName ? autocompleteItem.lastName + ' ' : '') + (autocompleteItem.firstName ? autocompleteItem.firstName + ' ' : '')}({autocompleteItem.email})</span>
                                                    {!autocompleteItem.id && <i className="fa fa-plus " onClick={(e) => this.addContact(autocompleteItem.email, e)} />}
                                                </div>}
                                                renderInput={addNewItem => <div
                                                    className={`form-group col-sm-12 ${form.touched.bcc && form.errors.bcc ? 'has-error' : ''}`}>
                                                    <div className="is-relative">
                                                        <label className="col-sm-1  col-form-label">BCC</label>
                                                        <div className="col-sm-11">
                                                            <Autocomplete type="companyContactList"
                                                                renderOption={(autocompleteItem: CompanyContact) =>
                                                                    <Option
                                                                        {...{
                                                                            key: autocompleteItem.id + '',
                                                                            value: autocompleteItem.id + '',
                                                                            title: autocompleteItem.email,
                                                                            item: autocompleteItem
                                                                        }}>{(autocompleteItem.lastName ? autocompleteItem.lastName + ' ' : '') + (autocompleteItem.firstName ? autocompleteItem.firstName + ' ' : '')}({autocompleteItem.email})</Option>
                                                                }
                                                                onSelect={(value, option) => {
                                                                    if (Yup.string().email().isValidSync(option.props.item.email))
                                                                        addNewItem({
                                                                            id: option.props.item.id,
                                                                            email: option.props.item.email,
                                                                            firstName: option.props.item.firstName,
                                                                            lastName: option.props.item.lastName,
                                                                        });
                                                                }}
                                                                params={{
                                                                    isEmailFilter: true
                                                                }}
                                                                value=""
                                                                paramName="text"
                                                                isClear={true}
                                                                antdProps={{
                                                                    placeholder: 'Start typing for search...'
                                                                }}
                                                                loadOnFocus={true}
                                                                updateAfterSelect={true}

                                                            />
                                                        </div>
                                                        {form.touched.bcc && form.errors.bcc ? <div className="validation-message">{form.errors.bcc}</div> : ''}
                                                    </div>
                                                </div>}

                                            />
                                        </React.Fragment>
                                    }}
                                />
                            </div>}
                            {creatingNewEmail && <div className="row">
                                <Field
                                    name="subject"
                                    render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                        <div className="form-group col-sm-12">
                                            <input className="form-control" id="subject" {...field} placeholder="Subject" />
                                        </div>
                                    )}
                                />
                            </div>
                            }
                            <Field
                                name="html"
                                render={({ field, form }: FieldProps<SendReplyMessage>) => (
                                    <div className={`form-group ${form.errors.html && form.touched.html ? 'has-error' : ''}`}>
                                        {/*<Wysiwyg
                                            onChange={(html) => { formikBag.setFieldValue('html', html) }}
                                            options={{ ...defaultOptions, lang: 'en', }}
                                            customPlugins={['fontsize', 'fontfamily', 'lineheight']}
                                            value={selectedItem ? ('<br/><br/><br/><br/><div class="gmail_quote">on ' + selectedItem.date + ', ' + selectedItem.from.substring(selectedItem.from.lastIndexOf('<') + 1, selectedItem.from.lastIndexOf('>')) + ' wrote:<br/>' + selectedItem.subject + "</div>") : ''}
                                        />*/}
                                        <FroalaEditor
                                            config={{
                                                key: "BWC6D-16A3C1E3F2D1A5vA-21mE-13tfcH1qA-32yiB6E5F4A3I2H3B7B6C5B4==",
                                                placeholder: "Edit Me",
                                                imageUploadURL: '/UploadFiles',
                                                imageUploadParams: {
                                                    id: 'my_editor'
                                                },
                                                videoUploadURL: '/UploadFiles',
                                                videoUploadParams: {
                                                    id: 'my_editor'
                                                },
                                                fileUploadURL: '/UploadFiles',
                                                fileUploadParams: {
                                                    id: 'my_editor'
                                                },
                                                events: {
                                                    'froalaEditor.image.beforeUpload': function (e, editor, images) {
                                                    }
                                                },
                                                height: 260,
                                                enter: FroalaEditor.ENTER_DIV

                                            }}
                                            model={formikBag.values.html}
                                            onModelChange={(html) => { formikBag.setFieldValue('html', html) }}
                                        />
                                        {form.touched.html && form.errors.html ? <div className="validation-message">{form.errors.html}</div> : ''}
                                    </div>
                                )}
                            />
                            <div>
                                {this.state.attachments.map((a, i) =>
                                    <div className="attached-file clearfix"><i className="fa fa-file"></i>&nbsp;{a.file.name}<i className="fa fa-times attached-file__remove" onClick={() => this.deleteAttachment(i)}></i></div>
                                )}
                            </div>
                            <div className="form-group">
                                <File buttonCaption="Add Attachment" asButton={true} buttonClassName='btn btn-blue' objectId={-1} type="injury" update={this.addAttachment} fileType={FileType.File} />
                            </div>
                            <div className="text-center space-between">

                                <Button isLoading={isLoadingReply} className="btn btn-primary" type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit() }}>{this.state.editorTitle}</Button>
                                {!this.state.isTask && !this.state.isReplayTask && <Button isLoading={isLoadingReply} className="btn btn-primary" type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); formikBag.values.draft = true; formikBag.handleSubmit() }}>Save</Button>}
                            </div>
                        </React.Fragment>}
                    /></form>
            </Modal>
        </Fragment>;
    }
}

export default connect<ReduxProps, Actions, ReportsProps>(
    (state: ApplicationState) => ({
        ...state.emailThreads,
        globalSettings: state.globalSettings,
        user: state.login.user
    }),
    (dispatch) => ({
        countersActions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
    })
)(MailBox) as typeof MailBox;



interface EmailThreadTableProps {
    emails: InjuryEmail[] | null;
    isNotExternal: boolean;
    isLoading?: boolean;
    onReply: any;
    onReplyTask: any;
    onForward: any;
    onEdit: any;
    onReplyAll: any;
    onPrint: any;
    onClose: any;
    onDelete: any;
    onRefresh: any;
    emailThread: EmailThread | null;

    user: User | null;
    globalSettings: GlobalSettingsState | null;
}

interface EmailThreadTableState {
    isPopupVisible: boolean;
    isLoading: boolean;
}

interface FormValues {

    injuryId: number | undefined | null,
    userId: number | undefined | null,
    emailFolderId: number | undefined | null,
    archive: boolean,
    read: boolean,
    emailStatus: EmailStatus,
    emailPriority: EmailPriority,
    completePercent: number,
    startDate: number | null,
    dueDate: number | null,
    taskStatus: TaskStatus
}

export class EmailThreadTable extends React.Component<EmailThreadTableProps, EmailThreadTableState>  {
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false,
            isLoading: false
        };

        this.onCancel = this.onCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values: any, formikActions: any) {
        this.setState({
            isLoading: true
        });
        const v = values as FormValues;
        const item = this.props.emailThread as EmailThread;
        item.userId = v.userId;
        item.injuryId = v.injuryId;
        item.emailFolderId = v.emailFolderId;
        item.emailStatus = v.emailStatus;
        item.emailPriority = v.emailPriority;
        item.read = v.read;
        item.archive = v.archive;
        item.completePercent = v.completePercent;
        item.taskStatus = v.taskStatus;
        item.dueDate = v.dueDate;
        item.startDate = v.startDate;

        request('emailThread', item).then((result) => {
            this.setState({
                isLoading: false
            });
            message.success('Saved');
            this.props.onRefresh();

        }).catch((e) => {
            message.error(e);
            this.setState({
                isLoading: false
            });
        });
    }

    onCancel() {
        this.setState({
            isPopupVisible: false,
        })
    }

    onBlock(email: InjuryEmail) {
        var emails = email.from.match(/(@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        if (!emails || emails.length == 0) return;
        let s: SpamFilter = {
            id: -1,
            name: '%' + emails[0] + '%',
            pattern: '%' + emails[0] + '%'
        }
        this.setState({
            isLoading: true
        });
        request('spamFilter', s).then((result) => {
            this.setState({
                isLoading: false
            });
            message.success('Domain blocked');
            this.props.onRefresh();

        }).catch((e) => {
            message.error(e);
            this.setState({
                isLoading: false
            });
        });
    }


    render() {
        const newItems: InjuryEmail[] | null = this.props.emails;
        const emailThread: EmailThread | null = this.props.emailThread;
        const isTask: boolean = emailThread ? emailThread.isTask : false;

        return <div>

            {this.props.emailThread ? <Collapse>
                <Panel header="Edit" key="1" >


                    <Formik
                        initialValues={{
                            injuryId: this.props.emailThread ? this.props.emailThread.injuryId : null,
                            archive: this.props.emailThread ? this.props.emailThread.archive : false,
                            read: this.props.emailThread ? this.props.emailThread.read : false,
                            emailStatus: this.props.emailThread ? this.props.emailThread.emailStatus : EmailStatus.Open,
                            emailPriority: this.props.emailThread ? this.props.emailThread.emailPriority : EmailPriority.Low,
                            userId: this.props.emailThread ? this.props.emailThread.userId : null,
                            emailFolderId: this.props.emailThread ? this.props.emailThread.emailFolderId : null,
                            completePercent: this.props.emailThread ? this.props.emailThread.completePercent : 0,
                            taskStatus: this.props.emailThread ? this.props.emailThread.taskStatus : TaskStatus.NotStarted,
                            startDate: this.props.emailThread ? this.props.emailThread.startDate : null,
                            dueDate: this.props.emailThread ? this.props.emailThread.dueDate : null,
                        }}
                        enableReinitialize={true}
                        onSubmit={this.handleSubmit}
                        render={(formikBag: FormikProps<FormValues>) => <React.Fragment>
                            <Form id="injury-email">
                                <div className="row">
                                    <div className={`form-group col-sm-6`}>
                                        <label>Injury</label>
                                        <Autocomplete
                                            type="vccCaseList"
                                            renderOption={(item: { id: number, vccCase: string, name: string }) =>
                                                <Option {...{
                                                    key: item.id + '',
                                                    value: item.vccCase + '',
                                                    title: item.vccCase,
                                                    item: item
                                                }}>{item.vccCase} ({item.name})</Option>
                                            }
                                            onSelect={(value, option) => {
                                                formikBag.setFieldValue("injuryId", option.props.item.id);
                                            }}
                                            onChange={(value = '') => {
                                                if (value === '') {
                                                    formikBag.setFieldValue('injuryId', null);
                                                }
                                            }}
                                            value={this.props.emailThread && this.props.emailThread.injury ? this.props.emailThread.injury.vccCase : ''}
                                        />
                                    </div>


                                    <div className={`form-group col-sm-6`}>
                                        <label>User</label>
                                        <Autocomplete
                                            type="userList"
                                            params={{ roles: [UserRole.Admin, UserRole.Internal] }}
                                            renderOption={(item: { id: number, lastName: string, firstName: string }) =>
                                                <Option {...{
                                                    key: item.id + '',
                                                    value: item.id + '',
                                                    title: item.lastName + ' ' + item.firstName,
                                                    item: item
                                                }}>{item.lastName + ' ' + item.firstName}</Option>
                                            }
                                            onSelect={(value, option) => {
                                                formikBag.setFieldValue("userId", option.props.item.id);
                                            }}
                                            onChange={(value = '') => {
                                                if (value === '') {
                                                    formikBag.setFieldValue('userId', null);
                                                }
                                            }}
                                            value={this.props.emailThread && this.props.emailThread.user ? this.props.emailThread.user.lastName + ' ' + this.props.emailThread.user.firstName : ''}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    {((this.props.globalSettings && this.props.globalSettings.settings.allowInternalToCloseEmail == true) || (this.props.user && this.props.user.role == UserRole.Admin)) && <Field
                                        name="emailStatus"
                                        render={({ field, form }: FieldProps<FormValues>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label htmlFor="emailStatus">Status</label>
                                                <select className="form-control input-sm" id="emailStatus"  {...field}>
                                                    <option value="0">Open</option>
                                                    <option value="1">Close</option>
                                                </select>
                                            </div>
                                        )}
                                    />}
                                    <Field
                                        name="emailPriority"
                                        render={({ field, form }: FieldProps<FormValues>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label htmlFor="emailPriority">Priority</label>
                                                <select className="form-control input-sm" id="emailPriority"  {...field}>
                                                    <option value="0">Low</option>
                                                    <option value="1">High</option>
                                                </select>
                                            </div>
                                        )}
                                    />
                                </div>
                                {isTask && <div className="row">
                                    <Field
                                        name="taskStatus"
                                        
                                        render={({ field, form }: FieldProps<FormValues>) => (
                                            <div className={`form-group col-sm-3`}>
                                                <label htmlFor="taskStatus">Task Status</label>
                                                <Select defaultValue={field.value} onChange={(value) => {
                                                    if (value == 1) {
                                                        formikBag.setFieldValue("completePercent", 100);
                                                    }
                                                    formikBag.setFieldValue("taskStatus", value);
                                                }} >
                                                    <Option value={-1}>Not Started</Option>
                                                    <Option value={0}>In Progress</Option>
                                                    <Option value={1}>Completed</Option>
                                                    <Option value={2}>Waiting On Someone Else</Option>
                                                    <Option value={3}>Deferred</Option>
                                                </Select>
                                            </div>
                                        )}
                                    />
                                    <Field
                                        name="completePercent"
                                        render={({ field, form }: FieldProps<FormValues>) => (
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="completePercent">Completed %</label>
                                                <br />
                                                <InputNumber className="form-control" id="completePercent" defaultValue={emailThread ? emailThread.completePercent : 0}
                                                    onChange={(value) => {
                                                        formikBag.setFieldValue('completePercent', value);
                                                    }}
                                                    value={field.value}
                                                    min={0} max={100} />
                                            </div>
                                        )}
                                    />

                                    <div className="form-group col-sm-3 filters-component__date">
                                        <label>Start Date</label>

                                        <Datepicker
                                            disabledDate={disabledTo(formikBag.values.dueDate, true)}
                                            value={formikBag.values.startDate}
                                            format="MM/DD/YY"
                                            onChange={date => formikBag.setFieldValue('startDate', date ? new Date(date).setHours(0, 0, 0, 0) : date)}
                                            antdProps={{
                                                placeholder: "Start Date"
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-sm-3 filters-component__date">
                                        <label>Due Date</label>
                                        <Datepicker
                                            disabledDate={disabledFrom(formikBag.values.startDate, true)}
                                            value={formikBag.values.dueDate}
                                            format="MM/DD/YY"
                                            onChange={date => formikBag.setFieldValue('dueDate', date ? new Date(date).setHours(24, 0, 0, -1) : date)}
                                            antdProps={{
                                                placeholder: "Due Date"
                                            }}
                                        />
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    {!isTask && <div className={`form-group col-sm-6`}>
                                        <label>Folder</label>
                                        <Autocomplete
                                            type="emailFolderList"
                                            renderOption={(item: { id: number, name: string }) =>
                                                <Option {...{
                                                    key: item.id + '',
                                                    value: item.name + '',
                                                    title: item.name,
                                                    item: item
                                                }}>{item.name}</Option>
                                            }
                                            onSelect={(value, option) => {
                                                formikBag.setFieldValue("emailFolderId", option.props.item.id);
                                            }}
                                            onChange={(value = '') => {
                                                if (value === '') {
                                                    formikBag.setFieldValue('emailFolderId', null);
                                                }
                                            }}
                                            value={this.props.emailThread && this.props.emailThread.emailFolder ? this.props.emailThread.emailFolder.name : ''}
                                        />
                                    </div>}
                                </div>
                                <div className="row">
                                    <Field name="read"
                                        render={({ field, form }: FieldProps<FormValues>) => (
                                            <div className="form-group col-sm-1">
                                                <label>Read</label>
                                                <br />
                                                <input id='read' className="toggle-checkbox" type="checkbox" checked={field.value}  {...field} />
                                                <label htmlFor="read" className="toggle-checkbox__label toggle-checkbox__label__sm"></label>
                                            </div>
                                        )}
                                    />

                                    <Field name="archive"
                                        render={({ field, form }: FieldProps<FormValues>) => (
                                            <div className="form-group col-sm-1">
                                                <label>Archive</label>
                                                <br />
                                                <input id='archive' className="toggle-checkbox" type="checkbox" checked={field.value}  {...field} />
                                                <label htmlFor="archive" className="toggle-checkbox__label toggle-checkbox__label__sm"></label>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="text-center form-group">
                                    <Button isLoading={this.state.isLoading} onClick={(e) => formikBag.submitForm()}>Save</Button>

                                </div>
                            </Form>
                        </React.Fragment>}
                    />
                </Panel></Collapse> : ''}


            {newItems != null ? newItems.filter(e => !e.deleted).map((email: InjuryEmail) => (
                <div className="card card-primary card-outline">
                    <div className="card-header">

                    </div>
                    <div className="card-body p-0">
                        <div className="mailbox-read-info">
                            <h5>{email.subject} {!isTask && <span className="mailbox-read-time float-right">Time:{email.date ? moment(email.date).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span>}</h5>
                            {!isTask && <h6>To: {email.to} <span className="mailbox-read-time float-right">Delivery Time:{email.deliveredTime ? moment(email.deliveredTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>}
                            {!isTask && <h6>From: {email.from} <span className="mailbox-read-time float-right">Send Time:{email.sendTime ? moment(email.sendTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>}
                            {!isTask && <h6>CC: {email.cc} <span className="mailbox-read-time float-right">Reply Time:{email.replyTime ? moment(email.replyTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>}
                            {!isTask && <h6>BCC: {email.bcc} <span className="mailbox-read-time float-right">Create Time:{email.createTime ? moment(email.createTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>}
                            {isTask && <h6>&nbsp;<span className="mailbox-read-time float-right">Create Time:{email.createTime ? moment(email.createTime).format(`dddd, MM/DD/YY h:mm:ss A`) : ''}</span></h6>}
                            {isTask && <h6>&nbsp;<span className="mailbox-read-time float-right">Start Date:{emailThread && emailThread.startDate ? moment(emailThread.startDate).format(`dddd, MM/DD/YY`) : ''}</span></h6>}
                            {isTask && <h6>&nbsp;<span className="mailbox-read-time float-right">Due Date:{emailThread && emailThread.dueDate ? moment(emailThread.dueDate).format(`dddd, MM/DD/YY`) : ''}</span></h6>}
                            {!isTask && <h6>{email.viewed ? 'Viewed' : ''}</h6>}
                            {!isTask && <h6>{email.deliveredTime || email.draft ? '' : 'Sending'}</h6>}
                            <h6>{email.draft ? 'Draft' : ''}</h6>
                            {email.user ? <h6>{"By: " + email.user.lastName + ' ' + email.user.firstName}</h6> : ''}
                        </div>
                        <div className="mailbox-controls with-border text-center">
                            <div className="btn-group">
                                {((this.props.user && this.props.user.role == UserRole.Admin)) && <button type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onDelete(email) }} className="btn btn-default btn-sm" data-toggle="tooltip" data-container="body" title="Delete">
                                    <i className="fa fa-lg fa-trash"></i></button>}
                                {(!email.draft && (email.userId == null || (emailThread && emailThread.isTask))) && <button type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); (isTask ? this.props.onReplyTask(email) : this.props.onReply(email)) }} className="btn btn-default btn-sm" data-toggle="tooltip" data-container="body" title="Reply">
                                    <i className="fa fa-lg fa-reply"></i></button>}
                                {(!email.draft && emailThread && !emailThread.isTask) && <button type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onForward(email) }} className="btn btn-default btn-sm" data-toggle="tooltip" data-container="body" title="Forward">
                                    <i className="fa fa-lg fa-share"></i></button>}

                                <button type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onPrint(email, emailThread) }} className="btn btn-default btn-sm" data-toggle="tooltip" title="Print">
                                    <i className="fa fa-lg fa-print"></i></button>
                                <button type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onClose(email) }} className="btn btn-default btn-sm" data-toggle="tooltip" title="Close">
                                    <i className="fa fa-lg fa-close"></i></button>
                            </div>
                        </div>
                        <div className="mailbox-read-message">
                            <div dangerouslySetInnerHTML={{ __html: email.html || email.text }} />
                            {/*__html: "<iframe srcdoc='" + email.html + "' onload='this.style.height = (this.contentWindow.document.body.scrollHeight+100) + \"px\"; this.style.width = (this.contentWindow.document.body.scrollWidth+100) + \"px\";' frameBorder='0' ></iframe>"  }} />*/}

                        </div>
                    </div>
                    <div className="card-footer bg-white">
                        <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                            {email.injuryFiles && email.injuryFiles.length > 0 &&
                                email.injuryFiles.map(f =>

                                    <li>
                                        <span className="mailbox-attachment-icon"><i className="far fa-file-pdf"></i></span>

                                        <div className="mailbox-attachment-info">
                                            <a target="_blank" href={f.file.src} className="mailbox-attachment-name"><i className="fa fa-paperclip"></i> {f.file.name}</a>
                                            <span className="mailbox-attachment-size clearfix mt-1">
                                                <span>{f.file.size}</span>
                                                <a target="_blank" href={f.file.src} key={f.id} className="btn btn-default btn-sm float-right"><i className="fa fa-download"></i></a>
                                            </span>
                                        </div>
                                    </li>)}
                        </ul>
                    </div>
                    <div className="card-footer">
                        <div className="float-right">
                            {(!email.draft && (email.userId == null || (emailThread && emailThread.isTask))) && <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); (isTask ? this.props.onReplyTask(email) : this.props.onReply(email)) }}><i className="fa fa-lg fa-reply"></i> Reply</button>}
                            {(!email.draft && email.userId == null) && <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onReplyAll(email) }}><i className="fa fa-lg fa-reply"></i> Reply All</button>}

                            {(!email.draft && emailThread && !emailThread.isTask) && <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onForward(email) }}><i className="fa fa-lg fa-share"></i> Forward</button>}
                            {(email.draft) && <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onEdit(email) }}><i className="fa fa-lg fa-edit"></i> Edit</button>}
                        </div>
                        {((this.props.user && this.props.user.role == UserRole.Admin)) && <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onDelete(email) }}><i className="fa fa-lg fa-trash"></i> Delete</button>}
                        {(!email.draft && email.userId == null) && <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); this.onBlock(email) }}><i className="fa fa-lg fa-ban"></i> Block Domain</button>}
                        <button type="button" className="btn btn-default" onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onPrint(email, emailThread) }}><i className="fa fa-lg fa-print"></i> Print</button>
                    </div>
                </div>)) : ''}

        </div>
    }
}