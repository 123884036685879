import {Company} from "@app/objects/Company";

export enum AttorneyType {
	Employee = 0,
	Defence = 1,
	ClaimAdjuster = 2
}

export interface Attorney {
	id: number;
	
	attorneyType: AttorneyType;
	lawFirm: string;
	firstName: string;
	lastName: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	phone: string;
	extension: string;
	fax: string;
	email: string;
	
	insuranceCompany: Company;
	insuranceCompanyId: number;
}
