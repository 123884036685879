import * as React from 'react';

import {StateTimeZone} from '@common/typescript/objects/StateTimeZone';

interface ComponentProps {
	className?: string;
	state: string;
	defaultCaption?: string;
	value: StateTimeZone;
	onChange: (timeZone: string) => void;
}

const stateTimeZones = {
	alabama: [-6],
	alaska: [-9, -10],
	arizona: [-7],
	arkansas: [-6],
	california: [-8],
	colorado: [-7],
	connecticut: [-5],
	delaware: [-5],
	districtofcolumbia: [-5],
	florida: [-5, -6],
	georgia: [-5],
	hawaii: [-10],
	idaho: [-7, -8],
	illinois: [-6],
	indiana: [-5, -6],
	iowa: [-6],
	kansas: [-6, -7],
	kentucky: [-5, -6],
	louisiana: [-6],
	maine: [-5],
	maryland: [-5],
	massachusetts: [-5],
	michigan: [-5, -6],
	minnesota: [-6],
	mississippi: [-6],
	missouri: [-6],
	montana: [-7],
	nebraska: [-6, -7],
	nevada: [-8, -7],
	newhampshire: [-5],
	newjersey: [-5],
	newmexico: [-7],
	newyork: [-5],
	northcarolina: [-5],
	northdakota: [-6, -7],
	ohio: [-5],
	oklahoma: [-6],
	oregon: [-8, -7],
	pennsylvania: [-5],
	rhodeisland: [-5],
	southcarolina: [-5],
	southdakota: [-6, -7],
	tijuanamexico: [-8],
	tennessee: [-5, -6],
	texas: [-6, -7],
	utah: [-7],
	vermont: [-5],
	virginia: [-5],
	washington: [-8],
	westvirginia: [-5],
	wisconsin: [-6],
	wyoming: [-7]
};

export const stateZoneNames = {
	'-4': 'UTC-4: Atlantic Standard Time (AST)',
	'-5': 'UTC-5: Eastern Standard Time (EST)',
	'-6': 'UTC-6: Central Standard Time (CST)',
	'-7': 'UTC-7: Mountain Standard Time (MST)',
	'-8': 'UTC-8: Pacific Standard Time (PST)',
	'-9': 'UTC-9: Alaska Standard Time (AKST)',
	'-10': 'UTC-10: Hawaii-Aleutian Standard Time (HAST)',
	'-11': 'UTC-11: Samoa Standard Time',
	10: 'UTC+10: Chamorro Standard Time',
	12: 'UTC+12: Wake Island Time Zone'
};

export class StateTimeZoneSelect extends React.Component<ComponentProps> {
	public static defaultProps: Partial<ComponentProps> = {
		className: 'form-control',
		state: '', 
		defaultCaption: 'Select state first'
	};
	
	componentWillReceiveProps(nextProps: ComponentProps) {
		if (nextProps.state !== this.props.state) {
			const zones = this.getZones(nextProps.state);
			if (zones.length > 0) {
				this.props.onChange(`${zones[0]}`);
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.value !== this.props.value ||
			nextProps.state !== this.props.state;
	}
	
	getZones(state: string): number[] {
		const stateName = state.toString().toLowerCase().replace(/\s/g, '');
		
		if (stateTimeZones[stateName]) {
			return stateTimeZones[stateName];
		} 
		
		return [];
	}
	
	render() {
		const {state, value, className, onChange, defaultCaption} = this.props;
		
		return <select 
			value={`${value}`} 
			className={`state-timezone-component ${className}`} 
			onChange={event1 => onChange(event1.target.value)} 
			disabled={state === ''}
		>
			<option disabled selected={state === ''} value="0">{defaultCaption}</option>
			{this.getZones(state).map((s) => {
				if (stateZoneNames[s.toString()]) {
					return <option value={s} key={s}>{stateZoneNames[s.toString()]}</option>;
				}
			})}

		</select>;
	}
}