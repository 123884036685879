export interface OutcomeType {
	id: number;
	name: string;
	group: Group;
	active: boolean;
}

export enum Group {
	ReturnToWork = 0,
	NPOTerminated = 1,
	Declined = 2,
	Inactive = 3
}