import { InjuryEmail } from "./InjuryEmail";
import { User } from "./User";
import { EmailThread } from "./EmailThread";

export enum EmailFolderType { Public, Private }
export interface EmailFolder {
    id: number;
    newCount: number;
    name: string;
    type: EmailFolderType;
    emails: InjuryEmail[];
    emailThreads: EmailThread[];
    userId: number | null | undefined;
    user: User | null | undefined;
    deleted: boolean;
    active: boolean;
}