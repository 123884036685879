import {Company} from "@app/objects/Company";
import {Injury} from "@app/objects/Injury";
import {List} from "@common/typescript/objects/List";
import Event from "@app/objects/Event";
import FollowUp from "@app/objects/FollowUp";

export enum DayOfWeek {
    Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

export interface WeekDay {
	id: number;
    scheduleId: number;
    schedule: Schedule | null | undefined;
	startTime: string | null | undefined;
	endTime: string | null | undefined;
	lateInAlert: number | null;
	lateOutAlert: number | null;
	day: DayOfWeek;
}

export default interface Schedule {
	id: number;
	companyName: string;
    companyId: number;
    company: Company;
    injuryId: number;
    injury: Injury | null | undefined;
    startTime: number;
    endTime: number;
    note: string;
    lunchStartTime: number;
    lunchEndTime: number;
    workDays: List<WeekDay>;
    events: List<Event>;
	followUp: List<FollowUp>;
	followUps: List<FollowUp>;
	totalDaysInProgram: number;
	totalDaysWorked: number;
	hoursWorked: number;
	last2WeeksHoursScheduled: number;
	last2WeeksHoursWorked: number;
	closedTime: number;
	outcomeTypeId: number;
	changeFrom?: number;
	nonProfitAdditionalInstructions: string;
	jobTask: string;
	physicalRequirements: string;
	timeRequirements: string;
	reportTo: string;
	title: string;

	placementTime: number;
	reportTime: number | null;
	reportDay: number;

	textSchedule: string;

	actualStartTime: number;
	lastDateWorked: number;
	wrkCity: string;
	wrkZip: string;
}