import * as React from "react";
import FollowUp from "@app/objects/FollowUp";

import Checkbox  from 'antd/lib/checkbox';
import {request} from "@app/components/Api";
import {Reminder} from "@app/objects/Reminder";

interface ComponentProps {
	item: FollowUp | Reminder;
	onCheck: (item: FollowUp | Reminder) => void;
	type?: 'followUp' | 'reminder';
}

export const FollowUpDoneCheckbox: React.SFC<ComponentProps> = ({ onCheck, item, type = 'followUp' }) => {
	const onChange = (e) => {
		let data;
		
		if(type === 'followUp') {
			data = {
				...item, 
				done: true, 
				followUpType: null, 
				statusHistory: null, 
				user: null, 
				injury: null, 
				schedule: null
			}
		} else {
			data = {
				...item,
				done: true,
				followUpType: null,
				followUp: null,
			}
		}
		
		return request<FollowUp | Reminder>(type, data).then((response: FollowUp | Reminder) => {
			onCheck(response);
		})
	};
	
	return <Checkbox
		checked={item.done}
		disabled={item.done}
		onChange={onChange}
		className="follow-up-done-checkbox"
	/>;
};