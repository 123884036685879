import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import {ActionCreatorsMapObject, bindActionCreators} from 'redux';

import { request } from '@common/react/components/Api';
import * as PageState from '@common/react/store/Item';
import * as ItemsState from '@common/react/store/ItemList';
import {BaseApplicationState} from '@common/react/store';
import {BaseUser} from '@common/react/objects/BaseUser';
import {BaseParams} from '@common/react/objects/BaseParams';
import Button from '@common/react/components/Forms/Button';

interface Actions<T, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	itemActions: PageState.IActionCreators<TUser, TApplicationState>;
	pagesActions: ActionCreatorsMapObject;
}

export type PageProps<T, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> =
	PageState.ItemState<T>
	& Actions<T, TUser, TApplicationState>
	& RouteComponentProps<{ id: string }>;

export class ExtendableItemEditor<TEntity, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>, TPropsExtension>
	extends React.Component<PageProps<TEntity, TUser, TApplicationState> & TPropsExtension, any> {
	type: string = 'page';
	itemsStore: string = 'page';
	itemsPath: string = 'pageList';
	path: string = 'getPage';
	defaultItem: any = {id: -1};
	backPath: string = '/';
	additionalParams: BaseParams = {};

	constructor(props: any) {
		super(props);

		this.state = {
			isLoading: false,
			success: false,
			error: null
		};

		this.handlerBack = this.handlerBack.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCheckItem = this.handleCheckItem.bind(this);
		this.saveItem = this.saveItem.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.clearForSubmit = this.clearForSubmit.bind(this);
	}

	componentWillMount() {
		const itemPathOrId = this.props.match
			? this.props.match.params.id
			: -1;
		this.props.itemActions.loadItem(this.type, this.path, itemPathOrId, this.defaultItem, this.additionalParams);
	}

	handlerBack(event: React.MouseEvent<HTMLButtonElement>) {
		this.props.history.push(this.backPath);
	}
	
	clearForSubmit(values: any): any {
		return {children: null, files: null};
	}

	submitForm(values: any, returnToList: boolean = true): Promise<TEntity | void> {
		const item = { ...(this.props.item as any), ...values, ...this.clearForSubmit(values)};

		return this.saveItem(item).then((response) => {
			if (returnToList) {
				this.returnToList();
			} else {
				this.updateItem(response);
				this.hideSuccess();
			}
		});
	}
	
	saveItem(item: any): Promise<any> {
		this.setState({
			isLoading: true,
			error: null
		});
		
		return request(this.type, item).then((response: any) => {
			this.setState({
				isLoading: false,
				success: true
			});

			this.props.pagesActions.refreshPages(this.itemsStore, this.itemsPath);

			return response;
		}).catch((error: string) => {
			this.setState({
				error: error,
				isLoading: false
			});
			
			throw error;
		});
	}

	returnToList(): void {
		setTimeout(() => {
			this.props.history.push(this.backPath);
		}, 2000);
	}

	handleSubmit(values: any, formikActions: any) {
		this.submitForm(values);
	}

	hideSuccess() {
		setTimeout(() => {
			this.setState({
				success: false
			});
		}, 2000);
	}
	
	messages() {
		return (
			<React.Fragment>
				{this.state.success ? <div className="alert alert-success">Successfully saved</div> : ''}
				{this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : ''}
			</React.Fragment>
		);
	}
	
	buttons() {
		return <div className="text-center form-group">
			<Button isLoading={this.state.isLoading}>Save</Button>
			<button type="button" className="btn btn-danger" onClick={this.handlerBack}>Cancel</button>
		</div>;
	}
	
	updateItem(data: any) {
		this.props.itemActions.updateItem(this.type, data);
	}
	
	handleCheckItem() {
		const item: any = this.props.item;
		
		if (item && this.props.match && item.id === +this.props.match.params.id) {
			return true;
		}
	}
}

export class ItemEditor<
	T, 
	TUser extends BaseUser, 
	TApplicationState extends BaseApplicationState<TUser>
> extends ExtendableItemEditor<T, TUser, TApplicationState, {}> {}

export function dispatchToProps<T>(dispatch) {
	return {
		itemActions: bindActionCreators(PageState.getActionCreators(), dispatch),
		pagesActions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
	};
}