(function ($) {
	'use strict';
	
	var selectedImageClass = 'trumbowyg-image-selected';
	var panel = {
		arrow: 'trumbowyg-popover-arrow-up',
		height: 38,
		width: 238
	};
	
	$.extend(true, $.trumbowyg, {
		plugins: {
			imgClick: {
				init: function (trumbowyg) {
					trumbowyg.$c.on('click', 'img', function () {
						
						$(document).mouseup(function (e) {
							if (!$('selected').is(e.target) && !$('.trumbowyg-popover').find(e.target).length) {
								$('.' + selectedImageClass).removeClass(selectedImageClass);
								$('.trumbowyg-popover').remove();
							}
						});
						
						if ($(this).hasClass(selectedImageClass)) {
							$(this).removeClass(selectedImageClass);
							$('.trumbowyg-popover').remove();
							
						} else if ($('.' + selectedImageClass).length) {
							$('.' + selectedImageClass).removeClass(selectedImageClass);
							$('.trumbowyg-popover').remove();
							$(this).addClass(selectedImageClass);
							showPanel();
						} else {
							$(this).addClass(selectedImageClass);
							showPanel();
						}
						
						var img = $('.' + selectedImageClass)
						
						if (!img.attr('data-width')) {
							img.attr('data-width', img.width());
						} 
					});
					
					trumbowyg.$c.on('scroll', function () {
						if ($('.trumbowyg-popover').length) {
							$('.trumbowyg-popover').remove();
							showPanel();
						}
					});
				}
			},
			buttonsClick: {
				init: function (trumbowyg) {
					$('body')
						.on('click', '.trumbowyg-img-float-left', function () {
							$('.' + selectedImageClass).css({ float: 'left', marginRight: '5px', marginLeft: '' });
							trumbowyg.syncTextarea();
							trumbowyg.$c.trigger('tbwchange');

							$('.trumbowyg-popover').remove();
							showPanel();
						})
						.on('click', '.trumbowyg-img-float-right', function () {
							$('.' + selectedImageClass).css({ float: 'right', marginLeft: '5px', marginRight: '' });
							trumbowyg.syncTextarea();
							trumbowyg.$c.trigger('tbwchange');
							
							$('.trumbowyg-popover').remove();
							showPanel();
						})
						.on('click', '.trumbowyg-img-float-none', function () {
							$('.' + selectedImageClass).css({ float: 'none', marginRight: '5px', marginLeft: '' });
							trumbowyg.syncTextarea();
							trumbowyg.$c.trigger('tbwchange');
							
							$('.trumbowyg-popover').remove();
							showPanel();
						})
						.on('click', '.trumbowyg-img-full', function () {
							$('.' + selectedImageClass).css({ width: $('.' + selectedImageClass).attr('data-width') + 'px' });
							trumbowyg.syncTextarea();
							trumbowyg.$c.trigger('tbwchange');

							$('.trumbowyg-popover').remove();
							showPanel();
						})
						.on('click', '.trumbowyg-img-half', function () {
							$('.' + selectedImageClass).css({ width: $('.' + selectedImageClass).attr('data-width')/2 + 'px' });
							trumbowyg.syncTextarea();
							trumbowyg.$c.trigger('tbwchange');

							$('.trumbowyg-popover').remove();
							showPanel();
						})
						.on('click', '.trumbowyg-img-quarter', function () {
							$('.' + selectedImageClass).css({ width: $('.' + selectedImageClass).attr('data-width')/4 + 'px' });
							trumbowyg.syncTextarea();
							trumbowyg.$c.trigger('tbwchange');

							$('.trumbowyg-popover').remove();
							showPanel();
						});
				}
			}
		}
	});
	
	function showPanel() {
		
		var image = $('.' + selectedImageClass),
			wysiwyg = $('.wysiwyg-component');
		
		var top = image.offset() ? image.offset().top + image.height() : 0,
			left = image.offset() ? image.offset().left : 0;
		
		var wysiwygBottom = wysiwyg.offset().top + wysiwyg.height(),
			wysiwygRight = wysiwyg.offset().left + wysiwyg.width();
		
		if (top > wysiwygBottom) top = wysiwygBottom;
		
		if (left + panel.width > wysiwygRight) left = left + image.width() - panel.width;
		
		$('body').append('<div class="trumbowyg-popover" style="position: absolute; top: ' + top +'px; left: ' + left +'px;">' +
				'<div class="trumbowyg-popover-arrow-up"></div>' +
				'<div class="trumbowyg-popover-buttons">' +
					'<button type="button" class="btn btn-default trumbowyg-img-full" title="Float Left">100%</button>' +
					'<button type="button" class="btn btn-default trumbowyg-img-half" title="Float Right">50%</button>' +
					'<button type="button" class="btn btn-default trumbowyg-img-quarter" title="Float None">25%</button>' +
		
					'<button type="button" class="btn btn-default trumbowyg-img-float-left" title="Float Left"><i class="fa fa-align-left"></i></button>' +
					'<button type="button" class="btn btn-default trumbowyg-img-float-right" title="Float Right"><i class="fa fa-align-right"></i></button>' +
					'<button type="button" class="btn btn-default trumbowyg-img-float-none" title="Float None"><i class="fa fa-align-justify"></i></button>' +
				'</div>' +
			'</div>');
		
		if (left === image.offset().left + image.width() - panel.width) $('.' + panel.arrow).css({transform: 'translateX(' + panel.width + 'px)'});
	}
	
})(jQuery);