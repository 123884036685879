import * as React from 'react';

import * as Yup from 'yup';

import { Group } from "@app/objects/Group";
import CommonFilters, { FilterBaseState, RemoveButton } from "@app/components/UI/CommonFilter/CommonFilter";
import { dateFormat } from "@app/components/Utils/Utils";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import Datepicker, { disabledFrom, disabledTo } from "@common/react/components/Forms/Datepicker/Datepicker";

import Autocomplete, { Option } from "@common/react/components/Forms/Autocomplete/Autocomplete";
import { EmailStatus, EmailPriority } from '@app/objects/EmailThread';
import { EmailAccount } from '@app/objects/EmailAccount';
import { User, UserRole } from '@app/objects/User';
import { Injury } from '@app/objects/Injury';
import { GlobalSettingsState } from '@app/store/GlobalSettings';
import * as LoginState from "@app/store/Login";
import { Company } from '@app/objects/Company';
import { SpecialCompanyType } from '@app/objects/CompanyType';
type ComponentsProps = OwnProps;

interface OwnProps {
    onApply: <T>(values) => void
    pastDue: boolean | undefined
}

const validationSchema = Yup.object().shape({
    vccCase: Yup.string().matches(/^[\d-,\s]+$/, { excludeEmptyString: true })
});

export interface ProjectCalenderFiltersValues {
    vccCase: string;
    employeeName: string;
    user: User | null;
    referral: Company | null;
    company: Company | null | undefined;
    date: number[];
    insuranceCarrier: Company | null | undefined;
}

export default class ProjectCalenderFilters extends CommonFilters<ComponentsProps, FilterBaseState, ProjectCalenderFiltersValues> {
    constructor(props: ComponentsProps) {
        super(props);

        const that = this;

        this.state = {
            filters: {
                vccCase: {
                    id: 1,
                    caption: 'VCC Case #',
                    defaultValue: '',
                    value: '',
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value}<RemoveButton
                            click={() => that.removeFilter('vccCase')} /></span>;
                    }
                },
                employeeName: {
                    id: 2,
                    caption: 'Employee Name',
                    defaultValue: '',
                    value: '',
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value}<RemoveButton
                            click={() => that.removeFilter('employeeName')} /></span>;
                    }
                },
                user: {
                    id: 3,
                    caption: 'User',
                    defaultValue: null,
                    value: null,
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value ? this.value.lastName + ' ' + this.value.firstName : ''}<RemoveButton
                            click={() => that.removeFilter('user')} /></span>;
                    }
                },
                referral: {
                    id: 4,
                    caption: 'Referral',
                    defaultValue: null,
                    value: null,
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value ? this.value.name : ''}<RemoveButton
                            click={() => that.removeFilter('referral')} /></span>;
                    }
                },
                company: {
                    id: 5,
                    caption: 'Company',
                    defaultValue: null,
                    value: null,
                    getValue: function () {
                        return <span className="filters-component__item-value">{this.value ? this.value.name : ''}<RemoveButton
                            click={() => that.removeFilter('company')} /></span>;
                    }
                },
                date: {
                    id: 6,
                    caption: 'Date',
                    defaultValue: [null, null],
                    value: [null, null],
                    getValue: function () {
                        if (this.value[0] !== null && this.value[1] !== null) {
                            return <span className="filters-component__item-value">{dateFormat(this.value[0])} - {dateFormat(this.value[1])}<RemoveButton click={() => that.removeFilter('date')} /></span>
                        } else if (this.value[0] !== null) {
                            return <span className="filters-component__item-value">From {dateFormat(this.value[0])}<RemoveButton click={() => that.removeFilter('date')} /></span>
                        } else {
                            return <span className="filters-component__item-value">To {dateFormat(this.value[1])}<RemoveButton click={() => that.removeFilter('date')} /></span>
                        }
                    },
                    check: function () {
                        return this.value[0] !== this.defaultValue[0] || this.value[1] !== this.defaultValue[1];
                    }
                },
                insuranceCarrier: {
                    id: 7,
                    caption: 'Insurance Carrier',
                    defaultValue: null,
                    value: null,
                    getValue: function () {
                        return this.value ? that.getFilterMarkup(this, (this.value as Company).name, 'insuranceCarrier') : null
                    }
                },
            },
            visible: false,
        };

        this.resetFilters = this.resetFilters.bind(this);
    }

    getFiltersContent() {
        const { filters } = this.state;

        const getFormContainer = (triggerNode: Element) => document.getElementById('filters-component__form') || document.body;

        return <div className="filters-component__popup clearfix">
            <Formik
                initialValues={
                    {
                        vccCase: filters.vccCase.value,
                        employeeName: filters.employeeName.value,
                        user: filters.user.value,
                        referral: filters.referral.value,
                        company: filters.company.value,
                        date: filters.date.value,
                        insuranceCarrier: filters.insuranceCarrier.value,
                    }
                }
                onSubmit={this.applyFilters}
                enableReinitialize={true}
                validationSchema={validationSchema}
                render={(formikBag: FormikProps<ProjectCalenderFiltersValues>) => (
                    <Form id="filters-component__form" className="filters-component__form">
                        <div className="row">
                            <div className="col-sm-6">
                                <Field
                                    name="vccCase"
                                    render={({ field, form }: FieldProps<ProjectCalenderFiltersValues>) => (
                                        <div className={` ${form.errors.vccCase && form.touched.vccCase ? 'has-error' : ''}`}>
                                            <label htmlFor="vccCase">Case #</label>
                                            <input className="form-control input-sm" type="text" id="vccCase"   {...field} />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Field
                                    name="employeeName"
                                    render={({ field, form }: FieldProps<ProjectCalenderFiltersValues>) => (
                                        <div className={` ${form.errors.employeeName && form.touched.employeeName ? 'has-error' : ''}`}>
                                            <label htmlFor="employeeName">Employee name</label>
                                            <input className="form-control input-sm" type="text" id="employeeName"   {...field} />
                                        </div>
                                    )}
                                />
                            </div>


                        </div>

                        <div className="row">
                            <Field
                                name="user"
                                render={({ field, form }: FieldProps<ProjectCalenderFiltersValues>) => (
                                    <div className={`form-group col-sm-6`}>
                                        <label>User</label>
                                        <Autocomplete type="userList"
                                            params={{ roles: [UserRole.RTWatHome] }}
                                            renderOption={(item) => <Option key={item.id} value={`${item.lastName + ' ' + item.firstName}`} title={item.lastName + ' ' + item.firstName} {...{ item: item }}>{item.lastName + ' ' + item.firstName}</Option>}
                                            onSelect={(value, option) => {
                                                formikBag.setFieldValue('user', option.props.item);
                                            }}
                                            onChange={(value = '') => { if (value === '') { formikBag.setFieldValue('user', null); } }}
                                            value={''}

                                            paramName="text"
                                        />
                                    </div>
                                )}
                            />
                            <Field
                                name="company"
                                render={({ field, form }: FieldProps<ProjectCalenderFiltersValues>) => (
                                    <div className={`form-group col-sm-6`}>
                                        <label>Employer</label>
                                        <Autocomplete type="companyList"
                                            onSelect={(value, option) => { formikBag.setFieldValue(field.name, option.props.item); }}
                                            onChange={(value = '') => { value === '' && formikBag.setFieldValue(field.name, null) }}
                                            value={formikBag.values.company ? formikBag.values.company.name : ''}
                                            paramName="name"
                                            antdProps={{ getPopupContainer: getFormContainer }}
                                            params={{
                                                companyType: SpecialCompanyType.Employer
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        <div className="row">
                            <Field
                                name="referral"
                                render={({ field, form }: FieldProps<ProjectCalenderFiltersValues>) => (
                                    <div className={`form-group col-sm-4`}>
                                        <label>Referral Source</label>
                                        <Autocomplete type="companyList"
                                            onSelect={(value, option) => { formikBag.setFieldValue(field.name, option.props.item); }}
                                            onChange={(value = '') => { value === '' && formikBag.setFieldValue(field.name, null) }}
                                            params={{
                                                companyType: SpecialCompanyType.Referral
                                            }}
                                            value={formikBag.values.referral ? formikBag.values.referral.name : ''}
                                            loadOnFocus={true}
                                            paramName="name"
                                        />
                                    </div>
                                )}
                            />
                            <Field
                                name={'insuranceCarrier'}
                                render={({ field, form }: FieldProps<ProjectCalenderFiltersValues>) => (
                                    <div className={`form-group col-sm-6`}>
                                        <label>Insurance Carrier</label>
                                        <div className="input-group w-100">
                                            <Autocomplete type="companyList"
                                                renderOption={(item: Company) => {
                                                    return <Option key={item.id + ''} value={item.name}  {...{ item: item }}
                                                        title={item.name}>{item.name}</Option>
                                                }}
                                                onSelect={(value, option) => { formikBag.setFieldValue(field.name, option.props.item); }}
                                                onChange={(value = '') => { value === '' && formikBag.setFieldValue(field.name, null); }}
                                                value={formikBag.values.insuranceCarrier ? formikBag.values.insuranceCarrier.name : ''}
                                                antdProps={{
                                                    getPopupContainer: getFormContainer
                                                }}
                                                params={{
                                                    companyType: SpecialCompanyType.Insurance
                                                }}
                                                paramName="text"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                        <div className="form-group col-sm-6 filters-component__date">
                            <label>Referal Date</label>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-sm-12 col-md-6 DateFrom">
                                        <Datepicker
                                            disabledDate={disabledTo(formikBag.values.date[1], true)}
                                            value={formikBag.values.date[0]}
                                            format="MM/DD/YY"
                                            onChange={date => formikBag.setFieldValue('date.0', date ? new Date(date).setHours(0, 0, 0, 0) : date)}
                                            antdProps={{
                                                placeholder: "From",
                                                getCalendarContainer: getFormContainer
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 DateTo">
                                        <Datepicker
                                            disabledDate={disabledFrom(formikBag.values.date[0], true)}
                                            value={formikBag.values.date[1]}
                                            format="MM/DD/YY"
                                            onChange={date => formikBag.setFieldValue('date.1', date ? new Date(date).setHours(24, 0, 0, -1) : date)}
                                            antdProps={{
                                                placeholder: "To",
                                                getCalendarContainer: getFormContainer
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.filtersButtons()}
                    </Form>
                )}
            />
        </div>
    }
}