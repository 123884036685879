﻿import {Injury, InjuryFormValues} from "@app/objects/Injury";
import {List} from "@common/typescript/objects/List";
import {Language} from "@app/objects/Language";
import {Company} from "@app/objects/Company";
import Occupation from "@app/objects/Occupation";
import {User} from "@app/objects/User";
import {StateTimeZone} from "@common/typescript/objects/StateTimeZone";
import {DayOfWeek, WeekDay} from "@app/objects/Schedule";
import {PayrollPeriod} from "@app/objects/PayrollPeriod";
import { UserRole } from "@app/objects/User";
export enum Salutation
{
	Mr,
	Ms,
	Mrs,
	Dr
}

export enum Gender
{
	Male = 0,
	Female = 1
}

export enum WorkerComp {
	WorkersComp = 0,
	NonOccupational = 1
}

export interface TempInjury extends InjuryFormValues{
	comment: string;
	restriction: string;
}

export interface PlaceList {
	id: number;
	latitude: number;
	longitude: number;
	name: string;
	place_id: string;
	address: string;
}

export interface EmployeeFormValues {
	// vccCase : string;
	vccCode : string;
	
	companyId?: Number;
	
	salutation : Salutation;

	firstName: string;
	lastName: string;

	address: string;
	apartment: string;
	city: string;
	state: string;
	zip: string;

	timeZone: StateTimeZone;

	email: string;
	gender: Gender;

	occupationId: number | null | undefined;
	occupation: Occupation | null | undefined;
	age: string;
	phone: string;
	mobile: string;
	note: string;
	injury: Injury | null;
	injuries: List<Injury>
	languages: List<EmployeeLanguage>
	referralId: number | null;
	referral?: Company;
	referralName: string;
	referralContactId: number;
	referralContact?: User;
	payrollPeriod: PayrollPeriod,
	startDate: DayOfWeek;
	tempInjury: TempInjury;
	companyName: string;
	workerComp: WorkerComp;

	isCompleted: boolean;

}

export interface Employee extends EmployeeFormValues {
	id: number;

	latitude: number;
	longitude: number;
	
	company?: Company;
	companyName: string;
}

export interface EmployeeLanguage {
	employeId: number;
	employe: Employee;
	language: Language;
	languageId: number;
	id: number;
}

export function getTimeZone(user: User | null | undefined, stateTimezone: StateTimeZone): string
export function getTimeZone(userRole: UserRole, stateTimezone: StateTimeZone): string
export function getTimeZone(userOrRole: any, stateTimezone: StateTimeZone): string {
	let stateTimezones = StateTimeZone[stateTimezone];
	return stateTimezones;
}