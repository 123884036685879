import React from "react";
import { connect } from "react-redux";
import { addTask } from "../../actions/";
import setNewTask from "../../hoc/setNewTask";

class Form extends React.Component {
    constructor() {
        super();
        this.state = {val:""}
    }
    isValidField = () => {
        return this.state.val.length > 3;
    }
    handleChange = (e) => {
        this.setState({val:e.target.value})
    }
  render() {
    const {
      handleOption
    } = this.props;
    return (
      <form
        onSubmit={this.handleSubmit}
        className="add-card-form add-card-form-true"
        >
            {/*
        <div className="add-card-form__header">
          <div onClick={handleOption} className="form__low-pr">
            <input
              className="form__checkbox"
              type="radio"
              name="priority"
              value="card-color-low"
              alt="Low Priority"
            />
            <label className="form__label" htmlFor="Low Priority">
              Low Priority
            </label>
          </div>
          <div onClick={handleOption} className="form__med-pr">
            <input
              className="form__checkbox"
              type="radio"
              name="priority"
              value="card-color-med"
              alt="Med Priority"
            />
            <label className="form__label" htmlFor="Med Priority">
              Med Priority
            </label>
          </div>
          <div onClick={handleOption} className="form__high-pr">
            <input
              className="form__checkbox"
              type="radio"
              name="priority"
              value="card-color-high"
              alt="High Priority"
            />
            <label className="form__label" htmlFor="High Priority">
              High Priority
            </label>
          </div>
        </div>
        */}
            <textarea
                className="add-card-form__main"
          type="text"
                placeholder="Write your task"
                value={this.state.val}
          onChange={this.handleChange}
        />
        <div className="add-card-form__footer">
                <div className="form__footer">
                    {/*
            <div className="form__footer-av">
              <img src={require("../../assets/img/thompson.jpg")} />
            </div>
            <div className="attach-ico">
              <i className="material-icons">attach_file</i>
            </div>
            */}
          </div>
          <input
            className="form-add-btn"
            type="submit"
                    value="Add"
            disabled={!this.isValidField()}
          />
        </div>
      </form>
    );
  }

  handleSubmit = ev => {
      ev.preventDefault();
      this.props.addTask({ name: this.state.val, taskListId: this.props.taskListId, projectId: this.props.projectId });
      this.setState({ val: "" });
  };
}

export default Form;
