import * as React from 'react';

import * as moment from 'moment';
import {DatePickerProps, RangePickerProps} from 'antd/lib/date-picker/interface';
import DatePicker  from 'antd/lib/date-picker';

import {getPopupContainer} from '@common/react/components/Utils/Utils';

const { RangePicker } = DatePicker;

const disabledAfterToday = (currentDate?: moment.Moment) => currentDate ? currentDate.isAfter(moment(), 'day') : false;

const disabledBeforeToday = (currentDate?: moment.Moment) => currentDate ? currentDate.isBefore(moment(), 'day') : false;

export const disabledFrom = (from: number | null, withoutToday?: boolean) => (currentDate?: moment.Moment) => (
	currentDate ? (!withoutToday && currentDate.isBefore(moment(), 'day') || (from !== null ? currentDate.isBefore(moment(from), 'day') : false)) : false
);

export const disabledTo = (to: number | null, withoutToday?: boolean) => (currentDate?: moment.Moment) => (
	currentDate ? (!withoutToday && currentDate.isAfter(moment(), 'day') || (to !== null ? currentDate.isAfter(moment(to), 'day') : false)) : false
);

interface ComponentProps {
	format?: string;
	disabledDate?: (currentDate?: moment.Moment) => boolean;
	maxDateToday?: boolean;
	minDateToday?: boolean;
	isDisabled?: boolean | false;
	locale?: any;
	showTime?: any;
}

interface DatepickerProps extends ComponentProps {
	value: number | null;
	onChange: (date: number | null) => void;
	antdProps?: DatePickerProps;
}

interface RangepickerProps extends ComponentProps {
	value:  [number, number] | null;
	onChange: (date: [number, number] | null) => void;
	antdProps?: RangePickerProps;
}

const Datepicker: React.SFC<DatepickerProps> = 
	({ value, onChange, format = 'DD.MM.YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {}, showTime, isDisabled}) => {
		// tslint:disable-next-line:no-parameter-reassignment
		if (maxDateToday) disabledDate = disabledAfterToday;
		
		// tslint:disable-next-line:no-parameter-reassignment
		if (minDateToday) disabledDate = disabledBeforeToday;
		
		const defaultValue = value !== null && value !== undefined ? moment(value) : undefined;
		
		const innerOnChange = (date: moment.Moment) => {
			onChange(date === null ? null : +date);
		};
		
		return <div className="datepicker-component">
			<DatePicker 
				{...antdProps}
				format={showTime ? 'MM-DD-YYYY HH:mm' : format}
				onChange={innerOnChange}
				disabledDate={disabledDate}
				locale={locale}
				value={defaultValue}
				showTime={showTime}
				getCalendarContainer={antdProps.getCalendarContainer || getPopupContainer}
				disabled={isDisabled}
			/>
		</div>;
	};

export const Rangepicker: React.SFC<RangepickerProps> = 
	({ value, onChange, format = 'DD.MM.YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {}, showTime, isDisabled}) => {
		const innerOnChange = (date: any) => {
			onChange(date.length === 2 ? [+date[0], +date[1]] : null);
		};
	
		// tslint:disable-next-line:no-parameter-reassignment
		if (maxDateToday) disabledDate = disabledAfterToday;
	
		// tslint:disable-next-line:no-parameter-reassignment
		if (minDateToday) disabledDate = disabledBeforeToday;
	
		let defaultValue;
		
		if (value !== null && value[0] !== null && value[0] !== undefined && value[1] !== null && value[1] !== undefined ) {
			defaultValue = [moment(value[0] as number), moment(value[1] as number)];
		} else {
			defaultValue = [undefined, undefined];
		}
		
		return <div className="datepicker-component">
			<RangePicker 
				{...antdProps}
				format={showTime ? 'MM-DD-YYYY HH:mm' : format}
				onChange={innerOnChange}
				disabledDate={disabledDate}
				locale={locale}
				defaultValue={defaultValue}
				showTime={showTime}
				getCalendarContainer={antdProps.getCalendarContainer || getPopupContainer}
				disabled={isDisabled}
			/>
		</div>;
	};

export default Datepicker;
