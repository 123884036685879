import * as React from 'react';

import {FormikErrors} from 'formik';

interface ComponentProps {
	isValid: boolean;
	submitCount: number;
	errors: FormikErrors<any>;
	formId: string;
}

export default class ScrollToError extends React.Component<ComponentProps, {}> {
	shouldComponentUpdate(nextProps, nextState) {
		return !nextProps.isValid && nextProps.submitCount > 0 && nextProps.errors && this.props.submitCount !== nextProps.submitCount;
	}
	
	componentDidUpdate(prevProps) {
		const firstInput = document.querySelector(`#${this.props.formId} .has-error`);
		
		if (firstInput) {
			firstInput.scrollIntoView();
		}
	}

	render() {
		return null;
	}
}