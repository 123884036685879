export interface FileInterface {
	deleted : boolean;
	extension : string;
	fileType : number;
	id : number;
	name : string;
	objectId : number;
	size : number;
	src : string;
	thumb : string;
	time : number;
	user : object;
	userId : number;
	_type : string;
}

export enum FileType {
	Any = 0,
	File = 1,
	Avatar = 2
}