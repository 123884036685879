import * as Yup from 'yup';

export const phoneRegexp = /(\(([0-9]{3})\)\s([0-9]{3})[-]([0-9]{4})|\+([0-9]{11}))/;

export const simpleStringValidator = Yup.string().required();

export const simpleNumberValidator = Yup.number().required();

export const positiveNumberValidator = Yup.number().positive('Required field');

export const notNullValidator = Yup.mixed().test('is-not-null', 'Required field!', value => value !== null);

export const emailValidator = Yup.string().email().required();

export const dateValidator = Yup.number().required().nullable();

export const phoneRequiredValidator = Yup.string().matches(phoneRegexp, 'Invalid phone number').required();

export const phoneValidator = Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value.match(phoneRegexp));