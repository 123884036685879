import * as React from 'react';
import {connect} from 'react-redux';

import {ActionCreatorsMapObject, bindActionCreators} from 'redux';

import Comment from '@common/react/components/Forms/Comment/Comment';
import {BaseUser} from '@common/react/objects/BaseUser';
import {Comment as TComment, ItemComments, getActionCreators} from '@common/react/store/Comments';

interface OwnProps {
	objectType: string;
	objectId: number;
	id: number;
	index: number;
	submitButtonCaption?: string;
	stateName?: string;
}

interface ComponentProps<TUser extends BaseUser> {
	commentComponent: any;
	commentFormComponent: any;
}

interface StateProps<TUser extends BaseUser> {
	item: TComment<TUser>;
	user?: TUser;
}

type TreeNodeProps<TUser extends BaseUser> = OwnProps & ComponentProps<TUser> & StateProps<TUser> & { actions?: ActionCreatorsMapObject};

interface TreeNodeState<TUser extends BaseUser> {
	editing: boolean;
	replying: boolean;
	tempComment?: TComment<TUser>;
}

class NotConnectedCommentTreeNode<TUser extends BaseUser> extends React.Component<TreeNodeProps<TUser>, TreeNodeState<TUser>>{
	constructor(props: TreeNodeProps<TUser>) {
		super(props);

		this.state = {
			editing: false,
			replying: false
		};

		this.handleEdit = this.handleEdit.bind(this);
		this.handleCancelEdit = this.handleCancelEdit.bind(this);
		this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
		this.handleReply = this.handleReply.bind(this);
		this.handleCancelReply = this.handleCancelReply.bind(this);
		this.handleSubmitReply = this.handleSubmitReply.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	public handleEdit() {
		this.setState({
			editing: true
		});
	}

	public handleCancelEdit() {
		this.setState({
			editing: false
		});
	}

	public handleSubmitEdit(values) {
		return this.props.actions 
			? this.props.actions.updateComment({...this.props.item, ...values}, this.props.stateName)
				.then(() => {
					this.setState({
						editing: false
					});
				})
			: Promise.reject('Error!');
	}

	public handleReply() {
		if (!this.props.user) {
			return;
		}
		
		this.setState({
			replying: true,
			tempComment: {
				id: -1,
				parent: this.props.item.id,
				objectType: this.props.item.objectType,
				objectId: this.props.item.objectId,
				usr: this.props.user,
				user: this.props.user ? this.props.user.id : 0,
				text: ''
			},
		});
	}

	public handleCancelReply() {
		this.setState({
			replying: false,
			tempComment: undefined
		});
	}

	public handleSubmitReply(values) {
		return this.props.actions
			? this.props.actions.addComment({...this.state.tempComment, ...values}, this.props.stateName)
				.then(() => {
					this.setState({
						replying: false,
						tempComment: undefined
					});
				})
			: Promise.reject('Error!');
	}

	public handleDelete() {
		return this.props.actions
			? this.props.actions.deleteComment(this.props.item, this.props.stateName)
			: Promise.reject('Error!');
	}

	public render() {
		const CommentForm = this.props.commentFormComponent;
		const Comment = this.props.commentComponent;
		
		const {item, objectType, objectId, submitButtonCaption, stateName, index} = this.props;
		
		const {tempComment, replying, editing} = this.state;
		
		return item
			?	<div className="comment-node">
				{!editing
					? <Comment 
						handleDelete={this.handleDelete}
						handleEdit={this.handleEdit}
						handleReply={this.handleReply}
						item={item}
						index={index}
						stateName={stateName}
					/>
					: <CommentForm 
						item={item}
						handleSubmit={this.handleSubmitEdit}
						handleCancel={this.handleCancelEdit}
						submitButtonCaption="Save"
					/>}
				{replying && tempComment && 
					<CommentForm 
						item={tempComment}
						handleSubmit={this.handleSubmitReply}
						handleCancel={this.handleCancelReply}
						submitButtonCaption={submitButtonCaption}
					/>
				}
				{item.childrenIds && <div className="comment-node__children">
					{item.childrenIds.list.map((childId, index) =>
						<CommentTreeNode 
							key={`${item.objectType}-${item.objectId}-${childId}`}
							objectType={objectType}
							objectId={objectId}
							id={childId}
							index={index}
							commentComponent={Comment}
							commentFormComponent={CommentForm}
							submitButtonCaption={submitButtonCaption}
							stateName={stateName}    
						/>)
					}
				</div>
				}
			</div>
			: null;
	}
}

const CommentTreeNode = connect<any, { actions?: ActionCreatorsMapObject}, any>(
	(state, ownProps?: OwnProps) => {
		if (!ownProps) { return {}; } 
		
		const {objectId, id, objectType} = ownProps;

		const stateName = ownProps.stateName || 'comments';

		return {
			item: state[stateName][objectType]
				&& state[stateName][objectType][objectId]
				&& state[stateName][objectType][objectId][id],
			user: state.login.user
		};
	},
	dispatch => ({
		actions: bindActionCreators(getActionCreators(), dispatch)
	})
)(NotConnectedCommentTreeNode);

export default CommentTreeNode;