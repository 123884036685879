import * as React from 'react';

import ItemsPage, {ExtendableItemsPage} from "@common/react/components/Pages/ItemsPage";

import SimpleSearchInput from "@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput";

ExtendableItemsPage.prototype.getFiltersRender = function () {
	return <div className="list-filters">
		<form className="clearfix" onSubmit={this.handleFilter}>
			<div className="pull-left list-filters__search top-search">
				<SimpleSearchInput onChange={this.handleChange} name="text"/>
			</div>
		</form>
	</div>
};


export {ExtendableItemsPage} from '@common/react/components/Pages/ItemsPage';

export default ItemsPage;