import {Injury, InjuryFile} from "@app/objects/Injury";
import { EmailStatus, EmailPriority, EmailThread } from "./EmailThread";
import { EmailAccount } from "./EmailAccount";
import { EmailFolder } from "./EmailFolder";
import { User } from "./User";

export interface InjuryEmail {
	id: number;
	injury: Injury | null | undefined;
	injuryId: number | null | undefined;
	subject: string;
	text: string;
	html: string;
	from: string;
	to: string;
	date: number;
    userId: number | null | undefined;
    user: User | null | undefined;
	read: boolean;
	draft: boolean;
	deleted: boolean;
	injuryFiles: InjuryFile[];
	archive: boolean;
	cc: string;
    bcc: string;

    emailStatus: EmailStatus;
    emailPriority: EmailPriority;


    emailAccountId: number | null | undefined;
    emailAccount: EmailAccount | null | undefined;
    emailFolderId: number | null | undefined;
    emailFolder: EmailFolder | null | undefined;

    emailThreadId: number | null | undefined;
    emailThread: EmailThread | null | undefined;
    sendTime: number ;
    createTime: number ;
    replyTime: number;
    viewed: boolean;
    deliveredTime: number;
    uniqueId: string;
    isSpam: boolean;
    inReplyTo: string;
    messageId: string;
}

export enum InjuryEmailAction {
	Read,
	Unread,
    Delete,
    HighPriority,
    LowPriority,
    Archive,
    Junk,
    Open,
    Close,
    Inbox,
    Unarchive
}