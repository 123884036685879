import {Employee} from "@app/objects/Employee";
import {List} from "@common/typescript/objects/List";
import {FileInterface} from "@common/typescript/objects/FileInterface";
import {Attorney} from "@app/objects/Attorney";
import {Call} from "@app/objects/Call";
import {User, UserRole} from "@app/objects/User";
import Schedule from "@app/objects/Schedule";
import {StatusReport} from "@app/objects/StatusReport";
import {TComment} from "@app/objects/Comment";
import {StatusHistory} from "@app/objects/StatusHistory";
import {OutcomeType} from "@app/objects/OutcomeType";
import {WithDeleted} from "@common/typescript/objects/WithDeleted";
import FollowUp from "./FollowUp";
import { Correspondence } from "./Correspondence";

export interface InjuryType {
	id: number;
	name: string;
	allowSubtype: boolean;
}

export interface InjuryPendingReason {
	id: number;
	name: string;
}

export interface InjuryFile {
	id: number;
	description: string;
	objectId: number;
	file: FileInterface;
}

export enum InjuryStatus {
	New = 1,
	InProgress = 2,
	FollowUp = 3,
	Pending = 4,
    Closed = 5,
    Open=6
}

export interface InjuryTime {
	from: number;
	to: number;
	employee: Employee;
	time: number;
}

export const allowSubtype = (injuryTypeId: number, injuryTypes: InjuryType[]): boolean => {
	let injuryType = injuryTypes.find(it => it.id == injuryTypeId);
	return injuryType !== undefined ? injuryType.allowSubtype : false;
};

interface InjuryStatusName {
	shortName: string,
	fullName: string
}

export const injuryStatusNames: { [key: number]: InjuryStatusName } = {
	[InjuryStatus.New]: {shortName: 'New', fullName: 'New Placement Received'},
	[InjuryStatus.InProgress]: {shortName: 'In Progress', fullName: 'Locating Offsite Job Location'},
	[InjuryStatus.FollowUp]: {shortName: 'Follow-up', fullName: 'Follow-up'},
	[InjuryStatus.Pending]: {shortName: 'Pending', fullName: 'Pending'},
	[InjuryStatus.Closed]: {shortName: 'Closed', fullName: 'Closed'}
};

export function getInjuryStatusName(user: User | null | undefined, injuryStatus: InjuryStatus): string
export function getInjuryStatusName(userRole: UserRole, injuryStatus: InjuryStatus): string
export function getInjuryStatusName(userOrRole: any, injuryStatus: InjuryStatus): string {
	let injuryStatusName = injuryStatusNames[injuryStatus];
	
	if (!injuryStatusName || !userOrRole) {
		return '';
	}
	
	let userRole = typeof userOrRole == "object"
		? userOrRole.role
		: userOrRole;
	
	return userRole == UserRole.External
		? injuryStatusName.shortName
		: injuryStatusName.fullName;
}

export enum InjurySubtype {
	None = 0,
	Right = 1,
	Left = 2,
	Bilateral = 3
}

interface InjuryWithoutDecimalValues extends WithDeleted {
	date: number | null;
	injuryStatus: InjuryStatus;
	startDate : number;
	claimNumber: string;
	injuryTypeId: number;
	injuryType: InjuryType;
	subtype: InjurySubtype;
	preInjuryWeeklyHoursWorked: string;
	currentRestrictionDate: number;
	lastRestrictionDate: number;
	nextDrAppt: number;
	daysTimeWorked: string;
	byPhysician: string;
	transportationIssues: boolean;
	currentMedicalRestrictions: string;
	transIssueType: string;
	vccCase: string;
	note: string;
	referralDate: number;
	reOpenDate: number;
	personAssigned: string;
	preCallDate: number;
	followUpDate: number;
	lastFollowUpDate: number;
	closeDate: number;
	stateJurisdiction: string;
	// pendingReason?: InjuryPendingReason;
	// pendingReasonId?: number;

	nonProfitAdditionalInstructions: string;
	jobTask: string;
	reportTo: string;
	title: string;
	physicalRequirements: string;
	timeRequirements: string;
	assignedPerson?: User;
	assignedPersonId?: number;
	referralSource?: User;
	referralSourceId?: number;
	employeId: number;
	employe: Employee;
	employeeAttorney?: Attorney;
	employeeAttorneyId?: number;
	defenceAttorney?: Attorney;
	defenceAttorneyId?: number;
	claimAdjuster?: Attorney;
	claimAdjusterId?: number;
	unreadEmailsCount: number;
	files: List<InjuryFile>;
	calls: List<Call>;
	userId: number;
	user: User | null;
    schedules?: List<Schedule>;
	followUps?: List<FollowUp>;
	correspondence?: List<Correspondence>;
	reports: List<StatusReport>;
	notes: TComment<User>[]
	lastStatusHistory?: StatusHistory;
	reportDate: number;
	totalDaysWorked: number;
	totalHoursWorked: number;
	totalMinutesWorked: number;
	totalDaysInProgram: number;
	rtwDate: number;
	totalInvoiced: number;
	estimatedIndemnitySavings: number;

	outcomeType?: OutcomeType;
	
	daysWorked: number;
	lastDayWorked: number | null;
	assignedDate: number | null;
	assignedTo: number | null;
	
	assignedUserId?: number;
	assignedUser: string;
}

export interface Injury extends InjuryWithoutDecimalValues {
	awwRate: number;
	compRate: number;
	hourRateALD: number;
	weeklyALDRate: string;
	placementFee1: number;
	placementFee2: number;
	placementFee3: number;
	minutesWorked: number | null;
}

export interface InjuryFormValues extends InjuryWithoutDecimalValues {
	awwRate: string;
	compRate: string;
	hourRateALD: string;
	weeklyALDRate: string;
	placementFee1: string;
	placementFee2: string;
	placementFee3: string;
	minutesWorked: string;
}