import {FormikInput} from "@common/react/components/Forms/FormikInput/FormikInput";
﻿import React from "react";
import {EmployeeFormValues} from "@app/objects/Employee";
import {Field, FieldProps, FormikProps} from "formik";
import {State} from "@common/react/components/Forms/State/State";
import MaskedInput from 'react-text-mask';
import {Attorney} from "@app/objects/Attorney";
import {InjuryFormValues} from "@app/objects/Injury";
import {phoneFormat, phoneMask} from "@app/components/Utils/Utils";
import {Zip} from "@app/objects/Zip";

import Autocomplete, {Option} from '@common/react/components/Forms/Autocomplete/Autocomplete';

export interface AttorneyFieldsProps {
	formikBag: FormikProps<InjuryFormValues> | FormikProps<EmployeeFormValues>; 
	attorney: Attorney;
	namePrefix: string;
	employeeId: number;
	isEditable?: boolean;
}

export default (props: AttorneyFieldsProps) => {
	let {formikBag, attorney, namePrefix, employeeId, isEditable = true} = props;
	
	const prop = namePrefix.slice(0 ,-1);
	
	return isEditable
		? <React.Fragment>
		<div className="row">
			<Field
				name={namePrefix + 'lawFirm'}
				render={(fieldProps: FieldProps<Attorney>) => (
					<FormikInput fieldProps={fieldProps} title="Law Firm" />
				)}
			/>
		</div>
		<div className="row">
			<Field
				name={namePrefix + 'firstName'}
				render={(fieldProps: FieldProps<Attorney>) => (
					<FormikInput fieldProps={fieldProps} title="First Name" />
				)}
			/>
			<Field
				name={namePrefix + 'lastName'}
				render={(fieldProps: FieldProps<Attorney>) => (
					<FormikInput fieldProps={fieldProps} title="Last Name" />
				)}
			/>
		</div>
		<div className="row">
			<Field
				name={namePrefix + 'address'}
				render={(fieldProps: FieldProps<Attorney>) => (
					<FormikInput fieldProps={fieldProps} title="Address" />
				)}
				/>
				<Field name={namePrefix + 'city'}
					render={(fieldProps: FieldProps<Attorney>) => (
						<FormikInput fieldProps={fieldProps} title="City" />
					)}
				/>
		</div>
		<div className="row">
			
			<Field
				name={namePrefix + 'state'}
				render={({field, form}: FieldProps<Attorney>) => (
					<div className={`form-group col-sm-6`}>
						<label >State</label>
						<State value={field.value} onChange={state => formikBag.setFieldValue(namePrefix + 'state', state)} abbreviation={true}/>
					</div>
				)}
				/>
				<Field
					name={namePrefix + 'zip'}
					render={({ field, form }: FieldProps<Attorney>) => (
						<div className={`form-group col-sm-6`}>
							<label htmlFor={namePrefix + 'zip' + employeeId}>Zip</label>
							<Autocomplete type="zipCodeList"
								renderOption={(item: Zip) => <Option key={item.id + ''} value={`${item.zip}`} title={item.zip} {...{ item: item }}>{item.zip}</Option>}
								onSelect={(value, option) => {
									formikBag.setFieldValue(namePrefix + 'zip', option.props.item.zip);
									formikBag.setFieldValue(namePrefix + 'city', option.props.item.city);
									formikBag.setFieldValue(namePrefix + 'state', option.props.item.stateName);
								}}
								onChange={(value = '') => {
									formikBag.setFieldValue('zip', value);
								}}
								value={field.value}
								paramName="text" />
						</div>
					)}
				/>
		</div>
	
		<div className="row">
			<Field
				name={namePrefix + 'phone'}
				render={({field, form}: FieldProps<Attorney>) => (
					<div className={`form-group col-sm-6 ${form.errors[prop] && form.touched[prop] && form.errors[prop].phone && form.touched[prop].phone ? 'has-error' : ''}`}>
						<label htmlFor={namePrefix + 'phone' + employeeId}>Business Phone</label>
						<div className="is-relative">
							<MaskedInput
								mask={phoneMask}
								className="form-control"
								placeholder="+1 (___) ___-____"
								guide={true}
								id={namePrefix + 'phone' + employeeId}
								{...field}
							/>
							{form.errors[prop] && form.touched[prop] && form.errors[prop].phone && form.errors[prop].phone ? <div className="validation-message">{form.errors[prop].phone}</div> : ''}
						</div>
					</div>
				)}
			/>
			<Field
				name={namePrefix + 'extension'}
				render={(fieldProps: FieldProps<Attorney>) => (
					<FormikInput fieldProps={fieldProps} title="Extension" />
				)}
			/>
		</div>
		<div className="row">
			<Field
				name={namePrefix + 'fax'}
				render={({field, form}: FieldProps<Attorney>) => (
					<div className={`form-group col-sm-6 ${form.errors[prop] && form.touched[prop] && form.errors[prop].fax && form.touched[prop].fax ? 'has-error' : ''}`}>
						<label htmlFor={namePrefix + 'fax'+ employeeId}>Fax Number</label>
						<div className="is-relative">
							<MaskedInput
								mask={phoneMask}
								className="form-control"
								placeholder="+1 (___) ___-____"
								guide={true}
								id={namePrefix + 'fax'+ employeeId}
								{...field}
							/>
							{form.errors[prop] && form.touched[prop] && form.errors[prop].fax && form.errors[prop].fax ? <div className="validation-message">{form.errors[prop].fax}</div> : ''}
						</div>
					</div>
				)}
			/>
			<Field
				name={namePrefix + 'email'}
				render={(fieldProps: FieldProps<Attorney>) => (
					<FormikInput fieldProps={fieldProps} title="Email" />
				)}
			/>
		</div>
	</React.Fragment>
		: <React.Fragment>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>Law Firm</label>
					<div>
						{attorney.lawFirm || '-'}
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>First Name</label>
					<div>
						{attorney.firstName || '-'}
					</div>
				</div>
				<div className={`form-group col-sm-6`}>
					<label>Last Name</label>
					<div>
						{attorney.lastName || '-'}
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>Address</label>
					<div>
						{attorney.address || '-'}
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>City</label>
					<div>
						{attorney.city || '-'}
					</div>
				</div>
				<div className={`form-group col-sm-6`}>
					<label>State</label>
					<div>
						{attorney.state || '-'}
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>Zip</label>
					<div>
						{attorney.zip || '-'}
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>Business Phone</label>
					<div>
						{attorney.phone ? phoneFormat(attorney.phone) : '-'}
					</div>
				</div>
				<div className={`form-group col-sm-6`}>
					<label>Extension</label>
					<div>
						{attorney.extension || '-'}
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`form-group col-sm-6`}>
					<label>Fax Number</label>
					<div>
						{attorney.fax ? phoneFormat(attorney.fax) : '-'}
					</div>
				</div>
				<div className={`form-group col-sm-6`}>
					<label>Email</label>
					<div>
						{attorney.email || '-'}
					</div>
				</div>
			</div>
		</React.Fragment>
}