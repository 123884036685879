import React, { Link } from "react";
import ClassNames from "classnames";
import { connect } from "react-redux";
import dragging from "../../hoc/dragging";
import { deleteTask } from "../../actions/";
import { Popover, Button } from "antd";
import Linkify from 'react-linkify';
import Modal from "antd/lib/modal";
import { dateFormat, getUserName } from "@app/components/Utils/Utils";

class Card extends React.PureComponent {
    state = { showModal: false };
    componentDidMount() {
    }
    render() {
        const { data, dragging, forDragStart } = this.props;
        const style = ClassNames("card-container-color", data.style);
        const dragAndDrop = ClassNames({
            card: true,
            "card-dragging": dragging
        });
        let taskStatus = ["Not Started", "Inprogress", "Completed", "Cancelled"]
       
        const content = 
            typeof data != typeof undefined ? <div>{data.start && <span>Start :{dateFormat(data.start, true)}</span>}
                <br />{data.dueDate && <span>End :{data.dueDate ? dateFormat(data.dueDate, true) : (data.date ? dateFormat(data.date, true) : 0)}</span>}
                <br />Task :{data.name}
                <br />Status :{taskStatus[data.status]}
                <br />By: {data.user ? data.user.lastName + ' ' + data.user.firstName : ''}
                <br />Files : {data.files && data.files.list ?
                     data.files.list.map((item, index) => {
                       
                        return <div><a href={item.file.src}>{item.file.name}</a></div>;

                    })

                : 'fsda'}
                <br />Description : 
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
            
                
            </div > : < div ></div>;
    return (
      <div  className={dragAndDrop} draggable="true" onDragStart={forDragStart}>
            {/*
            <div className="card__header">
          <div className={style}>
            <div className="card__header-priority">{data.priority}</div>
                </div>
                <div className="card__header-clear">
                    <i onClick={this.handleDelete} className="material-icons">edit</i>
                    <i onClick={this.handleDelete} className="material-icons">clear</i>
          </div>
        </div>
        */}
            <Modal
                title={'Download Desktop Application'}
                visible={this.state.showModal}
                width={600}
                footer={null}
                destroyOnClose={true}>
                {content}
                <div className="text-center space-between">
                    <Button className="btn btn-primary" onClick={e => { this.setState({ showModal: false }) }}>{'Ok'}</Button>
                    <Button className="btn btn-primary" onClick={e => { window.location.href = "/projecttask-editor/"+data.id }}>{'Edit'}</Button>
                </div>
            </Modal>
            <Popover content={content} title="Description">

                <div className="card__text" onClick={() => this.setState({ showModal:true })}>
                    {data.name}
                
                
                </div>
            </Popover>
            <div className="card__menu">
                
                <div className="card__menu-left" >
                    {this.props.showSessionControls && <div style={{ color: "green" }}>
                        <i className="material-icons" onClick={()=>this.props.start(this.props.id,data.name)} style={{ fontSize: 40 }}>play_circle_filled</i>
                        <i className="material-icons" onClick={() => this.props.stop(this.props.id, data.name)} style={{ fontSize: 40 }}>radio_button_checked</i>
                    </div>}
                    <div className="comments-wrapper" style={{
                        visibility: "hidden"
                    }}>
                        <div className="comments-ico" onClick={() => this.setState({ showModal: true })}>
                <i className="material-icons">comment</i>
              </div>
                        <div className="comments-num" onClick={() => this.setState({ showModal: true })}>{data.comments}</div>
            </div>
                    <div className="attach-wrapper" style={{
                        visibility: "hidden"
                    }}>
              <div className="attach-ico">
                <i className="material-icons">attach_file</i>
              </div>
              <div className="attach-num">{data.attach}</div>
            </div>
          </div>

                <div className="card__menu-right" style={{width:120}} onClick={() => this.setState({ showModal: true })}>

                    <div >
                        {this.props.isWorker && <Button className="btn btn-primary" onClick={e => { this.props.updateTaskStatus(data); e.stopPropagation(); this.forceUpdate();}}>{data.status != 1 ? "Set Inprogress" : "Set Completed"}</Button>}

            </div>
                    {/*<div className="img-avatar">
                        {data.user && <img src={data.user.avatar}  title={data.user.lastName + " " + data.user.firstName} />}
            </div>*/}
          </div>
        </div>
      </div>
    );
  }

  handleDelete = () => {
    const { data, deleteTask } = this.props;
    deleteTask(data.id);
  };
}

export default connect(
  null,
  { deleteTask }
)(dragging(Card));
