import * as React from 'react';

import {HandleChangeEventElement} from '@common/react/components/Pages/ItemsPage';

interface ComponentProps {
	onChange: (evt: HandleChangeEventElement) => void;
	onSubmit?: (evt: HandleChangeEventElement) => void;
	name: string;
	className?: string;
	placeholder?: string;
	withoutForm?: boolean;
	buttonType?: string;
}

interface ComponentState {
	value: string;
}

export default class SimpleSearchInput extends React.Component<ComponentProps, ComponentState> {

	public static defaultProps: Partial<ComponentProps> = {
		className: 'form-control',
		placeholder: 'Search',
		withoutForm: false,
		buttonType: 'submit'
	};

	constructor(props: ComponentProps) {
		super(props);
		
		this.state = {
			value: ''
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.clearField = this.clearField.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onClick = this.onClick.bind(this);
	}
	
	shouldComponentUpdate(nextProps: ComponentProps, nextState: ComponentState) {
		return nextState.value !== this.state.value;
	}
	
	handleChange(evt: React.FormEvent<HTMLInputElement>): void {
		this.setState({
			value: evt.currentTarget.value
		});
		
		this.props.onChange(evt);
	}
	
	onKeyPress(evt: React.KeyboardEvent<HTMLInputElement>): void {
		if (evt && evt.key === 'Enter') {
			this.props.onSubmit && this.props.onSubmit({
				currentTarget: {
					name: this.props.name,
					value: this.state.value
				}
			});
		}
	}
	
	onClick(): void {
		this.props.onSubmit && this.props.onSubmit({
			currentTarget: {
				name: this.props.name,
				value: this.state.value
			}
		});
	}
	
	clearField(): void {
		this.setState({
			value: ''
		});

		this.props.onChange({
			currentTarget: {
				name: this.props.name,
				value: ''
			}
		});
	}
	
	render() {
		const {withoutForm, onSubmit } = this.props;
		
		return <div className="simple-search-component input-group top-search">
			<input 
				type="text"
				className={this.props.className}
				placeholder={this.props.placeholder}
				name={this.props.name}
				onChange={this.handleChange}
				value={this.state.value}
				onKeyPress={this.props.withoutForm ? this.onKeyPress : undefined}
			/>
			{this.state.value !== '' && <i className="fa fa-times simple-search-component__clear" onClick={this.clearField}/>}
			<div className="input-group-btn">
				<button type={this.props.buttonType} onClick={withoutForm ? this.onClick : undefined} className="btn btn-sm btn-primary">
					<i className="fa fa-search"/>
				</button>
			</div>
		</div>;
	}
}