import * as React from "react";
import {TComment} from "@app/objects/Comment";
import BaseCommentTree from "@common/react/components/Forms/Comment/BaseCommentTree";
import CommentForm from "@common/react/components/Forms/Comment/CommentForm";
import Comment from "@app/components/Forms/Comment/Comment";
import {User} from "@app/objects/User";

interface OwnProps  {
	objectType: string;
	objectId: number;
	withoutAddButton?: boolean;
	submitButtonCaption?: string;
	readOnly?: boolean;
	stateName?: string;
	insertBefore?: boolean;
}

interface StateProps<TUser extends User> {
	root?: TComment<TUser>,
	user?: TUser
}

type CommentsProps<TUser extends User> = OwnProps & StateProps<TUser>;

export default class CommentTree<TUser extends User> extends React.Component<CommentsProps<TUser>> {
	public render() {
		return <BaseCommentTree {...this.props}
								additionalParams={{commentType: 0}}
								commentComponent={Comment}
								commentFormComponent={CommentForm}
		/>
	}
}


export  class MedicalTree<TUser extends User> extends React.Component<CommentsProps<TUser>> {
	public render() {
		return <BaseCommentTree {...this.props}
		                        additionalParams={{commentType: 1}}
		                        newCommentExtendProperties={{commentType: 1}}
		                        commentComponent={Comment}
		                        commentFormComponent={CommentForm}
		                        stateName="medicalRestrictions"
		                        readOnly={this.props.readOnly}
		/>
	}
}