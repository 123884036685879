import {List} from "@common/typescript/objects/List";
import { FileInterface } from "@common/typescript/objects/FileInterface";

import Event from './Event';
import {CompanyPhone} from "@app/objects/Company";
import {Employee} from "@app/objects/Employee";
import {Injury} from "@app/objects/Injury";

import {millisecondToMinutes, minutesToHours} from "@app/components/Utils/Utils";

export interface CallFile {
	id: number;
	objectId: number;
	file: FileInterface;
	fileId: number;
}

export enum CallType {
	ClockForDay = 0,
	ClockForLunch = 1,
	Record = 2,
	Call = 3,
	Info = 4,
    Unauthorized = 5,
    Fake=6
}

export interface WeekDayCalls {
	date: number;
    calls: Array<Call>;
    enableDaylightTimeSaving: boolean;
}

export const getDescriptionCall = function(call: Call): string {
	switch (call.callType) {
		case CallType.ClockForDay:
			return call.duration > 0 ? 'End work day' : 'Start work day';
		case CallType.ClockForLunch:
			return call.duration > 0 ? 'Start lunch' : 'End lunch';
		case CallType.Record:
			return 'Voice message';
		case CallType.Call:
			return 'Call center';
		case CallType.Info:
			return 'Call for info';	
		case CallType.Unauthorized:
			return 'Unauthorized';
		default:
			return '';
	}	
};

export const playCallVoice = function (record: CallFile) {
	const audio = new Audio(record.file.src);
	audio.play();
};

export interface Call {
	id: number;
	callType: CallType;
	phone: string;
	
	time: number ;

	duration: number;
	
	employeeId: number;
	employee?: Employee | null;
	
	injuryId: number;
	injury?: Injury;
	
	eventId: number;
	event?: Event;
	
	companyPhone?: CompanyPhone;
	companyPhoneId: number | null;

	files: List<CallFile>;

	employerName: string;
	employeeName: string;

	scheduleId: number;
	
	read: boolean;
	manual: boolean;
}

export interface actualTimesObject {
    startTime: number | null;
    startCall: Call | null | undefined;
    endTime: number | null;
    endCall: Call | null | undefined;
    lunchStartTime: number | null;
    lunchStartCall: Call | null | undefined;
    lunchEndTime: number | null;
    lunchEndCall: Call | null | undefined;
}

export const getTimesFromCalls = (calls: Call[]): actualTimesObject => {
	const start = calls.find((item: Call, index: number) => item.callType === CallType.ClockForDay && !item.duration);
	const end = calls.reverse().find((item: Call, index: number) => item.callType === CallType.ClockForDay && item.duration > 0);
	
	const lunchStart = calls.find((item: Call, index: number) => item.callType !== CallType.ClockForDay && !item.duration);
	const lunchEnd = calls.reverse().find((item: Call, index: number) => item.callType !== CallType.ClockForDay && item.duration > 0);

	const times: actualTimesObject = {
        startTime: start ? start.time : null,
        startCall: start,
        endTime: end ? end.time : (lunchEnd ? lunchEnd.time : null),
        endCall: end,
        lunchStartTime: lunchStart ? lunchStart.time : null,
        lunchStartCall: lunchStart,
        lunchEndTime: lunchEnd ? lunchEnd.time : null,
        lunchEndCall: lunchEnd
	};
	
	return times;
};

export const getTimeDifference = (event: Event, times: actualTimesObject): string => {
	const {startTime, endTime , lunchStartTime, lunchEndTime} = event;
	
	const {startTime: callStartTime, endTime: callEndTime , lunchStartTime: callLunchStartTime, lunchEndTime: callLunchEndTime} = times;
	
	let eventTime;

	if (lunchStartTime && lunchEndTime) {
		eventTime = (lunchStartTime - startTime) + (endTime - lunchEndTime);
	} else {
		eventTime = endTime - startTime;
	}
	
	if(callStartTime && callEndTime) {
		let callTime;

		if (callLunchStartTime && callLunchEndTime) {
			callTime = (callLunchStartTime - callStartTime) + (callEndTime - callLunchEndTime);
		} else {
			callTime = callEndTime - callStartTime;
		}
		
		return minutesToHours(millisecondToMinutes(eventTime - callTime));
	} 

	return minutesToHours(millisecondToMinutes(-eventTime));
	
};
