import * as React from 'react';

interface GoogleMapButtonProps {
	address: string;
	state: string;
	city: string;
	zip: string;
}

const handleGoogleMaps = (address, state, city, zip) => {
	let inputAddress = state + ', ' + address + (city? ', ' + city: '')+ (zip? ', ' + zip: '');
	inputAddress = encodeURIComponent(inputAddress).replace(new RegExp('%20', 'g'), '+').replace(/[!'()*]/g, function(c): any { '%' + c.charCodeAt(0).toString(16) });

	return window.open(`https://www.google.com/maps/search/?api=1&query=${inputAddress}`);
};

const GoogleMapButton: React.SFC<GoogleMapButtonProps> = ({ address, state, city, zip }) => {
	return address && state ? <button type="button" className="btn btn-default check-address" onClick={e => handleGoogleMaps(address, state, city, zip)}>Check Address on Google</button> : null;
};

export {GoogleMapButton};

