import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import Table, {ColumnProps} from 'antd/lib/table';
import {ActionCreatorsMapObject} from 'redux';

import * as PagesState from '@common/react/store/ItemList';
import {BaseParams} from '@common/react/objects/BaseParams';
import SimpleSearchInput from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';
import {deleteConfirmation} from '@common/react/components/Modal/Modal';

export type PageProps<T> =
	PagesState.ItemsState<T>
	& ActionCreatorsMapObject
	& RouteComponentProps<{ page: string }>;

export enum SortingDirection {
	Default = 0,
	Ascending = 1,
	Descending = 2
}

export interface HandleChangeEventElement {
	currentTarget: {
		name: string;
		value: string;
	};
}

export const renderMobileCell = (caption: string, text: string | JSX.Element | null): JSX.Element => {
	return <React.Fragment>
		<div className="table-mobile__caption">{caption}</div>
		<div className="table-mobile__content">{text}</div>
	</React.Fragment>;
};

type rowKey = (record) => string;

export class ExtendableItemsPage<TEntity, TPropsExtension, TState = {}> extends React.Component<PageProps<TEntity> & TPropsExtension, TState> {
	type: string = 'page';
	store: string = 'pages';
	path: string = 'pageList';
	listPath: string = 'pages';
	editorPath: string = 'page-editor';
	editorCaption: string = 'Add page';
	caption: string = 'Pages';
	text: string = '';
	isFilterText: boolean = true;
	textInput: any;
	tableClassName: string = '';
	additionalParams: BaseParams = {};
	rowKey: string | rowKey = 'id'; 
	withoutUrlPagination: boolean = false;
	count: number = 10;
	
	baseAction = {
		title: '',
		render: (text, record) => (
			<div className="text-right table-actions">
				<Link className="btn btn-sm btn-default" title="Edit" to={`/${this.editorPath}/${record.id}`}><i className="fa fa-pencil"/></Link>
				<button className="btn btn-sm btn-danger" title="Delete" onClick={e => this.handleDelete(e, record)}><i className="fa fa-times"/></button>
			</div>
		)
	};
	
	idColumn = {
		title: '#',
		dataIndex: 'id',
		render: (text: string, record: any): JSX.Element | null => {
			return this.renderMobileCell('#', record.id > 0 ? <span style={{whiteSpace: 'nowrap'}}>{record.id}</span> : null);
		}
	};

	columns: ColumnProps<TEntity>[] = [
		this.idColumn
	];
	
	constructor(props: any) {
		super(props);

		this.handleTableChange = this.handleTableChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeAndRefresh = this.handleChangeAndRefresh.bind(this);
	}

	componentWillMount() {
		const page = this.withoutUrlPagination ? 1 : (this.currentPage);
		
		this.props.reqPages(this.store, this.path, this.type, { page: page, count: this.count,  ...this.additionalParams });
	}

	componentWillReceiveProps(nextProps: Readonly<PageProps<TEntity> & TPropsExtension>) {
		// This method runs when incoming props (e.g., route params) change
		// If props were changed due to reloading don't need to make another request
		const page = nextProps.match ? parseInt(nextProps.match.params.page) || 1 : 1;
		
		if (!nextProps.isLoading && !this.withoutUrlPagination && page !== this.currentPage) {
			this.props.reqPages(this.store, this.path, this.type, {page: page, count: this.count, ...this.additionalParams});
		}
	}
	
	get currentPage(): number {
		return this.props.match ? parseInt(this.props.match.params.page) || 1 : 1;
	}

	handleDelete(event: React.MouseEvent<HTMLButtonElement>, item: TEntity) {
		event.preventDefault();
		
		deleteConfirmation(() => {
			this.removeItem(item);
		});
	}

	removeItem(item: TEntity): Promise<TEntity> {
		
		const self = this;
		const page = this.props.params.page || 1;
		const isLast = this.props.items.length === 1 && page > 1;

		return self.props.removeItem(
			self.store, 
			self.path, 
			self.type,
			{ ...item, ...{ children: null, files : null}}, 
			isLast ? {page: page - 1} : null
		).then(() => {
			if (isLast && !this.withoutUrlPagination) this.props.history.replace(`/${this.listPath}/${page - 1}`);
		});
	}

    handleChange(evt: HandleChangeEventElement) {
        let value: string|null = evt.currentTarget.value;
        if (value == "null") value = null;
        this.additionalParams = { ...this.additionalParams, ...{ [evt.currentTarget.name]: value }};
	}
	
	handleChangeAndRefresh (evt: HandleChangeEventElement) {
		this.handleChange(evt);
		this.handleFilter(null);
	}

	handleFilter(event: React.FormEvent<HTMLFormElement> | null) {
		event && event.preventDefault();
		this.props.refreshPages(this.store, this.path, { page: 1, ...this.additionalParams } );
	}
	
	handleTableChange(pagination, filters, sorter) {
		const page = this.currentPage;

		if (page !== pagination.current) {
			this.props.history.push(`/${this.listPath}/${pagination.current}`);
		} else {
			if (sorter.column) {
				this.additionalParams.column = [{
					caption: sorter.field,
					direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending
				}];
			} else {
				this.additionalParams.column = [];
			}

			this.props.refreshPages(this.store, this.path, { page: 1, count: this.count, ...this.additionalParams } );
		}
	}
	
	handleAdd() {
		this.props.history.push(`/${this.editorPath}/-1`);
	}

	getFiltersRender(): JSX.Element {
		return <div className="list-filters clearfix">
			<div className="list-filters__search">
				<form className="clearfix" onSubmit={this.handleFilter}>
					<SimpleSearchInput onChange={this.handleChange} name="text"/>
				</form>
			</div>
		</div>;
	}

	renderMobileCell(caption: string, text: string | JSX.Element | null): JSX.Element {
		return renderMobileCell(caption, text);
	}
	
	renderTable(): JSX.Element {
		let { _items } = this.props;
		const { items, pagination, isLoading } = this.props;
		
		return <Table
			columns={this.columns}
			dataSource={items}
			pagination={pagination}
			loading={isLoading}
			onChange={this.handleTableChange}
			childrenColumnName="child"
			rowKey={this.rowKey}
			className={this.tableClassName}
		/>;
	}
	
	public render() {
		return <React.Fragment>
			<div className="site-headline site-headline_with-button clearfix">
				<h1 className="pull-left">{this.caption}</h1>
				{this.editorCaption && this.editorPath ?
					<div className="pull-right">
						<button className="btn btn-primary" onClick={this.handleAdd}>{this.editorCaption}</button>
					</div>
					: null
				}
			</div>
			{this.isFilterText && this.getFiltersRender()}
			{this.renderTable()}
		</React.Fragment>;
	}
}

export default class ItemsPage<T, TState = {}> extends ExtendableItemsPage<T, {}, TState> {
	
}
