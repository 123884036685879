import {Answer} from "@app/objects/Answer";

export interface Question {
	id: number;
	text: string;
}

export interface CustomQuestion extends Question{
	checked: boolean;
	value: string;
	answerId: number;
}


export const getMergedQuestions = (answers: Answer[], questions: Question[]): CustomQuestion[] => {
	return questions.map((item: Question, index: number) => {
		let answer: Answer | undefined = answers.find((el: Answer) => {
			return el.questionId === item.id;
		});

		if(answer) {
			return {...item, checked: true, value: answer.text, answerId: answer.id || -1 }
		} else {
			return {...item, checked: false, value: '' , answerId: -1}
		}
	})
};