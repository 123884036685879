import { Injury } from "./Injury";
import { User } from "./User";
import { EmailFolder } from "./EmailFolder";
import { InjuryEmail } from "./InjuryEmail";
import { EmailAccount } from "./EmailAccount";

export enum EmailStatus { All = -1,Open=0, Closed =1}

export enum TaskStatus { NotStarted = -1, InProgress = 0, Completed = 1, WaitingOnSomeoneElse = 2, Deferred = 3 }
export enum EmailPriority {All=-1, Low=0, High=1 }


export interface EmailThread {
    id: number;
    injuryId: number | null | undefined;
    injuryFilesCount: number;
    completePercent: number;
    injury: Injury | null | undefined;
    emailAccountId: number | null | undefined;
    emailAccount: EmailAccount | null | undefined;
    emailFolderId: number | null | undefined;
    emailFolder: EmailFolder | null | undefined;
    userId: number | null | undefined;
    ownerUserId: number | null | undefined;
    user: User | null | undefined;
    owner: User | null | undefined;
    emailStatus: EmailStatus;
    emailPriority: EmailPriority;
    taskStatus: TaskStatus;
    emails: any;

    read: boolean;
    archive: boolean;
    isTask: boolean;
    startDate: number|null;
    dueDate: number |null;
}