import { Injury } from "@app/objects/Injury";
import { FollowUpType } from "@app/objects/FollowUpType";
import Schedule from "@app/objects/Schedule";
import { StatusHistory } from "@app/objects/StatusHistory";
import {User} from "@app/objects/User";
import {Reminder} from "@app/objects/Reminder";

export enum Recurrence {
	OneTime = 0,
	Daily = 1,
	Weekly = 2,
	ByWeekly = 3,
	Monthly = 4
}

export default interface FollowUp {
	id: number;
	injuryId: number;
	injury: Injury | null | undefined;
	followUpTypeId: number;
	followUpType: FollowUpType | null | undefined;
	scheduleId: number;
	schedule: Schedule | null | undefined;
	date: number | null;
	time: string | null;
	recurrence: Recurrence;
	statusHistroyId: number;
	statusHistory: StatusHistory;

	assignedPersonId: number;
	assignedPerson?: User;
	
	reminders: Reminder[];

	userId: number;
	user?: User;
	
	done: boolean;
	doneTime: number;
	

	deleted: boolean;
}