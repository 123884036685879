import Modal from "antd/lib/modal";

export function deleteConfirmation(callback: () => void, title?: string) {
	Modal.confirm({
		title: title || 'This item will be permanently deleted and cannot be recovered. Are you sure?',
		content: '',
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'Cancel',
		onOk() {
			callback()
		}
	});
}