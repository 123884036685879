import * as React from 'react';

interface ComponentProps {
	className?: string;
	onChange: (state: string) => void;
	value: string;
	defaultCaption?: string;
	abbreviation?: boolean;
	enableDefault?: boolean;
}

const states = [
	{index:0, name:'Alabama', code:'AL'},
	{index:1, name:'Alaska', code:'AK'},
	{index:2, name:'Arizona', code:'AZ'},
	{index:3, name:'Arkansas', code:'AR'},
	{index:4, name:'California', code:'CA'},
	{index:5, name:'Colorado', code:'CO'},
	{index:6, name:'Connecticut', code:'CT'},
	{index:7, name:'Delaware', code:'DE'},
	{index:8, name:'District Of Columbia', code:'DC'},
	{index:9, name:'Florida', code:'FL'},
	{index:10, name:'Georgia', code:'GA'},
	{index:11, name:'Hawaii', code:'HI'},
	{index:12, name:'Idaho', code:'ID'},
	{index:13, name:'Illinois', code:'IL'},
	{index:14, name:'Indiana', code:'IN'},
	{index:15, name:'Iowa', code:'IA'},
	{index:16, name:'Kansas', code:'KS'},
	{index:17, name:'Kentucky', code:'KY'},
	{index:18, name:'Louisiana', code:'LA'},
	{index:19, name:'Maine', code:'ME'},
	{index:20, name:'Maryland', code:'MD'},
	{index:21, name:'Massachusetts', code:'MA'},
	{index:22, name:'Michigan', code:'MI'},
	{index:23, name:'Minnesota', code:'MN'},
	{index:24, name:'Mississippi', code:'MS'},
	{index:25, name:'Missouri', code:'MO'},
	{index:26, name:'Montana', code:'MT'},
	{index:27, name:'Nebraska', code:'NE'},
	{index:28, name:'Nevada', code:'NV'},
	{index:29, name:'New Hampshire', code:'NH'},
	{index:30, name:'New Jersey', code:'NJ'},
	{index:31, name:'New Mexico', code:'NM'},
	{index:32, name:'New York', code:'NY'},
	{index:33, name:'North Carolina', code:'NC'},
	{index:34, name:'North Dakota', code:'ND'},
	{index:35, name:'Ohio', code:'OH'},
	{index:36, name:'Oklahoma', code:'OK'},
	{index:37, name:'Oregon', code:'OR'},
	{index:38, name:'Pennsylvania', code:'PA'},
	{index:39, name:'Rhode Island', code:'RI'},
	{index:40, name:'South Carolina', code:'SC'},
	{index:41, name:'South Dakota', code:'SD'},
	{index:42, name:'Tennessee', code:'TN'},
	{index:43, name:'Texas', code:'TX'},
	{index:44, name:'Tijuana Mexico', code:'CP'},
	{index:45, name:'Utah', code:'UT'},
	{index:46, name:'Vermont', code:'VT'},
	{index:47, name:'Virginia', code:'VA'},
	{index:48, name:'Washington', code:'WA'},
	{index:49, name:'West Virginia', code:'WV'},
	{index:50, name:'Wisconsin', code:'WI'},
	{index:51, name:'Wyoming', code:'WY'}
];

export const State: React.SFC<ComponentProps> = 
	({
		 className = 'form-control', 
		 onChange, 
		 value, 
		 defaultCaption = 'Select state', 
		 abbreviation = false, 
		 enableDefault
	}) => {
		return <select value={value || ''} className={`state-component ${className}`} onChange={event1 => onChange(event1.target.value)}>
			<option disabled={!enableDefault} value="">{defaultCaption}</option>
			{states.map(state => <option value={state.name} key={state.index}>{abbreviation ? state.code : state.name}</option>)}
		</select>;
	};

export function stateAbbreviation(name) {
	const state = states.filter(state => state.name === name);
	return state[0] ? state[0].code : '-';
}