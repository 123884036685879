import * as React from 'react';

import message from 'antd/lib/message';
import * as Yup from 'yup';

import {BaseUser} from '@common/react/objects/BaseUser';
import {Comment as TComment} from '@common/react/store/Comments';
import Button from '@common/react/components/Forms/Button';

const validationSchema = Yup.object().shape({
	text: Yup.string().required(),
});

interface CommentFormProps<TUser extends BaseUser> {
	item: TComment<TUser>;
	handleSubmit: (values: any) => Promise<void>;
	handleCancel: () => void;
	withoutAddButton?: boolean;
	submitButtonCaption?: string;
}

interface CommentFormState {
	isLoading: boolean;
	error: string | null;
	text: string;
}

export default class CommentForm<TUser extends BaseUser> extends React.Component<CommentFormProps<TUser>, CommentFormState> {

	constructor(props: CommentFormProps<TUser>){
		super(props);
		
		this.state = {
			isLoading: false,
			error: null,
			text: props.item.text || ''
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	
	public handleSubmit() {
		const text = this.state.text;
		
		this.setState({
			error: null
		});

		validationSchema.validate({text: text}).then(() => {
			this.setState({
				isLoading: true
			});
			
			this.props.handleSubmit({text: text})
				.then(() => {
					this.setState({
						isLoading: false,
						text: ''
					});
					message.success('Comment has been successfully saved!');
				})
				.catch(() => {
					this.setState({
						isLoading: false
					});
					message.error('Error!');
				});
		}).catch((err) => {
			this.setState({
				error:  err.errors[0]
			});
		});
	}

	handleChange(event) {
		this.setState({text: event.target.value});
	}
	
	public render() {
		const {item, handleCancel, withoutAddButton, submitButtonCaption = 'Add'} = this.props;
		
		const {error, text} = this.state;
		
		return <div className="comments-component__form">
				<div className={`form-group ${error ? 'has-error' : ''}`}>
					<div className="is-relative">
						<textarea className="form-control" value={text} onChange={this.handleChange} id="shortText"/>
						{error ?
							<div className="validation-message">{error}</div> : ''}
					</div>
				</div>
				<div className="text-right">
					<Button isLoading={this.state.isLoading} type="button" onClick={this.handleSubmit}>{submitButtonCaption}</Button>
					{!withoutAddButton && <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>}
				</div>
		</div>;
	}
}